import { CountryDropdown } from 'react-country-region-selector'
import 'react-phone-input-2/lib/style.css'
import { Label } from '../ui/label'

export default function CustomNationalityInput({
  value,
  handleChange,
  id,
  label,
  errorMessage,
  disabled,
}: any) {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={id}>{label}</Label>
      <CountryDropdown
        value={value}
        onChange={handleChange}
        classes={
          'flex h-9 w-full rounded-md border border-gray-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-indigo-600 disabled:cursor-not-allowed disabled:opacity-50 dark:border-gray-800 dark:placeholder:text-gray-400 dark:focus-visible:ring-gray-300'
        }
        disabled={disabled}
      />
      {errorMessage && (
        <p className="py-1 text-xs text-rose-500">{errorMessage}</p>
      )}
    </div>
  )
}
