//import { useGetMyCurrentSubscriptionQuery } from 'app/features/payment'
import {
  useDisableUserMutation,
  //useGetPaymentActiveAgentsCountQuery,
} from 'app/features/users'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'

export default function DisableUser({ user, open, setOpen }: any) {
  const [disableUser, { isLoading }] = useDisableUserMutation()

  // const {
  //   data: myCurrentSubscription,
  //   isError: isMyCurrentSubscriptionError,
  //   error: myCurrentSubscriptionError,

  //   isLoading: isMyCurrentSubscriptionLoading,
  // } = useGetMyCurrentSubscriptionQuery(null)

  // const {
  //   data: paymentAgentsActiveCount,
  //   isError: isPaymentAgentsActiveCountError,
  //   error: paymentAgentsActiveCountError,

  //   isLoading: isPaymentAgentsActiveCountLoading,
  // } = useGetPaymentActiveAgentsCountQuery(null)

  async function handleDisable() {
    await disableUser(user.id)
    setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change user status</AlertDialogTitle>
          <AlertDialogDescription>
            {user.disabled
              ? 'By enabling the user will be able to login to their account.'
              : 'By disabling the user will not be able to login to their account.'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button variant="outline" size="sm" onClick={() => setOpen(false)}>
            Cancel
          </Button>

          {/* 
          // TODO: to be refactored
          // this button should be disabled if the agent is disabled to to subscription reasons
          <Button
            variant={user.disabled ? 'default' : 'destructive'}
            disabled={
              isLoading ||
              isMyCurrentSubscriptionLoading ||
              isPaymentAgentsActiveCountLoading ||
              (myCurrentSubscription?.stripeSubscriptionNumOfUsers <=
                paymentAgentsActiveCount &&
                user.disabled)
            }
            size="sm"
            onClick={handleDisable}
          >
            {user.disabled ? 'Enable' : 'Disable'}
          </Button> */}
          <Button
            variant={user.disabled ? 'default' : 'destructive'}
            disabled={isLoading}
            size="sm"
            onClick={handleDisable}
          >
            {user.disabled ? 'Enable' : 'Disable'}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
