import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    initiated: false,
  },
  reducers: {
    setInitialState: (state, action) => {
      state.initiated = action.payload
    },
  },
})

export const { setInitialState } = appSlice.actions
export default appSlice.reducer
