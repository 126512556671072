'use client'

import { Link } from 'react-router-dom'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip'
import { cn } from 'lib/utils'
import { buttonVariants } from 'components/ui/button'
import { Avatar, AvatarImage } from 'components/ui/avatar'
import ProfileSettings from './profile-settings'

interface ChatSidebarProps {
  isCollapsed: boolean
  links: {
    id: string
    lead: { firstName: string }
    variant?: string
    messages: any[]
  }[]
  onClickItem: (id: string) => void
  isMobile: boolean
  profile: any
}

export function ChatSidebar({
  links,
  isCollapsed,
  isMobile,
  onClickItem,
  profile,
}: ChatSidebarProps) {
  return (
    <div
      data-collapsed={isCollapsed}
      className="relative group flex flex-col h-full gap-4 p-2 data-[collapsed=true]:p-2 "
    >
      {!isCollapsed && (
        <div className="flex justify-between p-2 items-center">
          <div className="flex w-full gap-2 items-center text-2xl">
            <p className="font-medium">Chats</p>
            <span className="text-zinc-300">({links.length})</span>
            <div className="flex-1" />
            {profile && <ProfileSettings profile={profile} />}
          </div>
        </div>
      )}
      <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {links.map((link, index) =>
          isCollapsed ? (
            <TooltipProvider key={index}>
              <Tooltip key={index} delayDuration={0}>
                <TooltipTrigger asChild>
                  <Link to="#">
                    <span className="sr-only">{link.lead.firstName}</span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent
                  side="right"
                  className="flex items-center gap-4"
                >
                  {link.lead.firstName}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <Link
              to="#"
              key={index}
              onClick={() => onClickItem(link.id)}
              className={cn(
                buttonVariants({ variant: 'secondary', size: 'xl' }),
                link.variant === 'grey' &&
                  'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white shrink',
                'justify-start gap-4'
              )}
            >
              <Avatar className="flex justify-center items-center">
                <AvatarImage
                  src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                  alt="@shadcn"
                  width={6}
                  height={6}
                  className="w-10 h-10 "
                />
              </Avatar>
              <div className="flex flex-col max-w-28">
                <span className="text-[1.1rem] truncate">
                  {link.lead.firstName}
                </span>
                {link.messages?.length > 0 && (
                  <span
                    className="text-xs truncate"
                    style={{
                      color: 'black',
                    }}
                  >
                    {link.messages?.[0].content}
                    <span
                      style={{
                        color: 'white',
                        display: 'inline-block',
                        borderRadius: '50%',
                        background: 'red',
                        padding: '2px 6px',
                        marginLeft: '20px',
                      }}
                    >
                      {link.messages?.length}
                    </span>
                  </span>
                )}
              </div>
            </Link>
          )
        )}
      </nav>
    </div>
  )
}
