import { createContext, useContext, useMemo, useState } from 'react'
import {
  useGetBranchesCountQuery,
  useGetBranchesQuery,
} from 'app/features/branch'
import {
  useGetDepartmentsQuery,
  useGetDepartmentsCountQuery,
} from 'app/features/department'
import {
  useGetSpecialistsCountQuery,
  useGetSpecialistsQuery,
} from 'app/features/specialist'
import { useGetGroupsQuery } from 'app/features/group'
import { useGetSourcesQuery } from 'app/features/source'
import {
  useGetSettingsQuery,
  useGetAccessPeriodQuery,
  useGetNotQualifiedReasonsQuery,
} from 'app/features/settings'
import {
  useGetCanBeFollowedUpLeadsCountQuery,
  useGetLeadsCountQuery,
} from 'app/features/lead'

export const AppContext = createContext<any | null>(null)

export const AppProvider = ({ children }: { children: any }) => {
  let isAppLoading = true
  const [filters, setFilters] = useState<DashboardFilters | null>(null)
  const branches = useGetBranchesQuery()
  const departments = useGetDepartmentsQuery()
  const specialists = useGetSpecialistsQuery()
  const groups = useGetGroupsQuery()
  const sources = useGetSourcesQuery()
  const settings = useGetSettingsQuery()
  const leadsCount = useGetLeadsCountQuery(filters)
  const branchesCount = useGetBranchesCountQuery(filters)
  const departmentsCount = useGetDepartmentsCountQuery(filters)
  const specialistsCount = useGetSpecialistsCountQuery(filters)
  const allLeadsCount = useGetLeadsCountQuery(null)
  const allBranchesCount = useGetBranchesCountQuery(null)
  const allDepartmentsCount = useGetDepartmentsCountQuery(null)
  const allSpecialistsCount = useGetSpecialistsCountQuery(null)
  const accessPeriod = useGetAccessPeriodQuery()
  const notQualifiedReasons = useGetNotQualifiedReasonsQuery()
  const canBeFollowedUpLeadsCount =
    useGetCanBeFollowedUpLeadsCountQuery(filters)

  isAppLoading = useMemo(
    () =>
      branches.isLoading ||
      departments.isLoading ||
      specialists.isLoading ||
      groups.isLoading ||
      sources.isLoading ||
      settings.isLoading ||
      notQualifiedReasons.isLoading,
    [
      branches.isLoading,
      departments.isLoading,
      specialists.isLoading,
      groups.isLoading,
      sources.isLoading,
      settings.isLoading,
      notQualifiedReasons.isLoading,
    ]
  )

  return (
    <AppContext.Provider
      value={{
        branches: branches.data,
        departments: departments.data,
        specialists: specialists.data,
        groups: groups.data,
        sources: sources.data,
        settings: settings.data,
        leadsCount: leadsCount?.data?.count,
        allLeadsCount: allLeadsCount?.data?.count,
        isAppLoading: isAppLoading,
        accessPeriod: accessPeriod.data,
        filters: filters,
        setFilters: setFilters,
        branchesCount: branchesCount?.data?.count,
        allBranchesCount: allBranchesCount?.data?.count,
        allDepartmentsCount: allDepartmentsCount?.data?.count,
        allSpecialistsCount: allSpecialistsCount?.data?.count,
        notQualifiedReasons: notQualifiedReasons?.data,
        canBeFollowedUpLeadsCount: canBeFollowedUpLeadsCount?.data?.count,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default function useApp() {
  return useContext(AppContext)
}
