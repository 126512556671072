import { api } from '../api'

type Lead = {
  id: string
  firstName: string
}

type Contact = {
  id: string
  phoneNumber: string
  lead: Lead
  messages: Message[]
}

type Message = {
  content: string
  receiverId: string
  senderId: string
  sender: { firstName: string }
}

type Conversation = {
  messages: Message[]
  createdAt: Date
  leadId: string
  phoneNumber: string
}

type MediaResponse = {
  media: string
}

type ContactResponse = Contact[]

type ProfileResponse = {
  profile_picture_url: string
}

export const whatsappApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContacts: builder.query<ContactResponse, void>({
      query: () => '/whatsapp',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Contacts' as const,
                id,
              })),
              { type: 'Contacts', id: 'LIST' },
            ]
          : [{ type: 'Contacts', id: 'LIST' }],
    }),
    getConversation: builder.query<Conversation, string | undefined>({
      query: (id) => `/whatsapp/${id}`,
      providesTags: ['Conversations'],
    }),
    sendMessage: builder.mutation({
      query: (message) => ({
        url: '/whatsapp/message',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [{ type: 'Conversations' }],
    }),
    markAsRead: builder.mutation({
      query: (id) => ({
        url: `/whatsapp/mark-as-read/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Contacts', 'Conversations'],
    }),
    getMedia: builder.query<MediaResponse, string | undefined>({
      query: (mediaId) => `/whatsapp/message/${mediaId}`,
    }),
    sendMedia: builder.mutation({
      query: (message) => ({
        url: '/whatsapp/media',
        method: 'POST',
        body: message,
      }),
      invalidatesTags: [{ type: 'Conversations' }],
    }),
    getProfile: builder.query<ProfileResponse, void>({
      query: () => `/whatsapp/profile`,
      providesTags: ['WhatsappProfile'],
    }),
    updateProfile: builder.mutation({
      query: (profile) => ({
        url: `/whatsapp/profile`,
        method: 'POST',
        body: profile,
      }),
      invalidatesTags: [{ type: 'WhatsappProfile' }],
    }),
    initAccount: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/account`,
        method: 'POST',
        body: data,
      }),
    }),
    updateAccount: builder.mutation({
      query: (data) => ({
        url: `/whatsapp/account`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
})

export const {
  useGetContactsQuery,
  useGetConversationQuery,
  useSendMessageMutation,
  useMarkAsReadMutation,
  useGetMediaQuery,
  useSendMediaMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useInitAccountMutation,
  useUpdateAccountMutation,
} = whatsappApi
