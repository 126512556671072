import { removeCredentials } from 'app/slices/user'
import { accessibleTab, cn } from 'lib/utils'
import {
  TbLayoutGrid,
  TbListDetails,
  TbLogout,
  TbSettings,
  TbProgressCheck,
  TbStethoscope,
  TbUserScan,
  TbCircleDashedX,
  TbBrandWhatsapp,
  TbMenu2,
} from 'react-icons/tb'
import { HiMiniEyeSlash } from 'react-icons/hi2'

import { CiSquareQuestion } from 'react-icons/ci'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { Switch } from 'components/ui/switch'
import { useEffect, useState } from 'react'
import { Button } from 'components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from 'components/ui/sheet'
import useApp from 'hooks/useApp'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'
import { IoMdNotificationsOutline } from 'react-icons/io'

export default function header() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useSelector((state: any) => state.user)
  const { canBeFollowedUpLeadsCount } = useApp()

  const [show, setShow] = useState(pathname == '/not-show' ? false : true)
  const showSwitchHandler = () => {
    setShow((prev) => !prev)
    if (!show) {
      navigate('/show')
    } else navigate('/not-show')
  }

  const [animateFollowUpCount, setAnimateFollowUpCount] = useState(false)
  useEffect(() => {
    setAnimateFollowUpCount(true)
    const timer = setTimeout(() => {
      setAnimateFollowUpCount(false)
    }, 3000 * 0.7)
    return () => clearTimeout(timer)
  }, [canBeFollowedUpLeadsCount])
  return (
    <header className="md:hidden flex h-14 items-center gap-4 border-b px-4 lg:h-[60px] lg:px-6  sticky top-0 z-[400] bg-white">
      {/* mobile menu */}
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <TbMenu2 />
            <span className="sr-only">Toggle navigation menu</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="flex flex-col  z-[450] ">
          <nav className="grid gap-2 text-lg font-medium pt-10">
            {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
              <Link
                to="/dashboard"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/dashboard')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <TbLayoutGrid
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="">Dashboard</span>
              </Link>
            )}
            {user.role !== 'ADMIN' && accessibleTab('Dashboard', user) && (
              <Link
                to="/agents-dashboard"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/agents-dashboard')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <TbLayoutGrid
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="">Dashboard</span>
              </Link>
            )}
            {accessibleTab('Leads', user) && (
              <Link
                to="/leads"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group relative',
                  pathname?.startsWith('/leads')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <TbListDetails
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="">Leads</span>
                {canBeFollowedUpLeadsCount && canBeFollowedUpLeadsCount > 0 ? (
                  <span className="absolute top-1/3 right-9 -translate-y-1/2 flex h-1 w-1">
                    <span className="relative ">
                      <IoMdNotificationsOutline
                        size={20}
                        className={`     ${
                          pathname.startsWith('/leads')
                            ? 'text-indigo-600'
                            : 'text-gray-500'
                        }  `}
                      />

                      <span
                        className={` absolute -right-2 -top-1 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white  text-[0.5rem] ${animateFollowUpCount ? 'animate-pulse-once' : ''}`}
                      >
                        {canBeFollowedUpLeadsCount <= 99
                          ? canBeFollowedUpLeadsCount
                          : '+99'}
                      </span>
                    </span>
                  </span>
                ) : (
                  ''
                )}
              </Link>
            )}
            {accessibleTab('Booked', user) && (
              <Link
                to="/booked"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/booked')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <TbProgressCheck
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="">Booked</span>
              </Link>
            )}
            {accessibleTab('Show', user) && (
              <span
                onClick={() => {
                  if (!pathname.includes('show')) {
                    if (show) navigate('/show')
                    else navigate('/not-show')
                  }
                }}
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group hover:cursor-pointer',
                  pathname?.includes('show')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                {show ? (
                  <FaEye size={18} className="group-hover:text-indigo-600" />
                ) : (
                  <HiMiniEyeSlash
                    size={18}
                    className="group-hover:text-indigo-600"
                  />
                )}
                <span className="">{show ? 'Show' : 'Not show'}</span>
                {pathname.includes('show') && (
                  <span className={` flex-1 flex justify-end`}>
                    <Switch
                      checked={show}
                      onCheckedChange={showSwitchHandler}
                    />
                  </span>
                )}
              </span>
            )}
            {accessibleTab('Not_Qualified', user) && (
              <Link
                to="/not-qualified"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/not-qualified')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <TbCircleDashedX
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="">NotQualified</span>
              </Link>
            )}
            {accessibleTab('Insurance', user) && (
              <Link
                to="/insurance"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname == '/insurance'
                    ? 'bg-indigo-600 text-white'
                    : 'text-gray-600'
                )}
              >
                <IoShieldCheckmarkSharp
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="hover:text-gray-800">Insurance</span>
              </Link>
            )}
            {accessibleTab('Insurance_Portal', user) && (
              <Link
                to="/insurance-portal"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/insurance-portal')
                    ? 'bg-indigo-600 text-white'
                    : 'text-gray-600'
                )}
              >
                <IoShieldCheckmarkSharp
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="hover:text-gray-800">Insurance Portal</span>
              </Link>
            )}

            {user.role === 'ADMIN' && (
              <Link
                to="/users"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/users')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <TbUserScan size={18} className="group-hover:text-indigo-600" />
                <span className="">Users</span>
              </Link>
            )}
            {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
              <Link
                to="/agents-login-requests"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/agents-login-requests')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <CiSquareQuestion
                  size={18}
                  className="group-hover:text-indigo-600"
                />
                <span className="">Login Requests</span>
              </Link>
            )}
            {/* <Link
  to="/whatsapp"
  className={cn(
    'p-3 rounded-md flex gap-2 items-center duration-300 group',
    pathname?.startsWith('/whatsapp')
      ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
      : 'text-foreground/60'
  )}
>
  <TbBrandWhatsapp size={18} className="group-hover:text-indigo-600" />
  <span className="">Whatsapp</span>
</Link> */}
            {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
              <Link
                to="/settings"
                className={cn(
                  'p-3 rounded-md flex gap-2 items-center duration-300 group',
                  pathname?.startsWith('/settings')
                    ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
                    : 'text-foreground/60'
                )}
              >
                <TbSettings size={18} className="group-hover:text-indigo-600" />
                <span className="">Settings</span>
              </Link>
            )}
          </nav>
          <div className="flex-1" />
          <div
            className="cursor-pointer p-3 rounded-md flex flex-col gap-1 duration-300 hover:bg-gray-100"
            onClick={() => dispatch(removeCredentials())}
          >
            <div className="flex items-center gap-2">
              <TbLogout size={15} />
              <span className="flex text-sm">Logout</span>
            </div>
          </div>
          <div className="pb-3">
            <div className="bg-gray-100 p-2 rounded-md mb-3">
              <div className="flex text-sm capitalize">
                {user?.firstName} {user?.lastName}
              </div>
              <div className="flex text-xs text-gray-500">{user?.email}</div>
            </div>
          </div>
        </SheetContent>
      </Sheet>

      <div className="flex gap-3 items-center ml-auto">
        <TbStethoscope size={20} className="text-indigo-600" />
        <h1 className="font-bold text-xl tracking-tight">Doctorna</h1>
      </div>
    </header>
  )
}
