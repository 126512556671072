import {
  useGetProfileQuery,
  useInitAccountMutation,
} from 'app/features/whatsapp'
import PhoneInput from 'components/shared/phone-input'
import { Button } from 'components/ui/button'
import ProfileSettings from 'pages/whatsapp/components/profile-settings'
import { useState } from 'react'

export default function MetaLogin() {
  const [initAccount] = useInitAccountMutation()
  const [phoneNumber, setPhoneNumber] = useState()

  const { data: profile } = useGetProfileQuery()

  const fbAsyncInit = function () {
    // JavaScript SDK configuration and setup
    window.FB.init({
      appId: process.env.REACT_APP_META_ID, // Meta App ID
      cookie: true, // enable cookies
      xfbml: true, // parse social plugins on this page
      version: 'v18.0', //Graph API version
    })
  }

  fbAsyncInit()

  function launchWhatsAppSignup() {
    // Launch Facebook login
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          const code = response.authResponse.code
          console.log('code ', code)
          console.log('response.authResponse ', response.authResponse)
          initAccount({ code, phoneNumber })
          //Use this token to call the debug_token API and get the shared WABA's ID
          // resource.storeCredentials({token: code})
          //     .then((response) => {
          //         router.push({name: 'integration.whatsapp.select-phone-number'})
          //     })
          //     .catch(setError);
        } else {
          console.log('User cancelled login or did not fully authorize.')
        }
      },
      {
        config_id: process.env.REACT_APP_META_CONFIG_ID, // configuration ID obtained in the previous step goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true,
        extras: {
          setup: {
            // Prefilled data can go here
          },
        },
      }
    )
  }

  return (
    <div className="flex flex-col space-y-4 justify-center items-center h-screen">
      {profile ? (
        <ProfileSettings profile={profile} />
      ) : (
        <>
          <div className="w-64 flex items-center gap-3">
            <PhoneInput
              label="Phone Number"
              id="phone"
              handleChange={(value: any) => setPhoneNumber(value)}
              value={phoneNumber}
              required
            />
          </div>
          <Button
            size="sm"
            onClick={() => launchWhatsAppSignup()}
            disabled={!phoneNumber}
          >
            Login with Facebook
          </Button>
        </>
      )}
    </div>
  )
}
