import React from 'react'
import { ChatList } from './chat-list'
import {
  useGetConversationQuery,
  useSendMediaMutation,
  useSendMessageMutation,
} from 'app/features/whatsapp'
import { prepareMediaFormData } from 'lib/utils'

interface ChatProps {
  messages?: any[]
  selectedConversationId: string
  isMobile: boolean
}

export function Chat({
  messages,
  selectedConversationId,
  isMobile,
}: ChatProps) {
  const [messagesState, setMessages] = React.useState<any[]>(messages ?? [])

  const [sendMessage] = useSendMessageMutation()

  const [sendMedia] = useSendMediaMutation()

  const { data: conversation } = useGetConversationQuery(selectedConversationId)

  const sendMessageClick = (newMessage: any) => {
    sendMessage({
      text: newMessage,
      messaging_product: 'whatsapp',
      to: conversation?.phoneNumber,
    })
    setMessages([...messagesState, newMessage])
  }

  const sendMediaClick = (newMessage: any) => {
    const data = prepareMediaFormData({
      my_file: newMessage.file,
      type: newMessage.type,
      to: conversation?.phoneNumber || '',
    })
    sendMedia(data)
    setMessages([...messagesState, newMessage])
  }

  return (
    <div className="flex flex-col justify-between w-full h-full">
      <ChatList
        messages={conversation?.messages}
        selectedUser={conversation?.leadId}
        sendMessage={sendMessageClick}
        sendMedia={sendMediaClick}
        isMobile={isMobile}
      />
    </div>
  )
}
