import { TbFaceIdError } from 'react-icons/tb'

export default function error({ message }: { message: string }) {
  return (
    <div className="text-xs font-medium flex flex-col justify-center items-center gap-3">
      <TbFaceIdError className="text-rose-600" size={30} />
      {message}
    </div>
  )
}
