import { useUpdateLeadMutation } from 'app/features/lead'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { useEffect, useState } from 'react'
import NotQualifiedInput from 'components/shared/notqualified-input'
import { useGetNotQualifiedReasonsQuery } from 'app/features/settings'
import Loader from 'components/shared/loader'

export default function NotQualified({ open, setOpen, lead }: any) {
  const [error, setError] = useState('')
  const [reason, setReason] = useState<string | null>(null)
  const [customReason, setCustomReason] = useState('')

  const [updateLead] = useUpdateLeadMutation()

  const { data, isLoading, isError } = useGetNotQualifiedReasonsQuery()
  const [list, setList] = useState<any>([])
  useEffect(() => {
    if (data) setList([...data, { id: 'other', name: 'Other...' }])
  }, [data])

  useEffect(() => {
    setReason(null)
    setCustomReason('')
    setError('')
  }, [open])

  async function setNotQualified() {
    setError('')

    if (reason === null || (reason === 'other' && customReason === '')) {
      setError('Please add reason for unQualification')
      return
    }

    try {
      if (reason !== null) {
        const notQualifiedReason =
          customReason || list.find((item: any) => item.id === reason).name
        await updateLead({
          id: lead.id,
          data: {
            status: 'NOT_QUALIFIED',
            notQualifiedReason,
          },
        })
      }
      setOpen(false)
    } catch (error) {
      setError('Something went wrong')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        {isLoading && (
          <div className="h-screen grid place-content-center">
            <Loader />
          </div>
        )}
        {!isLoading && (
          <>
            <AlertDialogHeader>
              <div className="flex items-center justify-between text-black">
                <h1 className="text-lg font-semibold">
                  {lead.firstName} {lead.lastName}
                </h1>
              </div>
              <p className="text-gray-500 text-xs">{lead.phone}</p>
              <p className="text-gray-500 text-xs">{lead.email}</p>
              {error !== '' && <p className="text-red-500 text-xs">{error}</p>}

              <NotQualifiedInput
                reason={reason}
                setReason={setReason}
                customReason={customReason}
                setCustomReason={setCustomReason}
                list={list}
              />
            </AlertDialogHeader>

            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <Button onClick={setNotQualified}>Submit</Button>
            </AlertDialogFooter>
            <div className="w-full border-t border-dashed" />
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
