import { useUpdateBrandMutation } from 'app/features/settings'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'

import { useEffect, useRef, useState } from 'react'
import useApp from 'hooks/useApp'
import { Label } from 'components/ui/label'
import { Textarea } from 'components/ui/textarea'

export default function Brand() {
  const { settings } = useApp()
  const [error, setError] = useState('')
  const [updateBrand, { isLoading, isError }] = useUpdateBrandMutation()

  const [description, setDescription] = useState<any>('')

  useEffect(() => {
    setDescription(settings ? settings[0]?.description : '')
  }, [settings])

  async function update() {
    setError('')
    if (!description) {
      return setError('description is required')
    }
    const data = {
      id: settings[0].id,
      description,
    }

    const result: any = await updateBrand(data)
    if (result?.error) {
      return setError('Failed to update brand')
    }
  }

  if (isLoading || !settings[0]) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  return (
    <>
      <div className="flex flex-col p-6 gap-3 ">
        <div className="py-3 border-b border-dashed  items-center gap-3 ">
          <div className="flex flex-col gap-1 w-full">
            <h1 className="text-lg font-medium">Logo</h1>
            <div className="flex justify-center items-center p-2  ">
              <img
                src={
                  'https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=338&ext=jpg&ga=GA1.1.2008272138.1726099200&semt=ais_hybrid'
                }
                width={200}
                height={200}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex items-center gap-3 max-md:flex-col">
          <div className="w-full space-y-2">
            <Label>Description</Label>
            <Textarea
              name="description"
              id="description"
              value={description}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-5 flex justify-between items-center">
        {error !== '' && (
          <p className="px-3 py-2 bg-rose-100 rounded-md text-xs font-medium text-rose-600">
            {error}
          </p>
        )}
        <div className="flex-1" />
        <Button onClick={update} size="sm">
          Update
        </Button>
      </div>
    </>
  )
}
