import { useAddFormLeadMutation } from 'app/features/lead'
import { CustomInput } from 'components/shared/custom-input'
import { CustomTextArea } from 'components/shared/custom-text-area'
import { Button } from 'components/ui/button'
import { useState } from 'react'
import { isEmail, isPhone } from 'lib/validation'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { Label } from 'components/ui/label'
import PhoneInput from 'components/shared/phone-input'
import CustomNationalityInput from 'components/shared/nationality-input'
import { useSearchParams } from 'react-router-dom'

const initialLead = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  interest: '',
  nationality: '',
  gender: null,
}

export default function Form() {
  const [queryParameters] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [addFormLead, { isError, isLoading }] = useAddFormLeadMutation()
  const [data, setData] = useState<any>(initialLead)
  const [error, setError] = useState<string>('')
  const [screen, setScreen] = useState<string>('')

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  async function createLead(e: any) {
    e.preventDefault()
    setError('')

    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (data.email && !isEmail(data.email)) {
      return setError('Valid email is required')
    }

    if (!isPhone(data.phone)) {
      return setError('Valid phone is required')
    }

    setLoading(true)

    const result = await addFormLead({
      ...data,
      status: 'NEW',
      formName: queryParameters.get('name'),
      token: queryParameters.get('token'),
    })

    if (isError) {
      setError('Error happened while sending data, please try again')
    } else {
      setScreen('done')
    }

    // if (response.status === 200) {
    //   setScreen('done')
    //   setLoading(false)
    // } else {
    //   setLoading(false)
    //   setError('Error happened while sending data, please try again')
    // }
  }
  return (
    <>
      {screen === 'done' && (
        <div className="w-full text-center p-6">Thank you</div>
      )}
      {screen !== 'done' && (
        <div className="w-full p-6 bg-white">
          <form onSubmit={createLead} className="space-y-3">
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <CustomInput
                name="firstName"
                label="First Name"
                value={data.firstName}
                setValue={handleChange}
                type="text"
                required
                disabled={loading}
              />
              <CustomInput
                name="lastName"
                label="Last Name"
                value={data.lastName}
                setValue={handleChange}
                type="text"
                disabled={loading}
              />
            </div>
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <CustomInput
                name="email"
                label="Email"
                value={data.email}
                setValue={handleChange}
                type="email"
                disabled={loading}
              />
              <PhoneInput
                label="Phone Number"
                id="phone"
                handleChange={(value: any) =>
                  setData({
                    ...data,
                    phone: value,
                  })
                }
                value={data.phone}
                required
                disabled={loading}
              />
            </div>
            <div className="w-full flex flex-col md:flex-row items-center gap-3">
              <div className="w-full space-y-2">
                <Label>Gender</Label>
                <Select
                  value={data.gender}
                  onValueChange={(value: any) =>
                    setData({
                      ...data,
                      gender: value,
                    })
                  }
                  disabled={loading}
                >
                  <SelectTrigger>
                    <SelectValue>{data.gender}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="male">Male</SelectItem>
                      <SelectItem value="female">Female</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <CustomNationalityInput
                label="Nationality"
                id="nationality"
                handleChange={(value: any) =>
                  setData({
                    ...data,
                    nationality: value,
                  })
                }
                value={data.nationality}
                required
                disabled={loading}
              />
            </div>
            <div className="w-full flex items-center gap-3">
              <CustomTextArea
                label="Interest"
                name="interest"
                value={data.interest}
                setValue={handleChange}
                type="text"
                disabled={loading}
              />
            </div>
            <div className="flex gap-5 items-center">
              <Button type="submit">Send</Button>
              <p className="text-rose-500">{error}</p>
            </div>
          </form>
        </div>
      )}
    </>
  )
}
