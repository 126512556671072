import { useState } from 'react'
import LeadInterest from './lead-interest'
import LeadInfo from './lead-info'
import { Button } from 'components/ui/button'
import { useAddLeadMutation } from 'app/features/lead'
import { useSelector } from 'react-redux'
import { isEmail, isPhone } from 'lib/validation'
import { useNavigate } from 'react-router-dom'
import Loader from 'components/shared/loader'
import useApp from 'hooks/useApp'

const initialLead = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  alternativePhone: '',
  userId: '',
  sourceId: '',
  branchIDs: [],
  departmentIDs: [],
  specialistIDs: [],
  interest: '',
  nationality: '',
  gender: null,
  status: '',
  notQualifiedReason: '',
  isInsuranceChecked: false,
}

export default function LeadForm() {
  const navigate = useNavigate()
  const { branches, departments, specialists } = useApp()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const { user } = useSelector((state: any) => state.user)

  const [addLead, { isLoading, isError }] = useAddLeadMutation()
  const [data, setData] = useState<any>(initialLead)

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  function addBranch(id: any) {
    if (data.branchIDs.includes(id)) {
      const newBranches = data.branchIDs.filter((branch: any) => branch !== id)
      setData({ ...data, branchIDs: newBranches })
    } else {
      setData({ ...data, branchIDs: [...data.branchIDs, id] })
    }
  }

  function addDepartment(id: any) {
    if (data.departmentIDs.includes(id)) {
      const newDepartments = data.departmentIDs.filter(
        (department: any) => department !== id
      )
      setData({ ...data, departmentIDs: newDepartments })
    } else {
      setData({ ...data, departmentIDs: [...data.departmentIDs, id] })
    }
  }

  function addSpecialist(id: any) {
    if (data.specialistIDs.includes(id)) {
      const newSpecialists = data.specialistIDs.filter(
        (specialist: any) => specialist !== id
      )
      setData({ ...data, specialistIDs: newSpecialists })
    } else {
      setData({ ...data, specialistIDs: [...data.specialistIDs, id] })
    }
  }

  async function createLead() {
    setError('')
    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (data.email && !isEmail(data.email)) {
      return setError('Valid email is required')
    }

    if (!isPhone(data.phone)) {
      return setError('Valid phone is required')
    }
    if (data.alternativePhone && !isPhone(data.alternativePhone)) {
      return setError('Valid Alternative phone is required')
    }
    if (!isPhone(data.whatsappNumber)) {
      return setError('Valid whatsapp number is required')
    }

    if (data.sourceId === '') {
      return setError('Source is required')
    }

    setLoading(true)

    const result: any = await addLead({
      ...data,
      status: 'NEW',
      userId: user.id,
    })
    if (result?.error?.status === 409) {
      setLoading(false)
      return setError('Lead already exists')
    }
    const lead = result?.data

    setData(initialLead)
    setLoading(false)
    navigate(`/lead-details/${lead.id}`)
  }

  return (
    <div className="flex-1 h-full bg-white border rounded-md overflow-hidden overflow-y-scroll max-md:pb-10">
      <div className="flex flex-col p-6 gap-3">
        <div className="py-3 border-b border-dashed items-center gap-3">
          <div className="flex flex-col gap-1">
            {(isLoading || loading) && (
              <div className="h-40 grid place-content-center">
                <Loader />
              </div>
            )}

            <h1 className="text-lg font-medium">Create lead</h1>
            {error !== '' && <p className="text-red-500 text-xs">{error}</p>}

            <div className="flex justify-end">
              <LeadInfo
                data={data}
                setData={setData}
                handleChange={handleChange}
              />
            </div>
            <div className="flex-1" />

            <div className="flex justify-end">
              <LeadInterest
                data={data}
                setData={setData}
                addBranch={addBranch}
                addDepartment={addDepartment}
                addSpecialist={addSpecialist}
                isDetailsPage={false}
                isCreatePage={true}
                branches={branches}
                departments={departments}
                specialists={specialists}
              />
            </div>

            <div className="flex-1" />

            <div className="pr-5 text-right">
              <Button onClick={createLead}>Create</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
