import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

import { Provider } from 'react-redux'
import { store, persister } from './app/store'
import { PersistGate } from 'redux-persist/integration/react'
import { Context } from './lib/context'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    {
      <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <Context>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Context>
        </PersistGate>
      </Provider>
    }
  </React.StrictMode>
)
