import { CustomInput } from 'components/shared/custom-input'
import { CustomSelect } from 'components/shared/custom-select'
import PhoneInput from 'components/shared/phone-input'
import { CustomTextArea } from 'components/shared/custom-text-area'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import CustomNationalityInput from 'components/shared/nationality-input'
import useApp from 'hooks/useApp'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Checkbox } from 'components/ui/checkbox'
import { useState } from 'react'
import { da } from 'date-fns/locale'
import dayjs from 'dayjs'

export default function LeadInfo({
  data,
  setData,
  handleChange,
  isDetailsPage,
}: any) {
  const { sources } = useApp()
  const [checkedWhatsappAsPhone, setCheckedWhatsappAsPhone] = useState(false)

  return (
    <div className="w-full space-y-3 px-1 ">
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <CustomInput
          name="firstName"
          label="First Name"
          value={data.firstName}
          setValue={handleChange}
          type="text"
          required
          disabled={isDetailsPage}
        />
        <CustomInput
          name="lastName"
          label="Last Name"
          value={data.lastName}
          setValue={handleChange}
          type="text"
          disabled={isDetailsPage}
        />
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <CustomInput
          name="email"
          label="Email"
          value={data.email}
          setValue={handleChange}
          type="email"
          disabled={isDetailsPage}
        />
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <PhoneInput
          label="Phone Number"
          id="phone"
          handleChange={(value: any) =>
            setData({
              ...data,
              phone: value,
              ...(checkedWhatsappAsPhone && {
                whatsappNumber: value,
              }),
            })
          }
          value={data.phone}
          required
          disabled={isDetailsPage}
        />

        <PhoneInput
          label="Alternative Phone Number"
          id="alternativePhone"
          handleChange={(value: any) =>
            setData({
              ...data,
              alternativePhone: value,
            })
          }
          value={data.alternativePhone}
          disabled={isDetailsPage}
        />
      </div>

      <div className="w-full flex items-center gap-3 ">
        <PhoneInput
          label="Whatsapp Number"
          id="whatsappNumber"
          className="w-full"
          handleChange={(value: any) =>
            setData({
              ...data,
              whatsappNumber: value,
            })
          }
          value={data.whatsappNumber}
          required
          disabled={isDetailsPage || checkedWhatsappAsPhone}
        />
        <div className="flex w-full pt-6 items-center space-x-2 ">
          <Checkbox
            id="whatsapp"
            checked={checkedWhatsappAsPhone}
            onCheckedChange={(checked) => {
              if (checked) {
                setData({
                  ...data,
                  whatsappNumber: data.phone,
                })
              } else {
                setData({
                  ...data,
                  whatsappNumber: '',
                })
              }
              setCheckedWhatsappAsPhone((prev) => !prev)
            }}
          />
          <label
            htmlFor="whatsapp"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Use phone number
          </label>
        </div>
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <div className="w-full space-y-2">
          <Label>Gender</Label>
          <Select
            value={data.gender}
            onValueChange={(value: any) =>
              setData({
                ...data,
                gender: value,
              })
            }
            disabled={isDetailsPage}
          >
            <SelectTrigger>
              <SelectValue>{data.gender}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="male">Male</SelectItem>
                <SelectItem value="female">Female</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <CustomNationalityInput
          label="Nationality"
          id="nationality"
          handleChange={(value: any) =>
            setData({
              ...data,
              nationality: value,
            })
          }
          value={data.nationality}
          required
          disabled={isDetailsPage}
        />
      </div>
      <div className="w-full flex items-center gap-3 max-md:flex-col ">
        <CustomSelect
          label="Source"
          list={sources}
          value={data.sourceId}
          setValue={(value: any) =>
            setData({
              ...data,
              sourceId: value,
            })
          }
          required
          disabled={isDetailsPage}
        />
      </div>

      <div className="w-full flex items-center gap-3 max-md:flex-col">
        <CustomTextArea
          label="Interest"
          name="interest"
          value={data.interest}
          setValue={handleChange}
          type="text"
          disabled={isDetailsPage}
        />
      </div>
      <div className="w-full flex items-center gap-3  py-3">
        <Label
          htmlFor="is-insurance-checked"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          Insurance Patient
        </Label>
        <Checkbox
          id="is-insurance-checked"
          checked={data?.isInsuranceClaimChecked}
          disabled={
            isDetailsPage ||
            (data?.insuranceClaims?.length &&
              data?.insuranceClaims[data.insuranceClaims.length - 1].status ===
                'Approved')
          }
          onCheckedChange={(checked) =>
            setData((prev: any) => {
              return {
                ...prev,
                isInsuranceClaimChecked: !prev.isInsuranceClaimChecked,
              }
            })
          }
        />
      </div>

      {isDetailsPage &&
      data?.isInsuranceClaimChecked &&
      data?.insuranceClaims?.length ? (
        <div className="w-full flex items-center gap-3 ">
          <div className="w-full space-y-2">
            <Label className="block">Recent Insurance Claim</Label>
            <div className="bg-gray-50 rounded-md p-3 space-y-3 border flex justify-between items-end">
              <div>
                <p className="text-indigo-700 text-xs">
                  {dayjs(
                    data?.insuranceClaims[data.insuranceClaims.length - 1]
                      .createdAt
                  ).format('DD/MM/YYYY')}
                </p>
                {data?.insuranceClaims[data.insuranceClaims.length - 1].comment}
                <p className="text-xs">
                  Status:{' '}
                  {
                    data?.insuranceClaims[data.insuranceClaims.length - 1]
                      .status
                  }{' '}
                </p>
              </div>
              <div>
                <p className="text-xs ">
                  Claimed by:{' '}
                  {
                    data?.insuranceClaims[data.insuranceClaims.length - 1]?.user
                      ?.firstName
                  }{' '}
                  {
                    data?.insuranceClaims[data.insuranceClaims.length - 1]?.user
                      ?.lastName
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isDetailsPage && data?.status == 'NOT_QUALIFIED' && (
        <div className="w-full flex items-center gap-3 ">
          <div className="w-full space-y-2">
            <Label className="block">Not Qualified Reason</Label>
            <Input
              name="not qualified"
              value={data.notQualifiedReason}
              disabled={true}
            />
          </div>
        </div>
      )}
    </div>
  )
}
