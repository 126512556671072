import { removeCredentials } from 'app/slices/user'
import { accessibleTab, cn } from 'lib/utils'
import {
  TbLayoutGrid,
  TbListDetails,
  TbLogout,
  TbSettings,
  TbProgressCheck,
  TbStethoscope,
  TbUserScan,
  TbCircleDashedX,
  TbBrandWhatsapp,
  TbMenu2,
} from 'react-icons/tb'
import { GiWhistle } from 'react-icons/gi'

import { HiMiniEyeSlash } from 'react-icons/hi2'

import { CiSquareQuestion } from 'react-icons/ci'

import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { Switch } from 'components/ui/switch'
import { useEffect, useState } from 'react'

import useApp from 'hooks/useApp'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'
import { IoMdNotificationsOutline } from 'react-icons/io'

export default function Sidebar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useSelector((state: any) => state.user)
  const { canBeFollowedUpLeadsCount } = useApp()
  const [show, setShow] = useState(pathname == '/not-show' ? false : true)
  const showSwitchHandler = () => {
    setShow((prev) => !prev)
    if (!show) {
      navigate('/show')
    } else navigate('/not-show')
  }

  const [animateFollowUpCount, setAnimateFollowUpCount] = useState(false)
  useEffect(() => {
    setAnimateFollowUpCount(true)
    const timer = setTimeout(() => {
      setAnimateFollowUpCount(false)
    }, 3000 * 0.7)
    return () => clearTimeout(timer)
  }, [canBeFollowedUpLeadsCount])

  return (
    <div className="hidden border-r md:block">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex gap-3 h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
          <TbStethoscope size={20} className="text-indigo-600" />
          <h1 className="font-bold text-xl tracking-tight flex">Doctorna</h1>
        </div>
        <nav className="flex flex-col h-full p-3 gap-1">
          {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
            <Link
              to="/dashboard"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/dashboard')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbLayoutGrid size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Dashboard</span>
            </Link>
          )}
          {user.role !== 'ADMIN' && accessibleTab('Dashboard', user) && (
            <Link
              to="/agents-dashboard"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/agents-dashboard')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbLayoutGrid size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Dashboard</span>
            </Link>
          )}
          {accessibleTab('Leads', user) && (
            <Link
              to="/leads"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group relative',
                pathname?.startsWith('/leads')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbListDetails
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800 ">Leads </span>

              {canBeFollowedUpLeadsCount && canBeFollowedUpLeadsCount > 0 ? (
                <span className="absolute top-1/3 right-8 -translate-y-1/2 flex h-1 w-1">
                  <span className="relative ">
                    <IoMdNotificationsOutline
                      size={20}
                      className={`     ${
                        pathname.startsWith('/leads')
                          ? 'text-white'
                          : 'text-gray-500'
                      }  `}
                    />
                    {/* <GiWhistle size={20} className="transform scale-x-[-1]" /> */}

                    <span
                      className={` absolute -right-2 -top-1 flex items-center justify-center h-4 w-4 rounded-full bg-red-500 text-white  text-[0.5rem] ${animateFollowUpCount ? 'animate-pulse-once' : ''}`}
                    >
                      {canBeFollowedUpLeadsCount <= 99
                        ? canBeFollowedUpLeadsCount
                        : '+99'}
                    </span>
                  </span>
                </span>
              ) : (
                ''
              )}
            </Link>
          )}
          {accessibleTab('Booked', user) && (
            <Link
              to="/booked"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/booked')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbProgressCheck
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Booked</span>
            </Link>
          )}
          {accessibleTab('Show', user) && (
            <span
              onClick={() => {
                if (!pathname.includes('show')) {
                  if (show) navigate('/show')
                  else navigate('/not-show')
                }
              }}
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group hover:cursor-pointer',
                pathname?.includes('show')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              {show ? (
                <FaEye size={18} className="group-hover:text-indigo-600" />
              ) : (
                <HiMiniEyeSlash
                  size={18}
                  className="group-hover:text-indigo-600"
                />
              )}
              <span className="hover:text-gray-800">
                {show ? 'Show' : 'Not show'}
              </span>
              {pathname.includes('show') && (
                <span className={` flex-1 flex justify-end`}>
                  <Switch checked={show} onCheckedChange={showSwitchHandler} />
                </span>
              )}
            </span>
          )}
          {accessibleTab('Not_Qualified', user) && (
            <Link
              to="/not-qualified"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/not-qualified')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbCircleDashedX
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">NotQualified</span>
            </Link>
          )}

          {accessibleTab('Insurance', user) && (
            <Link
              to="/insurance"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname == '/insurance'
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <IoShieldCheckmarkSharp
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Insurance</span>
            </Link>
          )}
          {accessibleTab('Insurance_Portal', user) && (
            <Link
              to="/insurance-portal"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/insurance-portal')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <IoShieldCheckmarkSharp
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Insurance Portal</span>
            </Link>
          )}

          {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
            <Link
              to="/users"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/users')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbUserScan size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Users</span>
            </Link>
          )}
          {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
            <Link
              to="/agents-login-requests"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/agents-login-requests')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <CiSquareQuestion
                size={18}
                className="group-hover:text-indigo-600"
              />
              <span className="hover:text-gray-800">Login Requests</span>
            </Link>
          )}
          {/* <Link
          to="/whatsapp"
          className={cn(
            'p-3 rounded-md flex gap-2 items-center duration-300 group',
            pathname?.startsWith('/whatsapp')
              ? 'bg-gray-100 dark:bg-gray-800 text-indigo-600'
              : 'text-foreground/60'
          )}
        >
          <TbBrandWhatsapp size={18} className="group-hover:text-indigo-600" />
          <span className="hover:text-gray-800">Whatsapp</span>
        </Link> */}
          {(user.role === 'ADMIN' || user.role === 'SUPERADMIN') && (
            <Link
              to="/settings"
              className={cn(
                'p-3 rounded-md flex gap-2 items-center duration-300 group',
                pathname?.startsWith('/settings')
                  ? 'bg-indigo-600 text-white'
                  : 'text-gray-600'
              )}
            >
              <TbSettings size={18} className="group-hover:text-indigo-600" />
              <span className="hover:text-gray-800">Settings</span>
            </Link>
          )}
          <div className="flex-1" />
          <div
            className="cursor-pointer p-3 rounded-md flex flex-col gap-1 duration-300 hover:bg-gray-100"
            onClick={() => dispatch(removeCredentials())}
          >
            <div className="flex items-center gap-2">
              <TbLogout size={15} />
              <span className="flex text-sm">Logout</span>
            </div>
          </div>
          <div className="bg-gray-100 p-2 rounded-md">
            <div className="flex text-sm capitalize">
              {user?.firstName} {user?.lastName}
            </div>
            <div className="flex text-xs text-gray-500">{user?.email}</div>
          </div>
        </nav>
      </div>
    </div>
  )
}
