import { TbError404 } from 'react-icons/tb'
import { useSelector } from 'react-redux'

export const TabsWrapper = ({ children, tab }: any) => {
  const { user } = useSelector((state: any) => state.user)

  return (
    <div className="w-full h-full">
      {user.role === 'ADMIN' ||
      user.role === 'SUPERADMIN' ||
      user.group?.tabs?.includes(tab) ? (
        <>{children}</>
      ) : (
        <div className="w-full h-full  flex items-center justify-center">
          <div className="text-xs font-medium flex flex-col justify-center items-center gap-3">
            <TbError404 className="text-rose-600" size={30} />
            Not Found
          </div>
        </div>
      )}
    </div>
  )
}
