import { api } from '../api'

type Group = {
  id: string
  name: string
  tabs: string[]
}

type GroupResponse = Group[]

export const groupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<GroupResponse, void>({
      query: () => '/groups',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Groups' as const,
                id,
              })),
              { type: 'Groups', id: 'LIST' },
            ]
          : [{ type: 'Groups', id: 'LIST' }],
    }),
    getGroup: builder.query<Group, string | undefined>({
      query: (id) => `/groups/${id}`,
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation({
      query: (group) => ({
        url: '/groups',
        method: 'POST',
        body: {
          ...group,
        },
      }),
      invalidatesTags: [{ type: 'Groups', id: 'LIST' }, 'Users'],
    }),
    updateGroup: builder.mutation({
      query: (group) => ({
        url: `/groups/${group.id}`,
        method: 'PUT',
        body: {
          ...group.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Groups', id: arg.id },
        'Users',
      ],
    }),
  }),
})

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useAddGroupMutation,
  useUpdateGroupMutation,
} = groupApi
