import { CustomSelect } from './custom-select'
import { Input } from 'components/ui/input'

export default function NotQualifiedInput({
  reason,
  setReason,
  customReason,
  setCustomReason,
  list,
}: any) {
  return (
    <div className="w-full flex items-center gap-3">
      <CustomSelect
        label="Why"
        list={list}
        value={reason}
        setValue={(value: any) => setReason(value)}
        required
      />
      {reason === 'other' && (
        <div className="w-full space-y-2 mt-8">
          <Input
            type={'text'}
            value={customReason}
            onChange={(e: any) => setCustomReason(e.target.value)}
            required
          />
        </div>
      )}
    </div>
  )
}
