import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { useState, useEffect, useRef } from 'react'
import { TbCloudUpload, TbX } from 'react-icons/tb'
import { useAddBulkLeadsMutation } from 'app/features/lead'
import { useSelector } from 'react-redux'
import { useToast } from 'components/ui/use-toast'
import { Link } from 'react-router-dom'
import { MatchHeaders } from './components/matchHeaders'
import useApp from 'hooks/useApp'
import {
  cleanPhoneNumber,
  validateEmail,
  validateName,
  validatePhoneNumber,
} from 'lib/utils'
import LeadInterest from 'pages/lead/lead-interest'
import { RxReset } from 'react-icons/rx'
import { IoMdClose } from 'react-icons/io'

export default function BulkUpload({ open, setOpen }: any) {
  const meta = useSelector((state: any) => state.app.meta)
  const { sources, branches, departments, specialists } = useApp()
  const { toast } = useToast()
  const { user, token } = useSelector((state: any) => state.user)
  const [jsonData, setJsonData] = useState<any>(null)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<string>('')
  const [notConfigured, setNotConfigured] = useState(false)
  const [matcher, setMatcher] = useState<Map<string, string>>(new Map())
  const [reload, setReload] = useState(false)
  const [csvHeaders, setCsvHeaders] = useState<string[]>([])
  const tableColumns = ['first_name', 'last_name', 'email', 'phone', 'source']
  const fileRef = useRef<any>()

  const handleMatch = (firstItem: string, secondItem: string) => {
    const newmatcher = new Map(matcher)
    newmatcher.set(firstItem, secondItem)
    setMatcher(newmatcher)
  }

  const [addBulkLeads, { isLoading }] = useAddBulkLeadsMutation()

  const convertCSVToJson = (csvData: any) => {
    try {
      const lines = csvData.split('\n')
      const headers = lines[0].split(',')
      if (headers.length < 5)
        throw new Error(
          'Please review the csv file, invalid columns, columns must be at least 5 ( first_name, last_name, source, email, phone )'
        )

      for (let i = 0; i < headers.length; i++) {
        if (!headers[i].trim()) {
          throw new Error('Please review the csv file, some headers are empty')
        }
      }

      setCsvHeaders(headers)
      const result = []

      for (let i = 1; i < lines.length; i++) {
        if (!lines[i]) continue
        const obj: any = {}
        const currentLine = lines[i].split(',')

        for (let j = 0; j < headers.length; j++) {
          if (!currentLine[j]?.trim())
            throw new Error(
              `invalid CSV file, some ${headers[j].trim()} entries are empty!`
            )
          if (
            headers[j].trim().toLowerCase() == 'email' &&
            !validateEmail(currentLine[j].trim())
          ) {
            throw new Error(`invalid CSV file, some email entries are invalid!`)
          }
          obj[headers[j].trim()] = currentLine[j].trim()
        }

        result.push(obj)
      }

      return result
    } catch (error: any) {
      setError(error.message || 'Invalid CSV file')
    }
  }

  const handleCSVInputChange = (event: any) => {
    event.preventDefault()
    setMatcher(new Map())
    setReload(true)
    setJsonData(null)
    setInterestData({
      branchIDs: [],
      departmentIDs: [],
      specialistIDs: [],
    })

    try {
      const file = event.target.files[0]
      const reader = new FileReader()

      reader.onload = (e: any) => {
        const csvData = e.target.result
        const jsonData: any = convertCSVToJson(csvData)
        setJsonData(jsonData)
      }

      reader.readAsText(file)
    } catch (error) {
      setError('Invalid CSV file')
    }
  }

  const replaceFileHandler = () => {
    fileRef.current.value = null
    setMatcher(new Map())
    setReload(true)
    setJsonData(null)
    setError('')
    setInterestData({
      branchIDs: [],
      departmentIDs: [],
      specialistIDs: [],
    })
  }

  useEffect(() => {
    try {
      if (jsonData) {
        setReload(false)
        if (csvHeaders.length >= 5) setError('')
        if (matcher.size == 5)
          setData(
            jsonData.map((jd: any) => ({
              firstName: jd[matcher.get('first_name')!.toString().trim()],
              lastName: jd[matcher.get('last_name')!.toString().trim()],
              email: jd[matcher.get('email')!.toString().trim()],
              phone: jd[matcher.get('phone')!.toString().trim()],
              sourceId: sources.find(
                (source: any) =>
                  source.name.toLowerCase() ===
                  jd[matcher.get('source')!.toString().trim()].toLowerCase()
              )?.id,
              userId: user.id,
            }))
          )
      }
    } catch (error) {}
  }, [matcher, jsonData])

  const [interestData, setInterestData] = useState<{
    branchIDs: string[]
    departmentIDs: string[]
    specialistIDs: string[]
  }>({
    branchIDs: [],
    departmentIDs: [],
    specialistIDs: [],
  })

  function addBranch(id: any) {
    if (interestData.branchIDs.includes(id)) {
      const newBranches = interestData.branchIDs.filter(
        (branch: any) => branch !== id
      )
      setInterestData({ ...interestData, branchIDs: newBranches })
    } else {
      setInterestData({
        ...interestData,
        branchIDs: [...interestData.branchIDs, id],
      })
    }
  }

  function addDepartment(id: any) {
    if (interestData.departmentIDs.includes(id)) {
      const newDepartments = interestData.departmentIDs.filter(
        (department: any) => department !== id
      )
      setInterestData({ ...interestData, departmentIDs: newDepartments })
    } else {
      setInterestData({
        ...interestData,
        departmentIDs: [...interestData.departmentIDs, id],
      })
    }
  }

  function addSpecialist(id: any) {
    if (interestData.specialistIDs.includes(id)) {
      const newSpecialists = interestData.specialistIDs.filter(
        (specialist: any) => specialist !== id
      )
      setInterestData({ ...interestData, specialistIDs: newSpecialists })
    } else {
      setInterestData({
        ...interestData,
        specialistIDs: [...interestData.specialistIDs, id],
      })
    }
  }

  const upload = async () => {
    setError('')
    const validSources = data.filter(
      (item: any) =>
        !item.sourceId || item.sourceId === undefined || item.sourceId === null
    )
    if (validSources.length > 0) {
      return setError('Please review the csv file, invalid source')
    }

    for (let i = 0; i < data.length; i++) {
      if (!validateEmail(data[i].email))
        return setError(
          'Please review the csv file, some emails are invalid!, OR please match a valid email column with the email field!'
        )

      if (data[i].firstName.length < 1)
        return setError('some first names are missed!')

      if (data[i].lastName.length < 1)
        return setError('some last names are missed!')

      if (!cleanPhoneNumber(data[i].phone))
        return setError('some phone numbers are invalid!')
    }

    if (data && data.length > 0 && validSources.length < 1) {
      const body = data.map(({ phone, ...item }: any) => {
        return {
          phone: cleanPhoneNumber(phone),
          branchIDs: interestData.branchIDs,
          departmentIDs: interestData.departmentIDs,
          specialistIDs: interestData.specialistIDs,
          ...item,
        }
      })
      const result: any = await addBulkLeads(body)

      if (result?.error?.originalStatus === 200) {
        toast({
          title: 'Leads are being created',
          description: 'This may take a while',
        })
        setOpen(false)
      } else {
      }
      setError('Some leads already exist, please review the csv file')
    } else {
      setError('Please review the csv file, invalid columns')
    }
  }

  useEffect(() => {
    setJsonData(null)
    setMatcher(new Map())
    setCsvHeaders([])
    setInterestData({
      branchIDs: [],
      departmentIDs: [],
      specialistIDs: [],
    })
    setError('')
  }, [open])

  useEffect(() => {
    if (meta) {
      setNotConfigured(sources?.length < 1)
    }
  }, [meta])

  const initiateCsvDownload = () => {
    const hiddenElement = document.createElement('a')
    hiddenElement.href =
      'data:text/plain;charset=utf-8,' +
      encodeURIComponent(
        `first_name,last_name,email,phone,source
John,Doe,john.doe@doctrona.con, +971123456789,Google Ads
Jane,Doe,jane.doe@doctrona.con, +971123456789,WhatsApp`
      )
    hiddenElement.download = `leads-template.csv`
    hiddenElement.click()
  }
  const exportAsCsvTemplateHandler = async () => {
    try {
      // Call the function to initiate the download
      initiateCsvDownload()
    } catch (error: any) {
      // Handle error
      toast({
        title: 'Error occured',
      })
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent
        className={`sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 ${jsonData && !reload && ' flex-1 h-full '} overflow-y-scroll`}
      >
        <div className="flex w-full justify-end">
          <Button variant="ghost" size="icon" onClick={() => setOpen(false)}>
            <TbX size={18} />
          </Button>
        </div>
        <div>
          {!isLoading && notConfigured && (
            <div className="p-3">
              <div className="grid h-96 w-full place-content-center">
                <div className="text-center">
                  <h1 className="text-lg font-semibold">
                    Please configure your sources, branches, and specialties
                    first
                  </h1>
                  <div className="mt-10">
                    <Link
                      className="bg-indigo-600 text-white py-2 px-5 rounded-md"
                      to="/settings"
                    >
                      Go to settings
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!isLoading && !notConfigured && (
            <>
              <h1 className="font-semibold text-lg">Bulk Upload</h1>
              <p className="text-sm">
                Upload multiple leads, make sure to{' '}
                <i>
                  match the columns of the csv file to be similar to the
                  structure shown in the example below{' '}
                </i>
              </p>
              <p className="mt-5 text-sm max-md:hidden">Example CSV file</p>
              <div className="mt-2 text-xs p-3 border bg-gray-100 rounded-md max-md:hidden">
                <pre>
                  first_name,last_name,email,phone,source
                  <br />
                  John,Doe,john.doe@doctrona.con, +971123456789,Google Ads
                  <br />
                  Jane,Doe,jane.doe@doctrona.con, +971123456789,WhatsApp
                </pre>
              </div>

              {jsonData && !reload && (
                <>
                  <p className="my-3 text-md ">Headers Matching</p>
                  <MatchHeaders
                    onMatch={handleMatch}
                    firstHeadersData={tableColumns}
                    secondHeadersData={csvHeaders}
                    matcher={matcher}
                  />

                  <div className="flex justify-end">
                    <LeadInterest
                      data={interestData}
                      setData={setInterestData}
                      addBranch={addBranch}
                      addDepartment={addDepartment}
                      addSpecialist={addSpecialist}
                      isDetailsPage={false}
                      isCreatePage={true}
                      branches={branches}
                      departments={departments}
                      specialists={specialists}
                      isInModal={true}
                    />
                  </div>
                </>
              )}
              <div className="mt-5 items-center flex relative">
                {/* <Label>CSV File</Label> */}
                <Input
                  id="leads"
                  type="file"
                  ref={fileRef}
                  accept=".csv"
                  className="cursor-pointer"
                  onChange={handleCSVInputChange}
                />
                <IoMdClose
                  size={20}
                  className="text-gray-600 hover:cursor-pointer hover:text-gray-900 absolute right-2"
                  onClick={replaceFileHandler}
                />
              </div>
              <div className="flex h-5 w-full items-center text-xs font-bold text-rose-600 mt-">
                {error}
              </div>
              <div className="mt-5 flex gap-4 justify-end">
                <Button onClick={exportAsCsvTemplateHandler} size="sm">
                  Template
                </Button>
                <Button
                  onClick={upload}
                  disabled={
                    jsonData === null || isLoading || matcher.size !== 5
                  }
                  size="sm"
                >
                  Upload
                </Button>
              </div>
            </>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
