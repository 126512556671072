import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from 'app/store'

export default function RequireAuth() {
  const { user } = useAppSelector((state: any) => state.user)

  const content = user ? <Outlet /> : <Navigate to="/login" />

  return content
}
