import { Cross1Icon } from '@radix-ui/react-icons'
import { useResetUserMutation } from 'app/features/users'
import PasswordInput from 'components/shared/password-input'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { useState } from 'react'

const regex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,}$/

export default function ResetUser({ user, open, setOpen }: any) {
  const [resetUser, { isLoading }] = useResetUserMutation()
  const [error, setError] = useState<string | undefined>('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  async function handleReset(e: any) {
    e.preventDefault()
    setError('')

    if (regex.test(newPassword) === false)
      return setError(
        'Password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters'
      )

    if (newPassword !== confirmNewPassword)
      return setError("Confirm password doesn't match New password ")

    try {
      await resetUser({ id: user.id, newPassword })
    } catch (error) {
      return setError('Error resting password, try again later')
    }
    setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[300px] md:max-w-[400px]">
        <div className="h-full w-full">
          <div className="flex w-full items-center justify-between">
            <h1 className="font-bold capitalize">Reset Password</h1>
            <Button
              variant="outline"
              size="icon"
              onClick={() => setOpen(false)}
            >
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </div>
          <form className="mt-5 space-y-3" onSubmit={handleReset}>
            {error !== '' && (
              <div className="my-1 text-center text-sm font-medium text-rose-600">
                {error}
              </div>
            )}
            <PasswordInput
              label="Password"
              password={newPassword}
              setPassword={setNewPassword}
              disabled={isLoading}
            />
            <PasswordInput
              label="Confirm Password"
              password={confirmNewPassword}
              setPassword={setConfirmNewPassword}
              disabled={isLoading}
            />
            <div className="pt-3">
              <Button disabled={isLoading} className="w-full text-white">
                Reset
              </Button>
            </div>
          </form>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
