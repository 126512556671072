import { shouldClaimInsurance } from './claim-insurance'

export function shouldFollowUp(
  lead: any,
  lastFollowUp: any,
  numberOfFollowUps: number,
  timeGap: number,
  numberOfInsuranceClaims: number
) {
  if (lead?.followUps?.length >= numberOfFollowUps) return false
  if (shouldClaimInsurance(lead, numberOfInsuranceClaims)) return false
  if (['BOOKED', 'NOT_QUALIFIED'].includes(lead?.status)) return false
  if (lead?.status === 'NEW') return true

  if (lastFollowUp) {
    const lastFollowUpDate = new Date(lastFollowUp?.date)
    const { nextFollowUpTimeGap } = lastFollowUp
    const today = new Date()
    if (nextFollowUpTimeGap) {
      if (nextFollowUpTimeGap > 0) {
        const timeDiff = Math.abs(today.getTime() - lastFollowUpDate.getTime())
        // convert timeDiff to seconds
        const timeDiffInSeconds = timeDiff / 1000
        return timeDiffInSeconds > nextFollowUpTimeGap
      }
    } else {
      const timeDiff = Math.abs(today.getTime() - lastFollowUpDate.getTime())
      // convert timeDiff to seconds
      const timeDiffInSeconds = timeDiff / 1000
      return timeDiffInSeconds > timeGap
    }
  }
  return true
}
