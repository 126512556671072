import { useSelector } from 'react-redux'
import { Link, Navigate } from 'react-router-dom'
import Loader from './loader'
import { useGetMyCurrentSubscriptionQuery } from 'app/features/payment'
import { cn } from 'lib/utils'

export const SubscriptionWrapperOld = ({ children, isActive = true }: any) => {
  const { user } = useSelector((state: any) => state.user)
  const {
    data: myCurrentSubscription,
    error: isMyCurrentSubscriptionError,
    isLoading: isMyCurrentSubscriptionLoading,
  } = useGetMyCurrentSubscriptionQuery(null, {
    skip: !user?.stripeCustomerId,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  })

  const isSubscribed =
    (isActive
      ? myCurrentSubscription?.stripeSubscriptionStatus == 'active' ||
        myCurrentSubscription?.stripeSubscriptionStatus == 'trialing'
      : myCurrentSubscription) && !isMyCurrentSubscriptionError

  if (isMyCurrentSubscriptionLoading)
    return (
      <div className="w-full h-full flex flex-grow justify-center items-center">
        <Loader />
      </div>
    )

  return (
    <div className="w-full h-full">
      {!isSubscribed && user?.role == 'ADMIN' && (
        /* Render the subscription overlay Or Navigate if not subscribed */
        // <SubscriptionOverlay user={user} />
        <Navigate to="/settings" state={{ screen: 'subscriptions' }} />
      )}
      <div
        className={cn(
          !isSubscribed && user?.role == 'ADMIN' ? 'blur-sm' : '',
          'w-full h-full'
        )}
      >
        {isSubscribed || user?.role != 'ADMIN' ? <>{children}</> : <></>}
      </div>
    </div>
  )
}

export const SubscriptionWrapper = ({ children, isActive = true }: any) => {
  return (
    <div className="w-full h-full">
      {false && (
        /* Render the subscription overlay Or Navigate if not subscribed */
        // <SubscriptionOverlay user={user} />
        <Navigate to="/settings" state={{ screen: 'subscriptions' }} />
      )}
      <div className={cn(false ? 'blur-sm' : '', 'w-full h-full')}>
        {true ? <>{children}</> : <></>}
      </div>
    </div>
  )
}

// Subscription overlay component (same as previous example)
const SubscriptionOverlay = ({ user }: any) => {
  return (
    <div className="w-screen h-screen   fixed inset-0  z-20">
      {/* Blurred screen effect */}
      <div className="absolute inset-0 z-30 flex justify-center items-center ">
        {/* Subscription prompt */}
        <div className="p-4 bg-white rounded shadow">
          <h2 className="text-xl font-semibold">
            Subscribe to Access Features
          </h2>
          <p className="mt-2">
            Please Subscribe to have access to all system features.
          </p>
          <div className="flex">
            <Link
              to={{ pathname: '/settings' }}
              state={{ screen: 'subscriptions' }}
              className=" block mt-4 mb-2  w-32 px-4 py-2 text-center bg-indigo-700 hover:bg-indigo-600 text-white rounded"
            >
              Subscribe
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
