import { api } from '../api'

type Source = {
  id: string
  name: string
}

type SourcesResponse = Source[]

export const sourceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSources: builder.query<SourcesResponse, void>({
      query: () => '/sources',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Sources' as const,
                id,
              })),
              { type: 'Sources', id: 'LIST' },
            ]
          : [{ type: 'Sources', id: 'LIST' }],
    }),
    getSource: builder.query<Source, string | undefined>({
      query: (id) => `/sources/${id}`,
      providesTags: ['Sources'],
    }),
    addSource: builder.mutation({
      query: (source) => ({
        url: '/sources',
        method: 'POST',
        body: {
          ...source,
        },
      }),
      invalidatesTags: [{ type: 'Sources', id: 'LIST' }, 'Leads'],
    }),
    updateSource: builder.mutation({
      query: (source) => ({
        url: `/sources/${source.id}`,
        method: 'PUT',
        body: {
          ...source.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Sources', id: arg.id },
        'Leads',
      ],
    }),
  }),
})

export const {
  useGetSourcesQuery,
  useGetSourceQuery,
  useAddSourceMutation,
  useUpdateSourceMutation,
} = sourceApi
