import { Cross1Icon } from '@radix-ui/react-icons'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { shouldBook } from 'lib/book'
import { shouldClaimInsurance } from 'lib/claim-insurance'
import { shouldFollowUp } from 'lib/follow-up'

export default function LeadPopUp({
  open,
  setOpen,
  leads,
  canFollowUp,
  canBook,
  canClaimInsurance,
  setOpenClaimInsurance,
  setOpenFollowUp,
  setOpenBook,
  setSelectedLead,
  hasActions = true,
}: any) {
  const { settings } = useApp()
  const numberOfFollowUps = settings[0].numberOfFollowUps
  const numberOfInsuranceClaims = settings[0].numberOfInsuranceClaims
  const timeGap = settings[0].followUpTimeGap
  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[1100px] bg-white dark:bg-gray-900 overflow-y-auto max-h-[500px]">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col"></div>
            <Button
              variant="outline"
              size="icon"
              onClick={() => setOpen(false)}
            >
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </div>
          <div className="overflow-hidden overflow-y-scroll">
            <div className="w-full overflow-hidden overflow-x-scroll">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="text-left border-b w-full ">
                    <th className="xl:w-56">Name</th>
                    <th className="xl:w-60 ">Email</th>

                    <th className="xl:w-40">Phone</th>
                    <th className="xl:w-40">Source</th>

                    {hasActions && <th className="">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {leads?.map((lead: any) => (
                    <tr
                      key={lead.id}
                      className="w-full border-b border-gray-100 last:border-b-0 py-1"
                    >
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {lead?.firstName} {lead?.lastName}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {lead.email}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {lead.phone}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {lead.source.name}
                      </td>
                      {hasActions && (
                        <>
                          {canFollowUp && (
                            <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                  setSelectedLead(lead)
                                  setOpenFollowUp(true)
                                }}
                                disabled={
                                  lead?.followUps?.length >=
                                    numberOfFollowUps ||
                                  !shouldFollowUp(
                                    lead,
                                    lead?.followUps[lead.followUps.length - 1],
                                    numberOfFollowUps,
                                    timeGap,
                                    numberOfInsuranceClaims
                                  )
                                }
                              >
                                Follow Up
                              </Button>
                            </td>
                          )}
                          {canBook && (
                            <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                  setSelectedLead(lead)
                                  setOpenBook(true)
                                }}
                                disabled={
                                  !shouldBook(lead, numberOfInsuranceClaims)
                                }
                              >
                                Book
                              </Button>
                            </td>
                          )}
                          {canClaimInsurance && (
                            <td className="overflow-x-hidden whitespace-nowrap text-ellipsis">
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={() => {
                                  setSelectedLead(lead)
                                  setOpenClaimInsurance(true)
                                }}
                                disabled={
                                  !shouldClaimInsurance(
                                    lead,

                                    numberOfInsuranceClaims
                                  )
                                }
                              >
                                Insurance Claim
                              </Button>
                            </td>
                          )}
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
