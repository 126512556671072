import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from 'react-phone-number-input'

// Load the necessary plugins
dayjs.extend(require('dayjs/plugin/localeData'))
dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(advancedFormat)

// Set the locale to use (e.g., 'en' for English)
dayjs.locale('en')

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isExist(value: any) {
  if (!value) return false
  if (typeof value === 'object' && value.length > 0) {
    return (
      value.filter(
        (item: any) => item === undefined || item === null || item === ''
      ).length === 0
    )
  }
  return value !== undefined || value !== null || value !== ''
}

export function convertTimeGap(value: any, type: any): number {
  if (type === 'MINUTE') return value * 60
  if (type === 'HOUR') return value * 60 * 60
  if (type === 'DAY') return value * 24 * 60 * 60
  if (type === 'WEEK') return value * 24 * 7 * 60 * 60
  else return 0
}

export function getStartDateAndEndDate(
  selectedDate: string
): { startDate: Dayjs; endDate: Dayjs } | null {
  if (selectedDate === 'Last 30 days') {
    const endDate = dayjs()
    const startDate = endDate.subtract(29, 'day')
    return { startDate, endDate }
  } else if (selectedDate === 'Last month') {
    const startDate = dayjs().subtract(1, 'month').startOf('month')
    const endDate = dayjs().subtract(1, 'month').endOf('month')
    return { startDate, endDate }
  } else if (selectedDate === 'This week') {
    const startDate = dayjs().startOf('week')
    const endDate = dayjs().endOf('week')
    return { startDate, endDate }
  } else if (selectedDate === 'Last week') {
    const startDate = dayjs().subtract(1, 'week').startOf('week')
    const endDate = dayjs().subtract(1, 'week').endOf('week')
    return { startDate, endDate }
  } else if (selectedDate === 'Yesterday') {
    const startDate = dayjs().subtract(1, 'day').startOf('day')
    const endDate = dayjs().subtract(1, 'day').endOf('day')
    return { startDate, endDate }
  } else if (selectedDate === 'Today') {
    const startDate = dayjs().startOf('day')
    const endDate = dayjs().endOf('day')
    return { startDate, endDate }
  } else return null
}

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/
  if (!phoneNumberRegex.test(phoneNumber)) {
    return false
  }
  return true
}

export const cleanPhoneNumber = (phoneNumber: string) => {
  // Remove any non-digit characters
  let cleaned = ('' + phoneNumber).replace(/\D/g, '')
  if (cleaned.length == 0) return null

  if (phoneNumber?.length && phoneNumber.charAt(0) == '+') {
    cleaned = '+' + cleaned
  }

  if (isPossiblePhoneNumber(cleaned, 'AE') && isValidPhoneNumber(cleaned, 'AE'))
    return cleaned

  cleaned = ('' + phoneNumber).replace(/\D/g, '')

  if (cleaned.length <= 10) {
    if (cleaned.charAt(0) == '0') cleaned = cleaned.substring(1)
    cleaned = '971' + cleaned
  }
  return '+' + cleaned
}

export const validateName = (name: string) => {
  // Check if the name contains only letters and spaces
  const nameRegex = /^[a-zA-Z\s]+$/
  if (!nameRegex.test(name)) {
    return false
  }

  // Check if the name has at least one letter
  if (!/[a-zA-Z]/.test(name)) {
    return false
  }

  return true
}

export const formatPrice = (price: any) => {
  const formatter = new Intl.NumberFormat('en-AE', {
    style: 'currency',
    currency: 'aed',
    currencySign: 'accounting',
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formatter.format(price)
}

export const formatDate = (date: any) => {
  const formatter = new Intl.DateTimeFormat('en-AE', {
    timeStyle: 'long',
  })
  // @ts-ignore
  const dubaiDate = dayjs(date).tz('Asia/Dubai')

  //  return `${new Date(date).toUTCString()} (${dubaiDate.format('dddd, MMMM D, YYYY h:mm A ')} Asia/Dubai)`
  return dubaiDate.format('MMMM D, YYYY')
}

export const formatDateAndTime = (date: any) => {
  // @ts-ignore
  const dubaiDate = dayjs(date).tz('Asia/Dubai')

  return `${dubaiDate.format('dddd, MMMM D, YYYY h:mm A ')}`
}

export const isDatePastThan = (date: any, minutes: number = 30) => {
  const currentTime = new Date()
  const pastMinutes = new Date(currentTime.getTime() - minutes * 60 * 1000)
  try {
    const dateInMilliseconds = new Date(date)

    return dateInMilliseconds < pastMinutes
  } catch (error) {
    console.error('Error checking date:', error)
    return false
  }
}

export const prepareMediaFormData = (data: Record<string, any>) => {
  const formData = new FormData()
  for (const key in data) {
    formData.append(key, data[key])
  }
  return formData
}

export const accessibleTab = (tab: string, user: any) => {
  return (
    user.role === 'ADMIN' ||
    user.role === 'SUPERADMIN' ||
    user.group?.tabs?.includes(tab)
  )
}
