import {
  useAddNotQualifiedReasonMutation,
  useGetNotQualifiedReasonsQuery,
  useUpdateNotQualifiedReasonMutation,
} from 'app/features/settings'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useEffect, useState } from 'react'
import { TbEdit } from 'react-icons/tb'

export default function NotQualifiedReasons() {
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [selectedNotQualifiedReason, setSelectedNotQualifiedReason] =
    useState('')
  const [mode, setMode] = useState('add')

  const {
    data: NotQualifiedReasons,
    isError,
    isLoading: dataLoading,
  } = useGetNotQualifiedReasonsQuery()
  const [
    addNotQualifiedReason,
    { isLoading: createLoading, isError: createError },
  ] = useAddNotQualifiedReasonMutation()
  const [
    updateNotQualifiedReason,
    { isLoading: updateLoading, isError: updateError },
  ] = useUpdateNotQualifiedReasonMutation()

  const isLoading = dataLoading || createLoading || updateLoading

  useEffect(() => {
    if (createError || updateError)
      setError('Not Qualified Reason is already exist')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setName('')
      setSelectedNotQualifiedReason('')
      setError('')
      setMode('add')
    }
  }, [mode])

  const handleNotQualifiedReason = async (e: any) => {
    e.preventDefault()
    setError('')
    if (name === '') return setError('Name is required')
    if (mode === 'add') await addNotQualifiedReason({ name })
    else
      await updateNotQualifiedReason({
        id: selectedNotQualifiedReason,
        data: { name },
      })
    setName('')
    setMode('add')
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      <h1 className="text-lg font-medium">Not Qualified Reasons</h1>
      <p className="text-xs text-muted-foreground">
        Create Not Qualified Reasons
      </p>
      {/* Form */}
      <form
        className="flex items-center gap-3 mt-5 max-md:flex-col"
        onSubmit={handleNotQualifiedReason}
      >
        <Input
          value={name}
          onChange={(e: any) => setName(e.target.value)}
          type="text"
          placeholder="Not Qualified Reason"
          disabled={isLoading || createLoading}
        />
        <Button type="submit" disabled={isLoading || createLoading} size="sm">
          {mode === 'add' ? 'Add Reason' : 'Update Reason'}
        </Button>
        {mode === 'update' && (
          <Button size="sm" variant="secondary" onClick={() => setMode('add')}>
            Cancel update
          </Button>
        )}
      </form>
      {error !== '' && (
        <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
      )}
      {/* List */}
      <div className="mt-5 flex flex-col gap-3">
        {NotQualifiedReasons?.map((reason: any) => (
          <div
            key={reason.id}
            className="flex items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800"
          >
            <div>{reason.name}</div>
            <div className="flex-1" />
            <div className="flex items-center gap-3">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setMode('update')
                  setName(reason.name)
                  setSelectedNotQualifiedReason(reason.id)
                }}
              >
                <TbEdit size={18} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
