import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'

export const ApproveLoginReuquest = ({
  open,
  setOpen,
  handleApprove,
  logoutFromOtherDevices,
  isLoading,
}: any) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <AlertDialogHeader>
          <AlertDialogTitle>Approve Auth Device</AlertDialogTitle>
          <AlertDialogDescription>
            By Confirming, the agent will be able to login from a new device
            {logoutFromOtherDevices && (
              <i className="text-red-500">
                , and will be logged out from all other devices.
              </i>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button variant="outline" size="sm" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant={logoutFromOtherDevices ? 'destructive' : 'default'}
            disabled={isLoading}
            size="sm"
            onClick={handleApprove}
          >
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
