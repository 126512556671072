import { useState, FormEvent, useEffect } from 'react'
import DateAndTime from './date-and-time'
import { Button } from 'components/ui/button'
import useApp from 'hooks/useApp'
import { Label } from '@radix-ui/react-label'
import MySelect from './select/MySelect'
import dayjs from 'dayjs'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { getStartDateAndEndDate } from 'lib/utils'
import { TbCloudDownload, TbDotsVertical, TbX } from 'react-icons/tb'
import { Input } from 'components/ui/input'
import { TiExportOutline } from 'react-icons/ti'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu'

export default function FiltersForm({
  withKeyword,
  downloadReport,
  exportRevenueReport,
}: any) {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [startHour, setStartHour] = useState<string>('1')
  const [startMinute, setStartMinute] = useState<string>('0')
  const [startAmpm, setStartAmpm] = useState<string>('AM')

  const [endDate, setEndDate] = useState<Date | undefined>(undefined)
  const [endHour, setEndHour] = useState<string>('1')
  const [endMinute, setEndMinute] = useState<string>('0')
  const [endAmpm, setEndAmpm] = useState<string>('AM')

  const [selectedDate, setSelectedDate] = useState<string>('All')

  const [selectedBranches, setSelectedBranches] = useState<any>([])
  const [selectedDepartments, setSelectedDepartments] = useState<any>([])
  const [selectedSpecialties, setSelectedSpecialties] = useState<any>([])

  const { setFilters, branches, departments, specialists } = useApp()

  const [keyword, setKeyword] = useState('')

  // TODO: to be deleted
  // const [departmentsData, setDepartmentsData] = useState<any>(departments)
  // const [specialtiesData, setSpecialtiesData] = useState<any>(specialists)

  useEffect(() => {
    // TODO: to be deleted

    // setDepartmentsData([])
    // setSpecialtiesData([])

    // selectedBranches.forEach((branch: { departments: any[] }) => {
    //   setDepartmentsData((prevDepartmentsData: any) => [
    //     ...prevDepartmentsData,
    //     ...branch.departments,
    //   ])

    //   branch.departments?.forEach((department: { specialists: any }) => {
    //     setSpecialtiesData((prevSpecialistsData: any) => [
    //       ...prevSpecialistsData,
    //       ...department.specialists,
    //     ])
    //   })
    // })
    // if (selectedBranches.length === 0) {
    //   setDepartmentsData(departments)
    //   setSpecialtiesData(specialists)
    //   setSelectedDepartments([])
    //   setSelectedSpecialties([])
    // }

    const filteredDepartments = selectedDepartments.filter((department: any) =>
      selectedBranches.some((branch: any) =>
        branch.departments.some(
          (branchDepartment: any) => department.id === branchDepartment.id
        )
      )
    )

    const filteredSpecialists = selectedSpecialties.filter(
      (specialist: { id: any }) =>
        selectedBranches.some((branch: { departments: any[] }) =>
          branch.departments.some(
            (departmentSpecialist: { specialists: any[] }) =>
              departmentSpecialist.specialists.some(
                (item: any) => specialist.id === item.id
              )
          )
        )
    )

    setSelectedDepartments(filteredDepartments)
    setSelectedSpecialties(filteredSpecialists)
  }, [selectedBranches])

  useEffect(() => {
    // TODO: to be deleted
    // setSpecialtiesData([])
    // selectedDepartments.forEach((department: { specialists: any[] }) => {
    //   setSpecialtiesData((prevSpecialistsData: any) => [
    //     ...prevSpecialistsData,
    //     ...department.specialists,
    //   ])
    // })
    // if (selectedDepartments.length === 0) {
    //   setSpecialtiesData(specialists)
    //   setSelectedSpecialties([])
    // }

    const filteredSpecialists = selectedSpecialties.filter((specialist: any) =>
      selectedDepartments.some((department: any) =>
        department.specialists.some(
          (departmentSpecialist: any) =>
            specialist.id === departmentSpecialist.id
        )
      )
    )
    setSelectedSpecialties(filteredSpecialists)
  }, [selectedDepartments])

  useEffect(() => {
    const { startDate: startDateValue, endDate: endDateValue } =
      getStartDateAndEndDate(selectedDate) || { startDate, endDate }
    setFilters({
      startDate: startDate
        ? dayjs(startDate)
            .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(startMinute))
            .toDate()
        : startDateValue,
      endDate: endDate
        ? dayjs(endDate)
            .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
            .minute(parseInt(endMinute))
            .toDate()
        : endDateValue,
    })
  }, [])

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    const { startDate: startDateValue, endDate: endDateValue } =
      getStartDateAndEndDate(selectedDate) || { startDate, endDate }
    setFilters({
      keyword: keyword,
      ...(selectedDate != 'All' && {
        startDate: startDate
          ? dayjs(startDate)
              .hour(parseInt(startHour) + (startAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(startMinute))
              .toDate()
          : startDateValue,
        endDate: endDate
          ? dayjs(endDate)
              .hour(parseInt(endHour) + (endAmpm === 'PM' ? 12 : 0))
              .minute(parseInt(endMinute))
              .toDate()
          : endDateValue,
      }),
      branchIDs: selectedBranches.map((branch: { id: any }) => branch.id),
      departmentIDs: selectedDepartments.map(
        (department: { id: any }) => department.id
      ),
      specialistIDs: selectedSpecialties.map(
        (specialist: { id: any }) => specialist.id
      ),
    })
  }

  const handleClearFilters = () => {
    setKeyword('')
    setSelectedBranches([])
    setSelectedDepartments([])
    setSelectedSpecialties([])
    setStartDate(undefined)
    setStartHour('1')
    setStartMinute('0')
    setStartAmpm('AM')
    setEndDate(undefined)
    setEndHour('1')
    setEndMinute('0')
    setEndAmpm('AM')
    setFilters(null)
    setSelectedDate('All')
  }

  return (
    <div className="w-full mb-3 pb-3 border-b border-dashed border-gray-300">
      <form
        onSubmit={handleSubmit}
        className="flex flex-wrap flex-row items-end gap-3"
      >
        {withKeyword && (
          <div className="space-y-2 flex flex-col">
            <Label className="text-xs font-semibold opacity-0">Keyword</Label>
            <Input
              type="text"
              placeholder="Search"
              value={keyword}
              className="bg-white"
              onChange={(e) => setKeyword(e.target.value)}
            />
          </div>
        )}

        <div className="space-y-2 flex flex-col">
          <MySelect
            selected={selectedBranches}
            setSelected={setSelectedBranches}
            data={branches}
            selectMeta="branch"
          />
        </div>
        <div className="space-y-2 flex flex-col">
          <MySelect
            selected={selectedDepartments}
            setSelected={setSelectedDepartments}
            // data={departmentsData}
            data={departments.filter((department: any) =>
              selectedBranches
                .map((branch: any) => branch.id)
                .includes(department.branchId)
            )}
            selectMeta="department"
          />
        </div>
        <div className="space-y-2 flex flex-col">
          <MySelect
            selected={selectedSpecialties}
            setSelected={setSelectedSpecialties}
            //data={specialtiesData}
            data={specialists.filter((specialist: any) => {
              return selectedDepartments.length
                ? selectedDepartments
                    .map((department: any) => department.id)
                    .includes(specialist.departmentId) && !specialist.disabled
                : selectedBranches
                    .map((branch: any) => branch.id)
                    .includes(specialist.branchId) && !specialist.disabled
            })}
            selectMeta="specialist"
          />
        </div>

        <div className="space-y-2 flex flex-col">
          <Select
            value={selectedDate}
            onValueChange={(value: any) => setSelectedDate(value)}
          >
            <SelectTrigger className="w-40 bg-white">
              <SelectValue>{selectedDate}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="Last 30 days">Last 30 days</SelectItem>
                <SelectItem value="Last month">Last month</SelectItem>
                <SelectItem value="This week">This week</SelectItem>
                <SelectItem value="Last week">Last week</SelectItem>
                <SelectItem value="Today">Today</SelectItem>
                <SelectItem value="Yesterday">Yesterday</SelectItem>
                <SelectItem value="Custom date">Custom Date</SelectItem>
                <SelectItem value="All">All</SelectItem>
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {selectedDate === 'Custom date' ? (
          <>
            <div className="space-y-2 flex flex-col">
              <Label className="text-xs text-gray-500">Start Date:</Label>
              <DateAndTime
                date={startDate}
                setDate={setStartDate}
                hour={startHour}
                setHour={setStartHour}
                minute={startMinute}
                setMinute={setStartMinute}
                ampm={startAmpm}
                setAmpm={setStartAmpm}
              />
            </div>
            <div className="space-y-2 flex flex-col">
              <Label className="text-xs text-gray-500">End Date:</Label>
              <DateAndTime
                date={endDate}
                setDate={setEndDate}
                hour={endHour}
                setHour={setEndHour}
                minute={endMinute}
                setMinute={setEndMinute}
                ampm={endAmpm}
                setAmpm={setEndAmpm}
                beforeSelectedDate={startDate}
              />
            </div>
          </>
        ) : null}

        <div className="space-x-2 h-full items-center pb-0.5">
          <Button size="sm">Apply Filters</Button>
        </div>

        <div className="space-x-2 h-full items-center pb-0.5">
          <Button
            size="sm"
            variant="outline"
            className="bg-white"
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        </div>
        {downloadReport && (
          <div className="space-x-2 h-full items-center pb-0.5">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                  <TbDotsVertical size={18} />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[160px]">
                <DropdownMenuItem
                  className="hover:cursor-pointer text-left"
                  onClick={downloadReport}
                >
                  <div className="flex gap-3 items-center justify-start  ">
                    <TiExportOutline size={15} className=" text-gray-500" />
                    Staff Report{' '}
                  </div>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="hover:cursor-pointer text-left"
                  onClick={exportRevenueReport}
                >
                  <div className="flex gap-3 items-center justify-start w-full">
                    <TbCloudDownload size={15} className=" text-gray-500" />
                    <span>Income Report</span>
                  </div>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </form>
    </div>
  )
}
