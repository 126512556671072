import { useGetMediaQuery } from 'app/features/whatsapp'

interface ChatListProps {
  type: string
  mediaId: string
  content?: string
  filename?: string
  senderName?: string
}

export function ChatMedia({
  type,
  mediaId,
  content,
  filename,
  senderName,
}: ChatListProps) {
  const { data } = useGetMediaQuery(mediaId)

  return (
    <div>
      <span className="font-bold text-sm">{senderName}</span>
      {type === 'IMAGE' && data && (
        <img className="w-80" src={data.media} alt="Image" />
      )}
      {type === 'STICKER' && data && (
        <img className="h-52" src={data.media} alt="Image" />
      )}
      {type === 'VIDEO' && data && (
        <video className="w-100" src={data.media} controls />
      )}
      {type === 'AUDIO' && data && <audio src={data.media} controls />}
      {type === 'DOCUMENT' && data && (
        <div className="bg-gray-200 p-4 rounded-lg">
          <a
            href={data.media}
            className="document-link text-blue-500"
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            {filename}
          </a>
        </div>
      )}
      {content && (
        <span className="bg-accent p-3 rounded-md max-w-xs">{content}</span>
      )}
    </div>
  )
}
