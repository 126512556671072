import { cn } from 'lib/utils'
export const SettingsSideMenu = ({ screen, setScreen }: any) => {
  return (
    <div className="flex flex-col gap-3 p-3 w-48 max-md:hidden">
      <div
        className={cn(
          screen === 'followUp' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('followUp')}
      >
        Follow Up
      </div>
      <div
        className={cn(
          screen === 'brand' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('brand')}
      >
        Brand
      </div>
      <div
        className={cn(
          screen === 'not-qualified-reasons' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('not-qualified-reasons')}
      >
        Not Qualified
      </div>

      <div
        className={cn(
          screen === 'sources' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('sources')}
      >
        Lead Sources
      </div>
      <div
        className={cn(
          screen === 'groups' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('groups')}
      >
        User Groups
      </div>
      <div
        className={cn(
          screen === 'branches' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('branches')}
      >
        Branches
      </div>
      <div
        className={cn(
          screen === 'departments' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('departments')}
      >
        Departments
      </div>
      <div
        className={cn(
          screen === 'specialists' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('specialists')}
      >
        Specialists
      </div>
      <div
        className={cn(
          screen === 'leadForm' ? 'bg-gray-200' : 'bg-transparent',
          'cursor-pointer py-2 px-3 rounded-md'
        )}
        onClick={() => setScreen('leadForm')}
      >
        Lead Form
      </div>

      {/* <div
          className={cn(
            screen === 'meta-login' ? 'bg-gray-200' : 'bg-transparent',
            'cursor-pointer py-2 px-3 rounded-md'
          )}
          onClick={() => setScreen('meta-login')}
        >
          Meta login
        </div> */}
    </div>
  )
}
