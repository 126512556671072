import { useState, useEffect } from 'react'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useLoginMutation, useLogoutMutation } from 'app/features/auth'
import { setCredentials, setDeviceId, setUser } from 'app/slices/user'
import PasswordInput from 'components/auth/password-input'
export default function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { user } = useSelector((state: any) => state.user)

  const [login, { isLoading: loginLoading }] = useLoginMutation()

  const [logout] = useLogoutMutation()

  useEffect(() => {
    if (user && user.verified && user.role === 'ADMIN') {
      navigate('/dashboard')
    } else if (user && user.verified && user.role === 'AGENT') {
      navigate('/agents-dashboard')
    } else if (!user) {
      logout(undefined)
    }
  }, [user])

  const handleLogin = async (e: any) => {
    e.preventDefault()
    setError('')
    try {
      const { user, token, deviceId } = await login({
        email: email?.trim()?.toLowerCase(),
        password,
      }).unwrap()
      dispatch(setDeviceId({ deviceId }))

      if (user.disabled)
        return setError(
          'Your account is disabled, please contact your administrator'
        )

      if (!user.verified) {
        dispatch(setUser(user))
        return navigate('/verify')
      }
      dispatch(setCredentials({ user, token }))
    } catch (error: any) {
      if (error.status === 409) {
        dispatch(setDeviceId({ deviceId: error.data?.deviceId }))
        return navigate('/temporarily-failure', {
          state: {
            message: error.data?.message,
            reason: error.data?.reason,
          },
        })
      }
      return setError('Wrong email or password')
    }
  }

  return (
    <div className="grid h-screen w-screen place-content-center">
      {/* Login */}
      <h1 className="text-lg font-semibold">Sign In</h1>
      <div className="rounded-md border p-3 bg-white dark:bg-gray-900 shadow-sm mt-3">
        <form className="w-96 space-y-3" onSubmit={handleLogin}>
          {error !== '' && (
            <div className="my-1 font-medium text-sm text-rose-600">
              {error}
            </div>
          )}
          <div className="space-y-2">
            <Label>Email</Label>
            <Input
              type="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              required
              disabled={loginLoading}
            />
          </div>
          <PasswordInput
            label="Password"
            password={password}
            setPassword={setPassword}
            disabled={loginLoading}
          />
          <div className="pt-3">
            <Button disabled={loginLoading} className="w-full">
              Login
            </Button>
          </div>
        </form>
        <div className="flex justify-end mt-5">
          {/* <Link
						to="/forgot-password"
						className="text-sm text-gray-600 hover:text-indigo-600"
					>
						Forgot password?
					</Link> */}
        </div>
      </div>
    </div>
  )
}
