import { Cross1Icon } from '@radix-ui/react-icons'
import { AlertDialog, AlertDialogContent } from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import dayjs from 'dayjs'

export default function ItemPopUp({ open, setOpen, items }: any) {
  return (
    <>
      <AlertDialog open={open} onOpenChange={setOpen}>
        <AlertDialogContent className="sm:max-w-[425px] md:max-w-[1100px] bg-white dark:bg-gray-900 overflow-y-auto max-h-[500px]">
          <div className="flex w-full items-center justify-between">
            <div className="flex flex-col"></div>
            <Button
              variant="outline"
              size="icon"
              onClick={() => setOpen(false)}
            >
              <Cross1Icon className="h-3 w-3" />
            </Button>
          </div>
          <div className="overflow-hidden overflow-y-scroll">
            <div className="w-full overflow-hidden overflow-x-scroll">
              <table className="w-full table-fixed">
                <thead>
                  <tr className="text-left border-b w-full ">
                    <th className="xl:w-52">Name</th>
                    <th className="xl:w-52 ">Date</th>

                    {items && items.length && items[0].index ? (
                      <>
                        <th className="xl:w-80">Comment</th>
                        {/* <th className="xl:w-28">Index</th> */}
                      </>
                    ) : (
                      <>
                        <th className="xl:w-40">Branch</th>
                        <th className="xl:w-40">Department</th>
                        <th className="xl:w-40">Specialist</th>{' '}
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {items?.map((item: any) => (
                    <tr
                      key={item.id}
                      className="w-full border-b border-gray-100 last:border-b-0 py-1"
                    >
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {item.lead?.firstName} {item.lead?.lastName}
                      </td>
                      <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                        {dayjs(item?.date).format('DD/MM/YYYY')}
                      </td>

                      {item.index ? (
                        <>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {item.comment}
                          </td>
                          {/* <td className="overflow-x-clip ">{item.index}</td> */}
                        </>
                      ) : (
                        <>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {item.branch?.name}
                          </td>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {item.department?.name}
                          </td>
                          <td className="overflow-x-hidden whitespace-nowrap text-ellipsis ">
                            {item.specialist?.name}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
