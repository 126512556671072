import {
  useGetUsersPerformanceQuery,
  UserPerformanceResponse,
} from 'app/features/users'
import {
  useGetLeadsByBookingQuery,
  useGetLeadsBySourceQuery,
  useGetAllLeadsQuery,
} from 'app/features/lead'
import useApp from 'hooks/useApp'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import Kpi from './components/kpi'
import Init from './components/init'
import {
  TbActivityHeartbeat,
  TbLayoutGrid,
  TbStethoscope,
  TbUsersGroup,
} from 'react-icons/tb'
import LeadsByBooking from './components/leads-by-booking'
import LeadsBySources from './components/leads-by-sources'
import LeadsByFollowUp from './components/leads-by-follow-up'
import UsersPerformance from './components/users-performance'
import UserDetails from './components/user-details'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import FiltersForm from 'components/shared/FiltersForm'
import LeadSection from './lead'
import UsersSection from './charts/users-section'
import { TiExportOutline } from 'react-icons/ti'
import generatePDF from 'react-to-pdf'
import axios from 'axios'
import { useToast } from 'components/ui/use-toast'
import { shouldClaimInsurance } from 'lib/claim-insurance'

export default function Dashboard() {
  const {
    isAppLoading,
    settings,
    leadsCount,
    filters,
    branchesCount,
    departmentsCount,
    specialistsCount,
    allBranchesCount,
    allDepartmentsCount,
    allSpecialistsCount,
  } = useApp()
  const navigate = useNavigate()
  const [selectedUser, setSelectedUser] =
    useState<UserPerformanceResponse | null>(null)
  const [openInit, setOpenInit] = useState(false)
  const [numberOfInsuranceClaims, setNumberOfInsuranceClaims] = useState(0)
  const { user, token } = useSelector((state: any) => state.user)
  const { toast } = useToast()
  const {
    data: users,
    isError: isUsersError,
    isLoading: isUsersLoading,
  } = useGetUsersPerformanceQuery(filters)
  const { data: leads, isLoading: leadsLoading } = useGetAllLeadsQuery(filters)
  const { data: leadsByBooking, isLoading: leadsByBookingLoading } =
    useGetLeadsByBookingQuery(filters)
  const { data: leadsBySource, isLoading: leadsBySourceLoading } =
    useGetLeadsBySourceQuery(filters)

  const [secPause, setSecPause] = useState(true)

  useEffect(() => {
    if (user && user.role === 'agent') {
      navigate('/agents-dashboard')
    }
  }, [user])

  useEffect(() => {
    if (settings && settings.length === 0) {
      setOpenInit(true)
    }
    if (settings?.length > 0)
      setNumberOfInsuranceClaims(settings[0]?.numberOfInsuranceClaims)
  }, [settings])

  useEffect(() => {
    setSelectedUser(null)
  }, [filters])

  useEffect(() => {
    if (users && users?.length) {
      const maxUser = users.reduce((max, obj) => {
        return obj.userScore > max.userScore ? obj : max
      }, users[0])
      if (maxUser) setSelectedUser(maxUser)
    }
  }, [users])

  function handleClick(id: string) {
    const temp = users?.find((user) => user.id === id)
    if (temp) setSelectedUser(temp)
  }

  const ref = useRef<any>()
  const exportReport = () => {
    generatePDF(ref, {
      filename: 'report.pdf',
    })
  }

  const initiateCsvDownload = (blobData: any) => {
    const url = URL.createObjectURL(blobData)
    const a = document.createElement('a')
    a.href = url
    a.download = `revenue-${new Date().toString()}.xlsx`
    a.click()
    URL.revokeObjectURL(url)
  }

  const exportRevenueReport = async () => {
    try {
      const baseUrl =
        process.env.NODE_ENV === 'development'
          ? process.env.REACT_APP_DEV_API_URL
          : process.env.REACT_APP_PRD_API_URL
      const response = await axios.get(
        `${baseUrl}/leads/revenue-report?filters=${JSON.stringify(filters)}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }
      )

      // Call the function to initiate the download
      initiateCsvDownload(response.data)
    } catch (error: any) {
      // Handle error
      toast({
        title: 'Error occured',
        description:
          error.response.status == 401
            ? 'UnAuthorized'
            : error.response.status == '409'
              ? 'sorry, cannot complete this operation in free trial period or if you do not subscribed yet, please subscribe'
              : 'please try again',
      })
    }
  }

  const loading =
    leadsByBookingLoading ||
    isUsersLoading ||
    isAppLoading ||
    leadsBySourceLoading ||
    leadsLoading

  useEffect(() => {
    const timer = setTimeout(() => {
      setSecPause(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [])

  if (loading || secPause) {
    return (
      <div className="w-full h-screen grid place-content-center">
        <Loader />
      </div>
    )
  }

  if (isUsersError) {
    return (
      <div className="w-full h-full grid place-content-center">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  }

  return (
    <>
      {!isAppLoading && settings && settings?.length < 1 && (
        <Init open={openInit} setOpen={setOpenInit} />
      )}
      {!isAppLoading && settings && settings.length > 0 && (
        <div
          ref={ref}
          className="flex flex-col min-h-screen pl-3 pt-3 pb-3 overflow-scroll bg-gray-100"
        >
          <div className="flex gap-3">
            <FiltersForm
              downloadReport={exportReport}
              exportRevenueReport={exportRevenueReport}
            />
          </div>
          <div className="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
            <Kpi
              title="Total Lead"
              icon={<TbUsersGroup size="15" className="text-indigo-600" />}
              value={leadsCount}
            />
            <Kpi
              title="Total Branches"
              icon={<TbLayoutGrid size="15" className="text-indigo-600" />}
              value={allBranchesCount}
            />
            <Kpi
              title="Total Departments"
              icon={
                <TbActivityHeartbeat size="15" className="text-indigo-600" />
              }
              value={allDepartmentsCount}
            />
            <Kpi
              title="Total Specialists"
              icon={<TbStethoscope size="15" className="text-indigo-600" />}
              value={allSpecialistsCount}
            />
          </div>
          <div className="flex flex-col gap-3">
            {leadsCount < 1 && (
              <div className="w-full grid place-content-center text-center pt-10">
                <p className="text-xs text-gray-500">
                  No leads for the specified period
                </p>
              </div>
            )}
            <div className="mt-3">
              {leadsCount > 0 && !secPause && (
                <LeadSection
                  bookingData={leadsByBooking}
                  sourcesData={leadsBySource}
                  followUps={leads?.filter(
                    (lead: any) =>
                      (lead.status === 'NEW' || lead.status === 'FOLLOW_UP') &&
                      !shouldClaimInsurance(lead, numberOfInsuranceClaims)
                  )}
                />
              )}
            </div>
            {/* {leadsCount > 0 && (
              <div className="grid grid-cols-3 gap-3 h-full mt-3">
                <div className="w-full h-full flex flex-col gap-3 ">
                  <LeadsByBooking leads={leadsByBooking} />
                  <LeadsBySources leads={leadsBySource} />
                </div>
                <div className="col-span-2">
                  <LeadsByFollowUp
                    leads={leads?.filter(
                      (lead: any) =>
                        lead.status === 'NEW' || lead.status === 'FOLLOW_UP'
                    )}
                  />
                </div>
              </div>
            )} */}
            {users && users.length > 0 && <UsersSection users={users} />}
          </div>
        </div>
      )}
    </>
  )
}
