import { api } from '../api'

type Department = {
  id: string
  name: string
  branchId: string
  branch?: any
}

type DepartmentsResponse = Department[]

export const departmentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDepartments: builder.query<DepartmentsResponse, void>({
      query: () => '/departments',
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Departments' as const,
                id,
              })),
              { type: 'Departments', id: 'LIST' },
            ]
          : [{ type: 'Departments', id: 'LIST' }],
    }),
    getDepartment: builder.query<Department, string | undefined>({
      query: (id) => `/departments/${id}`,
      providesTags: ['Departments'],
    }),
    addDepartment: builder.mutation({
      query: (department) => ({
        url: '/departments',
        method: 'POST',
        body: {
          ...department,
        },
      }),
      invalidatesTags: [
        { type: 'Departments', id: 'LIST' },
        'Branches',
        'Specialists',
      ],
    }),
    updateDepartment: builder.mutation({
      query: (department) => ({
        url: `/departments/${department.id}`,
        method: 'PUT',
        body: {
          ...department.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Departments', id: arg.id },
        'Branches',
        'Specialists',
      ],
    }),
    getDepartmentsCount: builder.query<any, DashboardFilters | null>({
      query: (filters: DashboardFilters) =>
        `/departments/count?filters=${JSON.stringify(filters)}`,
      providesTags: [{ type: 'Departments', id: 'LIST' }],
    }),
  }),
})

export const {
  useGetDepartmentsQuery,
  useGetDepartmentQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useGetDepartmentsCountQuery,
} = departmentApi
