import { useEffect, useState } from 'react'
import { ScrollArea } from 'components/ui/scroll-area'
import LeadsByBooking from './charts/leads-by-booking'
import LeadsBySource from './charts/leads-by-source'
import LeadsFollowUp from './charts/leads-follow-up'
import UsersSection from './charts/users-section'

export default function LeadSection({
  bookingData,
  sourcesData,
  followUps,
}: any) {
  const [loading, setLoading] = useState(true)
  const [bookingChartData, setBookingChartData] = useState<any>({})

  useEffect(() => {
    if (
      bookingData.bookedLeads > 0 ||
      bookingData.showLeads > 0 ||
      bookingData.noShowLeads > 0 ||
      bookingData.notQualifiedLeads > 0
    ) {
      setBookingChartData({
        booked: bookingData.bookedLeads,
        show: bookingData.showLeads,
        noShow: bookingData.noShowLeads,
        notQualified: bookingData.notQualifiedLeads,
      })
    } else {
      setBookingChartData(null)
    }
  }, [bookingData])

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 gap-2">
      <div className="col-span-2">
        <ScrollArea className="h-full w-full rounded-md border p-3 bg-white">
          <LeadsFollowUp leads={followUps} />
        </ScrollArea>
      </div>
      <div className="flex flex-col gap-2 h-full">
        <div className="border p-3 rounded-md bg-white">
          {bookingChartData && <LeadsByBooking data={bookingChartData} />}
        </div>
        <div className="border p-3 rounded-md bg-white">
          <LeadsBySource data={sourcesData} />
        </div>
      </div>
    </div>
  )
}
