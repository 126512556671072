import {
  useClaimInsuranceMutation,
  useClaimLeadMutation,
  useLazyGetLeadQuery,
} from 'app/features/lead'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Textarea } from 'components/ui/textarea'
import dayjs from 'dayjs'
import useApp from 'hooks/useApp'
import { isDatePastThan } from 'lib/utils'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { Switch } from 'components/ui/switch'

export default function ClaimInsurance({ open, setOpen, leadId }: any) {
  const { settings } = useApp()
  const numberOfInsuranceClaims = settings[0].numberOfInsuranceClaims
  const { user } = useSelector((state: any) => state.user)
  const [comment, setComment] = useState('')
  const [isApproved, setIsApproved] = useState(false)
  const [error, setError] = useState('')
  const [claimInsurance, { isLoading }] = useClaimInsuranceMutation()

  const [claimLead] = useClaimLeadMutation()

  const [getLead, { data: lead, isLoading: isLoadingLead }] =
    useLazyGetLeadQuery()

  useEffect(() => {
    const fetch = async () => {
      const res = await getLead(leadId)
      if (
        res.data &&
        (!res.data?.claimed || isDatePastThan(res.data?.updatedAt))
      ) {
        claimLead({ id: leadId, data: true })
      }
    }
    fetch()
  }, [])
  const onOpenChange = async () => {
    if (
      open &&
      (!lead?.claimed ||
        (lead?.claimed && lead?.claimedBy?.id == user?.id) ||
        isDatePastThan(lead?.updatedAt))
    ) {
      claimLead({ id: leadId, data: false })
    }
    setOpen((prev: any) => !prev)
  }

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      event.preventDefault() // Cancel the default behavior of refreshing
      if (!lead?.claimed || (lead?.claimed && lead?.claimedBy?.id == user?.id))
        claimLead({ id: leadId, data: false })
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    setComment('')
    setError('')
  }, [open])

  async function addInsuranceClaim() {
    setError('')
    if (comment === '') {
      setError('Please add a comment')
      return
    }

    try {
      await claimInsurance({
        leadId: lead.id,
        comment,
        status: isApproved ? 'Approved' : 'Rejected',
        userId: user.id,
      })
      await claimLead({ id: leadId, data: false })
      setOpen(false)
    } catch (error) {
      setError('Something went wrong')
    }
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        {(isLoading || isLoadingLead) && (
          <div className="h-40 grid place-content-center">
            <Loader />
          </div>
        )}
        {lead &&
        lead?.claimed &&
        lead?.claimedBy?.id != user?.id &&
        !isDatePastThan(lead?.updatedAt) ? (
          <>
            <AlertDialogHeader>
              <div className="h-40 grid place-content-center">
                Lead is claimed by another agent: {lead.claimedBy?.firstName}{' '}
                {lead.claimedBy?.lastName}
              </div>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>OK</AlertDialogCancel>
            </AlertDialogFooter>
          </>
        ) : (
          <>
            {lead && (
              <>
                <AlertDialogHeader>
                  <div className="flex items-center justify-between text-black">
                    <h1 className="text-lg font-semibold">
                      {lead.firstName} {lead.lastName}
                    </h1>
                    <div className="text-xs font-light py-1 px-2 bg-indigo-200 rounded-md text-indigo-600">
                      Claim insurance {lead?.insuranceClaims?.length + 1}
                    </div>
                  </div>
                  <p className="text-gray-500 text-xs">{lead.phone}</p>
                  <p className="text-gray-500 text-xs">{lead.email}</p>
                  {error !== '' && (
                    <p className="text-red-500 text-xs">{error}</p>
                  )}
                  <Textarea
                    className="mt-5"
                    id="nex-follow-up-checkbox"
                    value={comment}
                    onChange={(e: any) => setComment(e.target.value)}
                    placeholder="Add comment"
                  />
                  <div className="items-top flex space-x-2 pt-3">
                    <span className={` flex-1 flex  gap-1 items-center`}>
                      <span className="text-sm">Rejected</span>
                      <Switch
                        checked={isApproved}
                        onCheckedChange={() => setIsApproved((prev) => !prev)}
                      />
                      <span className="text-sm">Approved</span>
                    </span>
                  </div>
                </AlertDialogHeader>

                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button
                    disabled={
                      lead?.insuranceClaims.length >= numberOfInsuranceClaims
                    }
                    onClick={addInsuranceClaim}
                  >
                    Add Insurance Claim
                  </Button>
                </AlertDialogFooter>
                <div className="w-full border-t border-dashed" />
                {lead?.insuranceClaims?.length === 0 && (
                  <p className="text-sm text-gray-600 text-center mt-3">
                    No insurance claims history
                  </p>
                )}
                {lead?.insuranceClaims?.length > 0 && (
                  <h1 className="text-md font-semibold">
                    Insurance Claims history
                  </h1>
                )}
                <div className="overflow-hidden overflow-y-scroll pt-3 pl-3 pb-3 max-h-72 h-full flex flex-col gap-3 text-sm">
                  {lead?.insuranceClaims.map((insuranceClaim: any) => (
                    <div className="bg-gray-50 rounded-md p-3 space-y-3 border flex justify-between items-end">
                      <div>
                        <p className="text-indigo-700 text-xs">
                          {dayjs(insuranceClaim.createdAt).format('DD/MM/YYYY')}
                        </p>
                        {insuranceClaim.comment}
                        <p className="text-xs">
                          Status: {insuranceClaim?.status}{' '}
                        </p>
                      </div>
                      <div>
                        <p className="text-xs ">
                          Claimed by: {insuranceClaim?.user?.firstName}{' '}
                          {insuranceClaim?.user?.lastName}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </AlertDialogContent>
    </AlertDialog>
  )
}
