import Loader from 'components/shared/loader'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useUpdateUserMutation } from 'app/features/users'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { useToast } from 'components/ui/use-toast'
import { useEffect, useState } from 'react'
import { TbX } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import MultipleSelection from 'components/shared/multiple-selection'
import { cn } from 'lib/utils'
import useApp from 'hooks/useApp'
import { isEmail } from 'lib/validation'

export default function EditUser({ user, open, setOpen }: any) {
  const { groups, branches, departments, specialists, accessPeriod } = useApp()
  const [updateUser, { isLoading }] = useUpdateUserMutation()
  const [error, setError] = useState('')
  const [step, setStep] = useState(1)
  const [data, setData] = useState<any>({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    role: user.role,
    branchesIds: user.branches.map((branch: any) => branch.id),
    departmentsIds: user.departments.map((department: any) => department.id),
    specialistsIds: user.specialists.map((specialist: any) => specialist.id),
    groupId: user.groupId,
    accessPeriodId: user.accessPeriodId,
  })

  useEffect(() => {
    setError('')
    setStep(1)
    setData({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      role: user.role,
      branchesIds: user.branches.map((branch: any) => branch.id),
      departmentsIds: user.departments.map((department: any) => department.id),
      specialistsIds: user.specialists.map((specialist: any) => specialist.id),
      groupId: user.groupId,
      accessPeriodId: user.accessPeriodId,
    })
  }, [open])

  useEffect(() => {
    setError('')
  }, [step])

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  function addBranch(id: any) {
    if (data.branchesIds.includes(id)) {
      const newBranches = data.branchesIds.filter(
        (branch: any) => branch !== id
      )
      setData({ ...data, branchesIds: newBranches })
    } else {
      setData({ ...data, branchesIds: [...data.branchesIds, id] })
    }
  }

  function addDepartment(id: any) {
    if (data.departmentsIds.includes(id)) {
      const newDepartments = data.departmentsIds.filter(
        (department: any) => department !== id
      )
      setData({ ...data, departmentsIds: newDepartments })
    } else {
      setData({ ...data, departmentsIds: [...data.departmentsIds, id] })
    }
  }

  function addSpecialist(id: any) {
    if (data.specialistsIds.includes(id)) {
      const newSpecialists = data.specialistsIds.filter(
        (specialist: any) => specialist !== id
      )
      setData({ ...data, specialistsIds: newSpecialists })
    } else {
      setData({ ...data, specialistsIds: [...data.specialistsIds, id] })
    }
  }

  function validate1() {
    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (!isEmail(data.email?.trim()?.toLowerCase())) {
      return setError('Valid email is required')
    }

    if (data.groupId === '') {
      return setError('Group is required')
    }

    setStep(step + 1)
  }

  async function createUser() {
    setError('')
    try {
      const result: any = await updateUser({
        id: user.id,
        data: {
          ...data,
          email: data.email?.trim()?.toLowerCase(),
          branches: branches.filter((branch: any) =>
            data.branchesIds.includes(branch.id)
          ),
          departments: departments.filter((department: any) =>
            data.departmentsIds.includes(department.id)
          ),
          specialists: specialists.filter((specialist: any) =>
            data.specialistsIds.includes(specialist.id)
          ),
        },
      })
      if (result?.error?.status === 409)
        return setError(result?.error?.data?.message)
      if (result?.error)
        return setError('Failed to update user, please try again')
      setData({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        branchesIds: user.branches.map((branch: any) => branch.id),
        departmentsIds: user.departments.map(
          (department: any) => department.id
        ),
        specialistsIds: user.specialists.map(
          (specialist: any) => specialist.id
        ),
        groupId: user.groupId,
        accessPeriod: user.accessPeriod,
      })
      setOpen(false)
    } catch (error) {
      return setError('Failed to update user, please try again')
    }
  }
  useEffect(() => {
    const filteredDepartments = data.departmentsIds.filter(
      (departmentID: any) =>
        data.branchesIds.some((branchID: any) =>
          branches
            .find((branch: any) => branch.id == branchID)
            ?.departments?.some(
              (branchDepartment: { id: string }) =>
                departmentID === branchDepartment.id
            )
        )
    )

    const filteredSpecialists = data.specialistsIds.filter(
      (specialistID: any) =>
        data.branchesIds.some((branchID: any) =>
          branches
            .find((branch: any) => branch.id == branchID)
            ?.departments?.some(
              (departmentSpecialist: { specialists: any[] }) =>
                departmentSpecialist.specialists.some(
                  (item: any) => specialistID === item.id
                )
            )
        )
    )

    if (data?.email)
      setData((prev: any) => {
        return {
          ...prev,
          departmentsIds: [...filteredDepartments],
          specialistsIds: [...filteredSpecialists],
        }
      })
  }, [data.branchesIds])

  useEffect(() => {
    const filteredSpecialists = data.specialistsIds.filter(
      (specialistID: any) =>
        data.departmentsIds.some((departmentID: any) =>
          departments
            .find((department: any) => department.id == departmentID)
            ?.specialists?.some(
              (departmentSpecialist: { id: string }) =>
                specialistID === departmentSpecialist.id
            )
        )
    )

    if (data?.email)
      setData((prev: any) => {
        return {
          ...prev,
          specialistsIds: [...filteredSpecialists],
        }
      })
  }, [data.departmentsIds])

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          {isLoading && (
            <div className="h-40 grid place-content-center">
              <Loader />
            </div>
          )}

          {groups < 1 && !isLoading && (
            <div className="p-3">
              <div className="flex w-full justify-end">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <TbX size={18} />
                </Button>
              </div>
              <div className="grid h-96 w-full place-content-center">
                <div className="text-center">
                  <h1 className="text-lg font-semibold">
                    Please configure groups first to customize user access
                  </h1>
                  <div className="mt-10">
                    <Link
                      className="bg-indigo-600 text-white py-2 px-5 rounded-md"
                      to="/settings"
                    >
                      Go to settings
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {groups.length > 0 && !isLoading && (
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">Edit User</h1>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setOpen(false)}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              {step === 1 && (
                <>
                  <div className="w-full space-y-3 mt-5 px-1">
                    <div className="w-full flex items-center gap-3">
                      <Input2
                        name="firstName"
                        label="First Name"
                        value={data.firstName}
                        setValue={handleChange}
                        type="text"
                        required
                      />
                      <Input2
                        name="lastName"
                        label="Last Name"
                        value={data.lastName}
                        setValue={handleChange}
                        type="text"
                      />
                    </div>
                    <div className="w-full flex items-center gap-3">
                      <Input2
                        name="email"
                        label="Email"
                        value={data.email}
                        setValue={handleChange}
                        type="email"
                        disabled={true}
                      />
                      <Select2
                        label="Group"
                        list={groups}
                        value={data.groupId}
                        setValue={(value: any) =>
                          setData({
                            ...data,
                            groupId: value,
                          })
                        }
                        required
                      />
                    </div>
                  </div>
                  <div className="mt-5 flex items-center">
                    {error !== '' && (
                      <div className="text-rose-600 text-sm">{error}</div>
                    )}
                    <div className="flex-1" />
                    <Button size="sm" onClick={validate1}>
                      Next
                    </Button>
                  </div>
                </>
              )}

              {step === 2 && (
                <div className="space-y-3 mt-5">
                  <div>Select Branches</div>
                  {branches.length < 1 && (
                    <p className="mt-3 text-gray-500">No branches</p>
                  )}
                  {branches.length > 0 && (
                    <div className="flex flex-wrap gap-3">
                      {branches.map((branch: any) => (
                        <div
                          key={branch.id}
                          onClick={() => addBranch(branch.id)}
                          className={cn(
                            data.branchesIds.includes(branch.id)
                              ? 'border-indigo-600'
                              : 'border',
                            'px-3 py-1 rounded-md cursor-pointer border-2'
                          )}
                        >
                          {branch.name} - {branch.location}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="pt-5 flex items-center justify-between">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setStep(1)}
                    >
                      Back
                    </Button>
                    {data.branchesIds.length > 0 ? (
                      <Button
                        size="sm"
                        onClick={() =>
                          data.branchesIds.length > 0 ? setStep(3) : setStep(5)
                        }
                      >
                        Next
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className="space-y-3 mt-5">
                  <div>Select Departments</div>
                  {departments.filter((department: any) =>
                    data.branchesIds.includes(department.branchId)
                  ).length < 1 && (
                    <p className="mt-3 text-gray-500 text-sm">No department</p>
                  )}
                  {departments.length > 0 && (
                    <div className="flex flex-wrap gap-3">
                      {departments
                        .filter((department: any) =>
                          data.branchesIds.includes(department.branchId)
                        )
                        .map((department: any) => (
                          <div
                            key={department.id}
                            onClick={() => addDepartment(department.id)}
                            className={cn(
                              data.departmentsIds.includes(department.id)
                                ? 'border-indigo-600'
                                : 'border',
                              'px-3 py-1 rounded-md cursor-pointer border-2'
                            )}
                          >
                            {department.name} - {department.branch.name}
                          </div>
                        ))}
                    </div>
                  )}
                  <div className="pt-5 flex items-center justify-between">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setStep(2)}
                    >
                      Back
                    </Button>
                    <Button size="sm" onClick={() => setStep(4)}>
                      Next
                    </Button>
                  </div>
                </div>
              )}

              {step === 4 && (
                <div className="space-y-3 mt-5">
                  <div>Select Specialist</div>
                  <div className="flex flex-wrap gap-3">
                    {specialists.filter(
                      (specialist: any) =>
                        data.departmentsIds.includes(specialist.departmentId) &&
                        !specialist.disabled
                    ).length < 1 && (
                      <p className="mt-3 text-sm text-gray-500">
                        No specialist
                      </p>
                    )}
                    {specialists
                      .filter(
                        (specialist: any) =>
                          data.departmentsIds.includes(
                            specialist.departmentId
                          ) && !specialist.disabled
                      )
                      .map((specialist: any) => (
                        <div
                          key={specialist.id}
                          onClick={() => addSpecialist(specialist.id)}
                          className={cn(
                            data.specialistsIds.includes(specialist.id)
                              ? 'border-indigo-600'
                              : 'border',
                            'px-3 py-1 rounded-md cursor-pointer border-2'
                          )}
                        >
                          {specialist.name} - {specialist.department.name}
                        </div>
                      ))}
                  </div>
                  <div className="pt-5 flex items-center justify-between">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setStep(3)}
                    >
                      Back
                    </Button>
                    <Button size="sm" onClick={() => setStep(5)}>
                      Next
                    </Button>
                  </div>
                </div>
              )}

              {step === 5 && (
                <div className="space-y-3 mt-5">
                  <div className="w-full space-y-2">
                    {error !== '' && (
                      <div className="text-rose-600 text-sm">{error}</div>
                    )}
                    <Label>Limit data accessed by time</Label>
                    <div className="flex gap-3 flex-wrap">
                      {accessPeriod.map((period: any) => (
                        <div
                          key={period.id}
                          onClick={() => {
                            if (data.accessPeriodId === period.id) {
                              setData({
                                ...data,
                                accessPeriodId: null,
                              })
                            } else {
                              setData({
                                ...data,
                                accessPeriodId: period.id,
                              })
                            }
                          }}
                          className={cn(
                            data.accessPeriodId === period.id
                              ? 'border-indigo-600'
                              : 'border',
                            'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none'
                          )}
                        >
                          {period.duration}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-3 justify-between">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => setStep(4)}
                    >
                      Back
                    </Button>
                    <Button size="sm" onClick={createUser}>
                      Update User
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}

const Input2 = ({
  label,
  name,
  required,
  value,
  setValue,
  type,
  disabled,
}: any) => {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={name}>
        {label}
        {required && <span className="ml-1 text-rose-500">*</span>}
      </Label>
      <Input
        id={name}
        type={type}
        value={value}
        onChange={setValue}
        disabled={disabled}
      />
    </div>
  )
}

const Select2 = ({ label, name, required, list, value, setValue }: any) => {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={name}>
        {label}
        {required && <span className="ml-1 text-rose-500">*</span>}
      </Label>
      <Select value={value} onValueChange={setValue}>
        <SelectTrigger>
          <SelectValue
            placeholder={
              list.find((item: any) => item.id === value)?.name === undefined
                ? 'Select Group'
                : list.find((item: any) => item.id === value)?.name
            }
          />
        </SelectTrigger>
        <SelectContent>
          {list.map((item: any) => (
            <SelectItem key={item.id} value={item.id}>
              {item.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
