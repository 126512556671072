import { useState, useEffect } from 'react'
import Branches from './branches'
import Departments from './departments'
import Sources from './sources'
import Specialists from './specialists'
import Groups from './groups'
import FollowUp from './follow-up'
import LeadForm from './lead-form'
import { cn } from 'lib/utils'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubscriptionWrapper } from 'components/shared/wrapperSubscription'
import MetaLogin from './meta-login'

import { SettingsMobileMenu } from './components/settings-mobile-menu'
import { SettingsSideMenu } from './components/settings-side-menu'
import NotQualifiedReasons from './not-qualified-reasons'
import Brand from './brand'

export default function Settings() {
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useSelector((state: any) => state.user)
  const [screen, setScreen] = useState(location?.state?.screen || 'followUp')
  useEffect(() => {
    if (location?.state?.screen) {
      setScreen(location?.state?.screen)
    } else {
      setScreen('followUp')
    }
  }, [location?.state])
  useEffect(() => {
    if (user && user.role === 'agent') {
      navigate('/agents-dashboard')
    }
  }, [user])

  return (
    // TODO:
    // ASK FOR
    // MOHAMMAD ALBACHA // 7XL
    <div className="p-3 overflow-hidden h-screen max-w-7xl max-md:pr-2 max-md:pb-10">
      <div className="flex gap-3 h-full max-md:flex-col max-md:justify-center max-md:items-center w-full">
        <SettingsMobileMenu screen={screen} setScreen={setScreen} />
        <SettingsSideMenu screen={screen} setScreen={setScreen} />
        <div className="flex-1 h-full bg-white border rounded-md   w-[calc(100vw-40rem)]  max-md:w-[94vw] overflow-y-scroll">
          {screen === 'followUp' && (
            <SubscriptionWrapper>
              <FollowUp />
            </SubscriptionWrapper>
          )}
          {screen === 'brand' && (
            <SubscriptionWrapper>
              <Brand />
            </SubscriptionWrapper>
          )}
          {screen === 'not-qualified-reasons' && (
            <SubscriptionWrapper>
              <NotQualifiedReasons />
            </SubscriptionWrapper>
          )}
          {screen === 'sources' && (
            <SubscriptionWrapper>
              <Sources />
            </SubscriptionWrapper>
          )}
          {screen === 'groups' && (
            <SubscriptionWrapper>
              <Groups />
            </SubscriptionWrapper>
          )}
          {screen === 'branches' && (
            <SubscriptionWrapper>
              <Branches />
            </SubscriptionWrapper>
          )}
          {screen === 'departments' && (
            <SubscriptionWrapper>
              <Departments />
            </SubscriptionWrapper>
          )}
          {screen === 'specialists' && (
            <SubscriptionWrapper>
              <Specialists />
            </SubscriptionWrapper>
          )}
          {screen === 'leadForm' && (
            <SubscriptionWrapper>
              <LeadForm />
            </SubscriptionWrapper>
          )}

          {screen === 'meta-login' && <MetaLogin />}
        </div>
      </div>
    </div>
  )
}
