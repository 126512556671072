import { ChatLayout } from './components/chat-layout'

export default function Whatsapp() {
  return (
    <>
      <div className="p-3 w-full h-full space-y-3 h-screen overflow-scroll">
        <ChatLayout defaultLayout={undefined} navCollapsedSize={8} />
      </div>
    </>
  )
}
