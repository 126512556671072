import { Button } from 'components/ui/button'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip'
import { TbTrash } from 'react-icons/tb'
import { Timer } from './timer'
import { useEffect, useMemo, useState } from 'react'

interface RecordMessageProps {
  isRecording: boolean
  isRecordingPaused: boolean
  resetRecording: () => void
  canvasRef: React.RefObject<HTMLCanvasElement>
}

// Utility function to pad a number with leading zeros
const padWithLeadingZeros = (num: number, length: number): string => {
  return String(num).padStart(length, '0')
}

export default function RecordMessage({
  isRecording,
  isRecordingPaused,
  resetRecording,
  canvasRef,
}: RecordMessageProps) {
  let timerTimeout: NodeJS.Timeout
  const [timer, setTimer] = useState<number>(0)
  const hours = Math.floor(timer / 3600)
  const minutes = Math.floor((timer % 3600) / 60)
  const seconds = timer % 60
  // Split the hours, minutes, and seconds into individual digits
  const [hourLeft, hourRight] = useMemo(
    () => padWithLeadingZeros(hours, 2).split(''),
    [hours]
  )
  const [minuteLeft, minuteRight] = useMemo(
    () => padWithLeadingZeros(minutes, 2).split(''),
    [minutes]
  )
  const [secondLeft, secondRight] = useMemo(
    () => padWithLeadingZeros(seconds, 2).split(''),
    [seconds]
  )

  useEffect(() => {
    if (isRecording && !isRecordingPaused) {
      timerTimeout = setTimeout(() => {
        setTimer((prevTimer) => prevTimer + 1)
      }, 1000)
    }
    if (!isRecording && !isRecordingPaused) {
      setTimer(0)
      clearTimeout(timerTimeout)
    }
    return () => clearTimeout(timerTimeout)
  }, [isRecording, isRecordingPaused, timer])

  return (
    <div className="flex h-16 rounded-md relative w-full items-center justify-center gap-3 max-w-5xl border p-1">
      <Timer
        hourLeft={hourLeft}
        hourRight={hourRight}
        minuteLeft={minuteLeft}
        minuteRight={minuteRight}
        secondLeft={secondLeft}
        secondRight={secondRight}
      />
      <canvas ref={canvasRef} className="h-full w-full bg-background flex" />
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={resetRecording} size="icon" variant="destructive">
              <TbTrash size={15} />
            </Button>
          </TooltipTrigger>
          <TooltipContent className="m-2">
            <span> Reset recording</span>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  )
}
