import { useAddSettingsMutation } from 'app/features/settings'
import Loader from 'components/shared/loader'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select'
import { convertTimeGap, isExist } from 'lib/utils'
import Branches from 'pages/settings/branches'
import Departments from 'pages/settings/departments'
import Groups from 'pages/settings/groups'
import Sources from 'pages/settings/sources'
import Specialists from 'pages/settings/specialists'
import { useEffect, useState } from 'react'

export default function Init({ open, setOpen }: any) {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [settings, setSettings] = useState<any>({
    numberOfFollowUps: '1',
    followUpTimeGap: '',
    timeAccessType: 'HOUR',
    timeAccessValue: '1',
    numberOfInsuranceClaims: '1',
  })
  const [addSettings, { isLoading, isError, error: serverError }] =
    useAddSettingsMutation()

  useEffect(() => {
    if (isError) {
      setError('Server error, contact administrator please')
    }
  }, [isError])

  async function update() {
    setLoading(true)
    setError('')
    if (
      !isExist(
        Object.values([
          settings.numberOfFollowUps,
          settings.timeAccessValue,
          settings.numberOfInsuranceClaims,
        ])
      )
    ) {
      setLoading(false)
      return setError('All fields are required')
    }
    await addSettings({
      numberOfFollowUps: parseInt(settings.numberOfFollowUps),
      followUpTimeGap: convertTimeGap(
        settings.timeAccessValue,
        settings.timeAccessType
      ),
      timeAccessType: settings.timeAccessType,
      timeAccessValue: parseInt(settings.timeAccessValue),
      numberOfInsuranceClaims: parseInt(settings.numberOfInsuranceClaims),
    })
    setLoading(false)
    setOpen(false)
    window.location.reload()
  }

  if (loading || isLoading) {
    return (
      <div className="w-screen fixed z-50 inset-0 bg-white h-screen grid place-content-center">
        <Loader />
      </div>
    )
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild></AlertDialogTrigger>
      <AlertDialogContent className="p-3 max-w-2xl">
        <AlertDialogHeader className="p-3">
          <AlertDialogTitle className="text-3xl">
            Welcome to Doctorna
          </AlertDialogTitle>
          <AlertDialogDescription>
            Let's start by setting up the system, these configurations can be
            changed later in settings.
          </AlertDialogDescription>
        </AlertDialogHeader>
        {step === 1 && (
          <div className="flex flex-col gap-3 mt-5 p-3">
            <div className="py-3 border-b border-dashed grid grid-cols-2 items-center gap-3">
              <div className="flex flex-col gap-1">
                <h1 className="text-lg font-medium">Number of follow ups</h1>
                <p className="text-xs text-gray-500">
                  Select the numbers of follow ups. you can select from 1 to 7
                </p>
              </div>
              <div className="flex justify-end">
                <Select
                  value={settings.numberOfFollowUps}
                  onValueChange={(value: any) =>
                    setSettings({
                      ...settings,
                      numberOfFollowUps: value,
                    })
                  }
                >
                  <SelectTrigger className="w-[70px]">
                    <SelectValue>{settings.numberOfFollowUps}</SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="1">1</SelectItem>
                      <SelectItem value="2">2</SelectItem>
                      <SelectItem value="3">3</SelectItem>
                      <SelectItem value="4">4</SelectItem>
                      <SelectItem value="5">5</SelectItem>
                      <SelectItem value="6">6</SelectItem>
                      <SelectItem value="7">7</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="py-3 border-b border-dashed grid grid-cols-2 items-center gap-3">
              <div className="flex flex-col gap-1">
                <h1 className="text-lg font-medium">Follow up time gap</h1>
                <p className="text-xs text-gray-500">
                  Select the time gap between each follow up in hours, days or
                  weeks
                </p>
              </div>
              <div className="flex items-center gap-3 justify-end">
                <Input
                  className="w-[70px]"
                  type="number"
                  value={settings.timeAccessValue}
                  onChange={(event: any) =>
                    setSettings({
                      ...settings,
                      timeAccessValue: event.target.value,
                    })
                  }
                  min={1}
                  max={99}
                />
                <Select
                  value={settings.timeAccessType}
                  onValueChange={(value: any) =>
                    setSettings({
                      ...settings,
                      timeAccessType: value,
                    })
                  }
                >
                  <SelectTrigger className="w-[100px]">
                    <SelectValue>
                      {!settings.timeAccessType
                        ? ''
                        : settings.timeAccessType === 'HOUR'
                          ? 'Hours'
                          : settings.timeAccessType === 'DAY'
                            ? 'Days'
                            : 'Weeks'}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectItem value="HOUR">Hours</SelectItem>
                      <SelectItem value="DAY">Days</SelectItem>
                      <SelectItem value="WEEK">Weeks</SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>

            <div className="py-3 border-b border-dashed grid grid-cols-2 items-center gap-3">
              <div className="flex flex-col gap-1">
                <h1 className="text-lg font-medium">
                  Number of insurance claims
                </h1>
                <p className="text-xs text-gray-500">
                  Select the numbers of insurance claims. you can select from 1
                  to 100
                </p>
              </div>
              <div className="flex justify-end">
                <Select
                  value={settings.numberOfInsuranceClaims}
                  onValueChange={(value: any) =>
                    setSettings({
                      ...settings,
                      numberOfInsuranceClaims: value,
                    })
                  }
                >
                  <SelectTrigger className="w-[70px]">
                    <SelectValue>
                      {settings.numberOfInsuranceClaims}
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {Array.from({ length: 100 }, (_, i) => i + 1).map(
                        (item: any) => {
                          return (
                            <SelectItem value={item.toString()}>
                              {item}
                            </SelectItem>
                          )
                        }
                      )}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="max-h-[50vh] overflow-hidden overflow-y-scroll">
            <Sources />
          </div>
        )}

        {step === 3 && (
          <div className="max-h-[50vh] overflow-hidden overflow-y-scroll">
            <Groups />
          </div>
        )}

        {step === 4 && (
          <div className="max-h-[50vh] overflow-hidden overflow-y-scroll">
            <Branches />
          </div>
        )}

        {step === 5 && (
          <div className="max-h-[50vh] overflow-hidden overflow-y-scroll">
            <Departments />
          </div>
        )}

        {step === 6 && (
          <div className="max-h-[50vh] overflow-hidden overflow-y-scroll">
            <Specialists />
          </div>
        )}

        <div className="mt-5 flex justify-between items-center p-3">
          {error !== '' && (
            <p className="px-3 py-2 bg-rose-100 rounded-md text-xs font-medium text-rose-600">
              {error}
            </p>
          )}
          <div className="flex items-center w-full">
            {step > 1 && (
              <Button
                variant="outline"
                onClick={() => setStep(step - 1)}
                size="sm"
              >
                Back
              </Button>
            )}
            <div className="flex-1" />
            {step < 6 && (
              <Button onClick={() => setStep(step + 1)} size="sm">
                Next
              </Button>
            )}
            {step === 6 && (
              <Button onClick={update} size="sm">
                Configure
              </Button>
            )}
          </div>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
