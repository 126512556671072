import { api } from '../api'

type Filters = {
  take: number
  skip: number
  query?: string
}

type User = {
  id: string
  firstName: string
  lastName: string
  name: string
  email: string
  verified: boolean
  disable: boolean
  role: string
  branches: string[]
  createdActivities: any
  createdLeads: any
  updatedLeads: any
  leads: any
  followUps: any
  bookings: any
}

type UsersResponse = {
  count: number
  users: User[]
}

type AgentsNames = {
  firstName: string
  lastName: string
  id: string
}

export type UserPerformanceResponse = {
  id: string
  firstName: string
  leadCount: number
  followUpCount: number
  bookingCount: number
  userScore: number
}

export const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<UsersResponse, Filters>({
      query: (filters: Filters) => `/users?filters=${JSON.stringify(filters)}`,
      providesTags: (result) =>
        result
          ? [
              ...result.users.map(({ id }) => ({
                type: 'Users' as const,
                id,
              })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    getAllUser: builder.query<UsersResponse, void>({
      query: () => `/users/all`,
      providesTags: (result) =>
        result
          ? [
              ...result.users.map(({ id }) => ({
                type: 'Users' as const,
                id,
              })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    getAllAgentsNames: builder.query<AgentsNames[], void>({
      query: () => `/users/agents-names`,
      providesTags: ['Users'],
    }),
    getUser: builder.query<User, string | undefined>({
      query: (id) => `/users/${id}`,
      providesTags: ['Users'],
    }),
    addUser: builder.mutation({
      query: (user) => ({
        url: '/users',
        method: 'POST',
        body: {
          ...user,
        },
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }, { type: 'Users' }],
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `/users/${user.id}`,
        method: 'PUT',
        body: {
          ...user.data,
        },
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.id }],
    }),
    resetUser: builder.mutation({
      query: (credentials) => ({
        url: `/users/reset/${credentials.id}`,
        method: 'PUT',
        body: {
          newPassword: credentials.newPassword,
        },
      }),
      invalidatesTags: ['Users'],
    }),
    disableUser: builder.mutation({
      query: (id) => ({
        url: `/users/disable/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    activateDowngradedUser: builder.mutation({
      query: (id) => ({
        url: `/users/activate-downgraded-user/${id}`,
        method: 'PUT',
      }),
      invalidatesTags: ['Users'],
    }),
    getPaymentActiveAgentsCount: builder.query({
      query: (id) => ({
        url: `/users/get-payment-active-agents-count`,
      }),
      providesTags: ['Users'],
    }),
    resetPassword: builder.mutation({
      query: (email) => ({
        url: '/auth/request-reset',
        method: 'POST',
        body: {
          email,
        },
      }),
      invalidatesTags: ['Users'],
    }),
    getUsersPerformance: builder.query<
      UserPerformanceResponse[],
      DashboardFilters | null
    >({
      query: (filters: DashboardFilters) =>
        `/users/user-performance?filters=${JSON.stringify(filters)}`,
      providesTags: ['Dashboard'],
    }),
    removeUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
    }),
  }),
})

export const {
  useAddUserMutation,
  useGetUserQuery,
  useGetUsersQuery,
  useGetAllUserQuery,
  useUpdateUserMutation,
  useDisableUserMutation,
  useResetUserMutation,
  useResetPasswordMutation,
  useGetUsersPerformanceQuery,
  useRemoveUserMutation,
  useGetPaymentActiveAgentsCountQuery,
  useActivateDowngradedUserMutation,
  useGetAllAgentsNamesQuery,
} = userApi
