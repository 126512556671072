import { cn } from 'lib/utils'
import { ScrollArea } from 'components/ui/scroll-area'
import { useEffect } from 'react'
import useApp from 'hooks/useApp'

export default function LeadInterest({
  data,
  setData,
  addBranch,
  addDepartment,
  addSpecialist,
  branches,
  departments,
  specialists,
  isDetailsPage,
  isCreatePage,
  isInModal,
}: any) {
  // TODO: to be deleted
  // const {
  //   branches: appBranches,
  //   departments: appDepartments,
  //   specialists: appSpecialists,
  // } = useApp()
  // let interestBranches: any
  // let interestDepartments: any
  // let interestSpecialists: any
  // if (isDetailsPage) {
  //   interestBranches = branches
  //   interestDepartments = departments
  //   interestSpecialists = specialists
  // } else {
  //   interestBranches = appBranches
  //   interestDepartments = appDepartments
  //   interestSpecialists = appSpecialists
  // }

  useEffect(() => {
    const filteredDepartments = data.departmentIDs.filter((departmentID: any) =>
      data.branchIDs.some((branchID: any) =>
        branches
          .find((branch: any) => branch.id == branchID)
          ?.departments?.some(
            (branchDepartment: { id: string }) =>
              departmentID === branchDepartment.id
          )
      )
    )

    const filteredSpecialists = data.specialistIDs.filter((specialistID: any) =>
      data.branchIDs.some((branchID: any) =>
        branches
          .find((branch: any) => branch.id == branchID)
          ?.departments?.some((departmentSpecialist: { specialists: any[] }) =>
            departmentSpecialist.specialists.some(
              (item: any) => specialistID === item.id
            )
          )
      )
    )

    if (data?.email || isCreatePage)
      setData((prev: any) => {
        return {
          ...prev,
          departmentIDs: [...filteredDepartments],
          specialistIDs: [...filteredSpecialists],
        }
      })
  }, [data.branchIDs])

  useEffect(() => {
    const filteredSpecialists = data.specialistIDs.filter((specialistID: any) =>
      data.departmentIDs.some((departmentID: any) =>
        departments
          .find((department: any) => department.id == departmentID)
          ?.specialists?.some(
            (departmentSpecialist: { id: string }) =>
              specialistID === departmentSpecialist.id
          )
      )
    )

    if (data?.email || isCreatePage)
      setData((prev: any) => {
        return {
          ...prev,
          specialistIDs: [...filteredSpecialists],
        }
      })
  }, [data.departmentIDs])

  return (
    <div className="w-full gap-3 space-y-3 px-1">
      <div
        className={`w-full max-w-[100vw]   flex gap-3  max-lg:flex-col lg:max-w-[calc(100vw-15rem)] xl:max-w-[calc(100vw-20rem)] ${isInModal && 'flex-col '}`}
      >
        <div
          className={cn(
            isDetailsPage ? 'min-h-auto' : 'h-auto',
            'flex flex-1 gap-3 flex-col mt-5  '
          )}
        >
          <h1 className="font-semibold text-md ">
            {isDetailsPage ? 'Branches' : 'Select Branches'}
          </h1>
          {branches.length < 1 && (
            <p className="mt-3 text-gray-500">No branches</p>
          )}
          {branches.length > 0 && (
            <ScrollArea
              className={cn(
                isDetailsPage ? 'h-auto' : 'min-h-72',
                'flex  flex-col'
              )}
            >
              {branches.map((branch: any) => (
                <div
                  key={branch.id}
                  onClick={() => addBranch(branch.id)}
                  className={cn(
                    data.branchIDs.includes(branch.id)
                      ? 'border-indigo-600'
                      : 'border',
                    'px-3 py-1 rounded-md cursor-pointer border-2 my-3',
                    isDetailsPage ? 'pointer-events-none opacity-90' : ''
                  )}
                >
                  {branch.name} - {branch.location}
                </div>
              ))}
            </ScrollArea>
          )}
        </div>

        {/* Departments */}
        <div
          className={cn(
            isDetailsPage ? 'min-h-auto' : 'h-auto',
            'flex flex-1 gap-3 flex-col mt-5 '
          )}
        >
          <h1 className="font-semibold text-md overflow-x-hidden  ">
            {isDetailsPage ? 'Departments' : 'Select Departments'}
          </h1>
          {departments.filter((department: any) =>
            data.branchIDs.includes(department.branchId)
          ).length < 1 && (
            <p className="mt-3 text-gray-500 text-sm">No department</p>
          )}
          {departments.length > 0 && (
            <ScrollArea
              className={cn(
                data.branchIDs.length > 0 ? 'opacity-1' : 'opacity-0',
                'flex flex-wrap gap-3'
              )}
            >
              {departments
                .filter((department: any) =>
                  data.branchIDs.includes(department.branchId)
                )
                .map((department: any) => (
                  <div
                    key={department.id}
                    onClick={() => addDepartment(department.id)}
                    className={cn(
                      data.departmentIDs.includes(department.id)
                        ? 'border-indigo-600'
                        : 'border',
                      'px-3 py-1 rounded-md cursor-pointer border-2 my-3',
                      isDetailsPage ? 'pointer-events-none opacity-90' : ''
                    )}
                  >
                    {department?.name} - {department?.branch?.name}
                  </div>
                ))}
            </ScrollArea>
          )}
          <div className="flex-1" />
        </div>

        {/* Specialists */}

        <div
          className={cn(
            isDetailsPage ? 'min-h-auto' : 'h-auto',
            'flex flex-1 gap-3 flex-col mt-5   '
          )}
        >
          <h1 className="font-semibold text-md">
            {isDetailsPage ? 'Specialists' : 'Select Specialists'}
          </h1>
          {specialists.filter(
            (specialist: any) =>
              data.departmentIDs.includes(specialist.departmentId) &&
              !specialist.disabled
          ).length < 1 && (
            <p className="mt-3 text-sm text-gray-500">No specialist</p>
          )}
          {specialists.length > 0 && (
            <ScrollArea
              className={cn(
                data.branchIDs.length > 0 ? 'opacity-1' : 'opacity-0 h-0 ',
                'flex flex-wrap gap-3'
              )}
            >
              {specialists
                .filter(
                  (specialist: any) =>
                    data.departmentIDs.includes(specialist.departmentId) &&
                    !specialist.disabled
                )
                .map((specialist: any) => (
                  <div
                    key={specialist.id}
                    onClick={() => addSpecialist(specialist.id)}
                    className={cn(
                      data.specialistIDs.includes(specialist.id)
                        ? 'border-indigo-600'
                        : 'border',
                      'px-3 py-1 rounded-md cursor-pointer border-2 my-3',
                      isDetailsPage ? 'pointer-events-none opacity-90' : ''
                    )}
                  >
                    {specialist?.name} - {specialist?.department?.name}
                  </div>
                ))}
            </ScrollArea>
          )}
        </div>
      </div>
    </div>
  )
}
