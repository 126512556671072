import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Label } from '../ui/label'

export default function CustomPhoneInput({
  value,
  handleChange,
  id,
  label,
  errorMessage,
  required,
  disabled,
}: any) {
  return (
    <div className="w-full space-y-2">
      <Label htmlFor={id}>
        {label}
        {required && <span className="text-rose-500 ml-1">*</span>}
      </Label>
      <PhoneInput
        country={'ae'}
        value={value}
        onChange={handleChange}
        inputProps={{
          id: { id },
          name: { id },
          required,
        }}
        disabled={disabled}
      />
      {errorMessage && (
        <p className="py-1 text-xs text-rose-500">{errorMessage}</p>
      )}
    </div>
  )
}
