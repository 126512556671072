import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { useAppDispatch } from '../app/store'
import { SocketClient } from 'lib/socket'
import { api } from 'app/api'

export const SocketContext = createContext<any | null>(null)

export const SocketProvider = ({ children }: { children: any }) => {
  const dispatch = useAppDispatch()
  const socket = SocketClient.getInstance()

  const [check, setCheck] = useState(false)

  useEffect(() => {
    socket.onMessage('message', async () => {
      const audio = new Audio('/notification-sound.wav')
      audio.play()
      dispatch(api.util.invalidateTags(['Contacts']))
    })
  }, [])

  return (
    <SocketContext.Provider
      value={{
        socket,
        check,
        setCheck,
      }}
    >
      {children}
    </SocketContext.Provider>
  )
}

export default function useSocket() {
  return useContext(SocketContext)
}
