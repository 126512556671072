'use client'

import React, { useEffect, useState } from 'react'
import { cn } from 'lib/utils'
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from 'components/ui/resizable'
import { ChatSidebar } from './chat-sidebar'
import { Chat } from './chat'
import {
  useGetContactsQuery,
  useGetProfileQuery,
  useMarkAsReadMutation,
} from 'app/features/whatsapp'
import { Link } from 'react-router-dom'

interface ChatLayoutProps {
  defaultLayout: number[] | undefined
  defaultCollapsed?: boolean
  navCollapsedSize: number
}

export function ChatLayout({
  defaultLayout = [320, 480],
  defaultCollapsed = false,
  navCollapsedSize,
}: ChatLayoutProps) {
  const { data: contacts } = useGetContactsQuery()
  const { data: profile } = useGetProfileQuery()
  const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsed)
  const [selectedConversationId, setSelectedConversationId] = React.useState('')
  const [isMobile, setIsMobile] = useState(false)
  const [markAsRead] = useMarkAsReadMutation()

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    // Initial check
    checkScreenWidth()

    // Event listener for screen width changes
    window.addEventListener('resize', checkScreenWidth)

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenWidth)
    }
  }, [])

  return (
    <>
      {' '}
      {profile ? (
        <ResizablePanelGroup
          direction="horizontal"
          onLayout={(sizes: number[]) => {
            document.cookie = `react-resizable-panels:layout=${JSON.stringify(
              sizes
            )}`
          }}
          className="h-full items-stretch"
        >
          <ResizablePanel
            defaultSize={defaultLayout[0]}
            collapsedSize={navCollapsedSize}
            collapsible={true}
            minSize={isMobile ? 0 : 24}
            maxSize={isMobile ? 8 : 30}
            onCollapse={() => {
              setIsCollapsed(true)
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                true
              )}`
            }}
            onExpand={() => {
              setIsCollapsed(false)
              document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
                false
              )}`
            }}
            className={cn(
              isCollapsed &&
                'min-w-[50px] md:min-w-[70px] transition-all duration-300 ease-in-out'
            )}
          >
            <ChatSidebar
              isCollapsed={isCollapsed || isMobile}
              links={contacts || []}
              isMobile={isMobile}
              onClickItem={(id: string) => {
                setSelectedConversationId(id)
                markAsRead(id)
              }}
              profile={profile}
            />
          </ResizablePanel>
          <ResizableHandle withHandle />
          <ResizablePanel defaultSize={defaultLayout[1]} minSize={30}>
            <Chat
              messages={[]}
              selectedConversationId={selectedConversationId}
              isMobile={isMobile}
            />
          </ResizablePanel>
        </ResizablePanelGroup>
      ) : (
        <>
          <div className="flex flex-col space-y-4 justify-center items-center h-screen">
            <Link
              to={{ pathname: '/settings' }}
              state={{ screen: 'meta-login' }}
              className=" block mt-4 mb-2  w-32 px-4 py-2 text-center bg-indigo-700 hover:bg-indigo-600 text-white rounded"
            >
              Log into your meta account
            </Link>
          </div>
        </>
      )}
    </>
  )
}
