import { useState, useEffect } from 'react'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useResetPasswordMutation } from 'app/features/users'
import { useLogoutMutation } from 'app/features/auth'

export default function ResetPassword() {
  const navigate = useNavigate()
  const [error, setError] = useState<string | undefined>('')
  const [info, setInfo] = useState<string | undefined>('')
  const [email, setEmail] = useState('')
  const { user } = useSelector((state: any) => state.user)

  const [resetPassword, { isLoading }] = useResetPasswordMutation()
  const [logout] = useLogoutMutation()

  useEffect(() => {
    if (user && user.role === 'ADMIN') {
      navigate('/dashboard')
    } else if (user && user.role === 'AGENT') {
      navigate('/leads')
    } else if (!user) {
      logout(undefined)
    }
  }, [user])

  const handleReset = async (e: any) => {
    e.preventDefault()
    setError('')
    try {
      await resetPassword(email?.trim().toLowerCase())
      setInfo(
        "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder."
      )
    } catch (error) {
      return setError('Invalid Email')
    }
  }

  return (
    <div className="grid h-screen w-screen place-content-center">
      {/* Login */}
      <h1 className="text-lg font-semibold">Reset password</h1>
      <div className="rounded-md border p-3 bg-white dark:bg-gray-900 shadow-sm mt-3">
        <form className="w-96 space-y-3" onSubmit={handleReset}>
          {error !== '' && (
            <div className="my-1 font-medium text-sm text-rose-600">
              {error}
            </div>
          )}
          {info !== '' && (
            <div className="my-1 font-medium text-sm">{info}</div>
          )}
          {info === '' && (
            <>
              <div className="space-y-2">
                <Label>Email</Label>
                <Input
                  type="email"
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                  required
                  disabled={isLoading}
                />
              </div>
              <div className="pt-3">
                <Button disabled={isLoading} className="w-full">
                  Reset
                </Button>
              </div>
            </>
          )}
          <div>
            <a
              href="/login"
              className="text-sm text-gray-600 hover:text-gray-700"
            >
              Back to sign in
            </a>
          </div>
        </form>
      </div>
    </div>
  )
}
