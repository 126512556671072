import { api } from '../api'

export const settingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<any, void>({
      query: () => '/settings',
      providesTags: ['Settings'],
    }),
    addSettings: builder.mutation({
      query: (setting) => ({
        url: '/settings',
        method: 'POST',
        body: {
          ...setting,
        },
      }),
      invalidatesTags: [
        { type: 'Sources', id: 'LIST' },
        'Leads',
        'Settings',
        'Dashboard',
      ],
    }),
    updateSettings: builder.mutation({
      query: (setting) => ({
        url: `/settings/${setting.id}`,
        method: 'PUT',
        body: {
          numberOfFollowUps: setting.numberOfFollowUps,
          followUpTimeGap: setting.followUpTimeGap,
          timeAccessType: setting.timeAccessType,
          timeAccessValue: setting.timeAccessValue,
          numberOfInsuranceClaims: setting.numberOfInsuranceClaims,
        },
      }),
      invalidatesTags: [
        { type: 'Sources', id: 'LIST' },
        'Leads',
        'Settings',
        'Dashboard',
      ],
    }),
    getAccessPeriod: builder.query<any, void>({
      query: () => '/settings/access-period',
      providesTags: ['Settings'],
    }),
    getForms: builder.query<any, void>({
      query: () => '/settings/forms',
      providesTags: ['Settings'],
    }),
    addForm: builder.mutation({
      query: (form) => ({
        url: '/settings/forms',
        method: 'POST',
        body: {
          ...form,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    disableForm: builder.mutation({
      query: (formId) => ({
        url: `/settings/forms/${formId}`,
        method: 'PUT',
        body: {
          valid: false,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    getFormTokens: builder.mutation({
      query: (sourceId: any) => ({
        url: '/settings/forms/token',
        method: 'POST',
        body: {
          ...sourceId,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    getNotQualifiedReasons: builder.query<any, void>({
      query: () => '/settings/not-qualified-reasons',
      providesTags: ['Settings'],
    }),

    addNotQualifiedReason: builder.mutation({
      query: (reason) => ({
        url: '/settings/not-qualified-reasons',
        method: 'POST',
        body: {
          ...reason,
        },
      }),
      invalidatesTags: ['Settings'],
    }),
    updateNotQualifiedReason: builder.mutation({
      query: (reason) => ({
        url: `/settings/not-qualified-reasons/${reason.id}`,
        method: 'PUT',
        body: {
          ...reason.data,
        },
      }),
      invalidatesTags: (result, error, arg) => ['Settings'],
    }),
    updateBrand: builder.mutation({
      query: (data) => ({
        url: `/settings/brand/${data.id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Settings'],
    }),
  }),
})

export const {
  useGetSettingsQuery,
  useAddSettingsMutation,
  useUpdateSettingsMutation,
  useGetAccessPeriodQuery,
  useGetFormsQuery,
  useAddFormMutation,
  useDisableFormMutation,
  useGetFormTokensMutation,
  useGetNotQualifiedReasonsQuery,
  useAddNotQualifiedReasonMutation,
  useUpdateNotQualifiedReasonMutation,
  useUpdateBrandMutation,
} = settingsApi
