import { useState } from 'react'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from 'components/ui/dropdown-menu'
import { TbDotsVertical } from 'react-icons/tb'
import EditUser from '../edit-user'
import ResetUser from './reset-user'
import DisableUser from './disable-user'
import RemoveUser from '../remove-user'
import {
  // useGetPaymentActiveAgentsCountQuery,
  useRemoveUserMutation,
} from 'app/features/users'
import TogglePaymentDisable from './toggle-payment-disable'
//import { useGetMyCurrentSubscriptionQuery } from 'app/features/payment'
import * as Tooltip from '@radix-ui/react-tooltip'

export function RowAction({ user }: any) {
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [openReset, setOpenReset] = useState(false)
  const [openDisable, setOpenDisable] = useState(false)
  const [openPaymentDisable, setOpenPaymentDisable] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [removeUser] = useRemoveUserMutation()

  // const {
  //   data: myCurrentSubscription,
  //   isLoading: isMyCurrentSubscriptionLoading,
  // } = useGetMyCurrentSubscriptionQuery(null)

  // const {
  //   data: paymentAgentsActiveCount,
  //   isLoading: isPaymentAgentsActiveCountLoading,
  // } = useGetPaymentActiveAgentsCountQuery(null)

  const remove = async () => {
    await removeUser(selectedUser.id)
    window.location.reload()
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <TbDotsVertical size={18} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          {user.verified ? (
            <>
              <DropdownMenuItem
                onClick={() => {
                  setSelectedUser(user)
                  setOpenReset(true)
                }}
              >
                Reset Password
              </DropdownMenuItem>

              {/* 
              // TODO: refactor, this for enable/disable-downgrade user
              {!user.disabled && user.isAgentDisableDueToSubscriptionPlan && (
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <span>
                        <DropdownMenuItem
                          disabled={
                            isMyCurrentSubscriptionLoading ||
                            isPaymentAgentsActiveCountLoading ||
                            (myCurrentSubscription?.stripeSubscriptionNumOfUsers <=
                              paymentAgentsActiveCount &&
                              user.isAgentDisableDueToSubscriptionPlan)
                          }
                          onClick={() => {
                            setSelectedUser(user)
                            setOpenPaymentDisable(true)
                          }}
                        >
                          Activate
                        </DropdownMenuItem>
                      </span>
                    </Tooltip.Trigger>

                    {user.isAgentDisableDueToSubscriptionPlan &&
                      myCurrentSubscription?.stripeSubscriptionNumOfUsers <=
                        paymentAgentsActiveCount && (
                        <Tooltip.Portal>
                          <Tooltip.Content
                            className=" rounded px-4 py-2  animate-pulse  z-50  bg-yellow-200 shadow-md shadow-gray-500 text-sm mr-10"
                            sideOffset={5}
                          >
                            Please upgrade subscription, OR Disable some agent
                            <Tooltip.Arrow className="TooltipArrow" />
                          </Tooltip.Content>
                        </Tooltip.Portal>
                      )}
                  </Tooltip.Root>
                </Tooltip.Provider>
              )} */}

              {/* {!(
                !user.disabled && user.isAgentDisableDueToSubscriptionPlan
              ) && (
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <span>
                        <DropdownMenuItem
                          disabled={
                            isMyCurrentSubscriptionLoading ||
                            isPaymentAgentsActiveCountLoading ||
                            (myCurrentSubscription?.stripeSubscriptionNumOfUsers <=
                              paymentAgentsActiveCount &&
                              user.disabled)
                          }
                          onClick={() => {
                            setSelectedUser(user)
                            setOpenDisable(true)
                          }}
                        >
                          {user.disabled ? 'Enable' : 'Disable'}
                        </DropdownMenuItem>
                      </span>
                    </Tooltip.Trigger>

                    {user.disabled &&
                      myCurrentSubscription?.stripeSubscriptionNumOfUsers <=
                        paymentAgentsActiveCount && (
                        <Tooltip.Portal>
                          <Tooltip.Content
                            className=" rounded px-4 py-2  animate-pulse  z-50  bg-yellow-200 shadow-md shadow-gray-500 text-sm mr-10"
                            sideOffset={5}
                          >
                            Please upgrade subscription, OR Disable some agent
                            <Tooltip.Arrow className="TooltipArrow" />
                          </Tooltip.Content>
                        </Tooltip.Portal>
                      )}
                  </Tooltip.Root>
                </Tooltip.Provider>
              )} */}

              <DropdownMenuItem
                onClick={() => {
                  setSelectedUser(user)
                  setOpenDisable(true)
                }}
              >
                {user.disabled ? 'Enable' : 'Disable'}
              </DropdownMenuItem>

              <DropdownMenuItem
                onClick={() => {
                  setSelectedUser(user)
                  setOpenEdit(true)
                }}
              >
                Edit
              </DropdownMenuItem>
            </>
          ) : (
            <DropdownMenuItem
              onClick={() => {
                setSelectedUser(user)
                setOpenRemove(true)
              }}
            >
              Remove
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedUser && openReset && (
        <ResetUser
          user={selectedUser}
          open={openReset}
          setOpen={setOpenReset}
        />
      )}
      {selectedUser && openDisable && (
        <DisableUser
          user={selectedUser}
          open={openDisable}
          setOpen={setOpenDisable}
        />
      )}
      {selectedUser && openPaymentDisable && (
        <TogglePaymentDisable
          user={selectedUser}
          open={openPaymentDisable}
          setOpen={setOpenPaymentDisable}
        />
      )}
      {selectedUser && openEdit && (
        <EditUser user={selectedUser} open={openEdit} setOpen={setOpenEdit} />
      )}
      {selectedUser && openRemove && (
        <RemoveUser remove={remove} open={openRemove} setOpen={setOpenRemove} />
      )}
    </>
  )
}
