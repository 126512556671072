import {
  useAddGroupMutation,
  useGetGroupsQuery,
  useUpdateGroupMutation,
} from 'app/features/group'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useEffect, useState } from 'react'
import { TbEdit } from 'react-icons/tb'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
} from 'components/ui/alert-dialog'
import { Cross1Icon } from '@radix-ui/react-icons'
import { CustomInput } from 'components/shared/custom-input'
import { Label } from 'components/ui/label'
import { cn } from 'lib/utils'

const tabs = [
  'Dashboard',
  'Leads',
  'Booked',
  'Show',
  'Not_Qualified',
  'Insurance',
  'Insurance_Portal',
]

export default function Groups() {
  const [error, setError] = useState('')
  const [data, setData] = useState<{ name: string; tabs: string[] }>({
    name: '',
    tabs: [],
  })

  const [selectedGroup, setSelectedGroup] = useState('')
  const [mode, setMode] = useState('add')
  const [open, setOpen] = useState(false)

  const { data: groups, isError, isLoading: dataLoading } = useGetGroupsQuery()
  const [addGroup, { isLoading: createLoading, isError: createError }] =
    useAddGroupMutation()
  const [updateGroup, { isLoading: updateLoading, isError: updateError }] =
    useUpdateGroupMutation()

  const isLoading = dataLoading || createLoading || updateLoading

  useEffect(() => {
    if (createError || updateError) setError('Failed to add group')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setData({ name: '', tabs: [] })
      setSelectedGroup('')
      setError('')
      setMode('add')
    }
  }, [mode])

  const handleSelectTab = (tab: string) => {
    if (data.tabs.includes(tab)) {
      const newTabs = data.tabs.filter((item: any) => item !== tab)
      setData({ ...data, tabs: newTabs })
    } else {
      setData({ ...data, tabs: [...data.tabs, tab] })
    }
  }
  const handleGroup = async (e: any) => {
    e.preventDefault()
    setError('')

    if (mode === 'add') await addGroup({ name: data.name, tabs: data.tabs })
    else
      await updateGroup({
        id: selectedGroup,
        data: { name: data.name, tabs: data.tabs },
      })
    setData({ name: '', tabs: [] })
    setMode('add')
    setOpen(false)
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      <h1 className="text-lg font-medium">Groups</h1>
      <p className="text-xs text-muted-foreground">
        Create groups that will be used to categorize users
      </p>
      {/* Form */}
      <div className="flex items-center gap-3 mt-5 max-md:flex-col">
        <div className="flex-1" />

        <SelectTabs
          open={open}
          setOpen={setOpen}
          error={error}
          setError={setError}
          handleGroup={handleGroup}
          handleSelectTab={handleSelectTab}
          isLoading={isLoading}
          data={data}
          setData={setData}
          mode={mode}
          setMode={setMode}
        />
      </div>
      {error !== '' && (
        <p className="my-3 text-xs text-right font-medium text-rose-600">
          {error}
        </p>
      )}
      {/* List */}
      <div className="mt-5 flex flex-col gap-3">
        {groups?.map((group: any) => (
          <div
            key={group.id}
            className="flex items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800 max-sm:items-start"
          >
            <div className="w-28">{group.name}</div>
            <ul className="flex gap-1 items-center justify-center ml-10  max-sm:flex-col max-sm:items-start max-sm:justify-start">
              {group.tabs?.map((tab: any) => {
                return (
                  <li className="px-3 py-1 rounded-md cursor-pointer border-2 text-sm bg-slate-200">
                    {tab}
                  </li>
                )
              })}
            </ul>
            <div className="flex-1" />
            <div className="flex items-center gap-3">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setMode('update')
                  setOpen(true)
                  setData({ name: group.name, tabs: group.tabs || [] })
                  setSelectedGroup(group.id)
                }}
              >
                <TbEdit size={18} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const SelectTabs = ({
  open,
  setOpen,
  error,
  setError,
  data,
  setData,
  handleSelectTab,
  isLoading,
  handleGroup,
  mode,
  setMode,
}: any) => {
  const [step, setStep] = useState(1)

  function validate1() {
    if (data.name < 1) {
      return setError('Group Name is required')
    }
    setStep(step + 1)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <span>
          <Button size="sm">Add Group</Button>
        </span>
      </AlertDialogTrigger>

      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <div className="h-full w-full">
          <>
            <div className="max-h-[70vh] overflow-hidden overflow-y-scroll">
              <div className="flex w-full items-center justify-between">
                <h1 className="text-lg font-semibold capitalize">
                  {mode === 'add' ? 'Add Group' : 'Update Group'}
                </h1>

                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => {
                    setOpen(false)
                    setMode('add')
                  }}
                >
                  <Cross1Icon className="h-3 w-3" />
                </Button>
              </div>

              {step === 1 && (
                <>
                  <div className="w-full space-y-3 mt-5 px-1">
                    <div className="w-full flex items-center gap-3">
                      <CustomInput
                        name="name"
                        label="Group Name"
                        value={data.name}
                        setValue={(e: any) =>
                          setData((prev: any) => {
                            return { ...prev, name: e.target.value }
                          })
                        }
                        type="text"
                        required
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                  <div className="mt-5 flex items-center">
                    {error !== '' && (
                      <div className="text-rose-600 text-sm">{error}</div>
                    )}
                    <div className="flex-1" />
                    <Button size="sm" onClick={validate1}>
                      Next
                    </Button>
                  </div>
                </>
              )}

              {step === 2 && (
                <div className="space-y-3 my-5">
                  <div className="w-full space-y-2">
                    {error !== '' && (
                      <div className="text-rose-600 text-sm">{error}</div>
                    )}
                    <Label>Accessible Tabs</Label>
                    <div className="flex gap-3 flex-wrap">
                      {tabs.map((tab: any) => (
                        <div
                          key={tab}
                          onClick={() => {
                            handleSelectTab(tab)
                          }}
                          className={cn(
                            data.tabs.includes(tab)
                              ? 'border-indigo-600'
                              : 'border',
                            'px-3 py-1 rounded-md cursor-pointer border-2 whitespace-nowrap select-none'
                          )}
                        >
                          {tab}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex items-center gap-3 justify-between pt-5">
                    <Button
                      size="sm"
                      variant="outline"
                      onClick={() => setStep(1)}
                    >
                      Back
                    </Button>
                    <Button
                      size="sm"
                      onClick={handleGroup}
                      disabled={isLoading}
                    >
                      {mode === 'add' ? 'Add Group' : 'Update Group'}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
