import Error from 'components/shared/error'
import { Link, useLocation } from 'react-router-dom'
import { IoIosReturnLeft } from 'react-icons/io'
import { TbDeviceDesktopCancel, TbFaceIdError } from 'react-icons/tb'
import { TbLicenseOff } from 'react-icons/tb'

export default function TemporarilyFailure() {
  const location = useLocation()

  return (
    <div className="grid h-screen w-screen place-content-center">
      {/* failure reason */}
      <div className="text-xs font-medium flex flex-col justify-center items-center gap-3">
        {location?.state?.reason == 'device' ? (
          <TbDeviceDesktopCancel className="text-rose-600" size={30} />
        ) : location?.state?.reason == 'license' ? (
          <TbLicenseOff className="text-rose-600" size={30} />
        ) : (
          <TbFaceIdError className="text-rose-600" size={30} />
        )}
        {location?.state?.message}
      </div>

      {/* naviagte back to login */}
      <div className="flex items-center justify-center mt-20">
        <Link
          to="/login"
          className=" flex  items-center justify-center gap-1 text-sm font-medium   px-4 py-2 rounded border  text-indigo-600 border-indigo-600 bg-white hover:text-white  hover:bg-indigo-500 hover:border-white "
        >
          <span>
            <IoIosReturnLeft size={20} />
          </span>
          Login
        </Link>
      </div>
    </div>
  )
}
