import { useEffect, useState } from 'react'
import { TrendingUp } from 'lucide-react'
import { Label, Pie, PieChart } from 'recharts'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from 'components/ui/chart'

export default function LeadsByBooking({ data }: any) {
  const { booked, show, noShow, notQualified } = data

  const chartData = [
    { browser: 'booked', visitors: booked, fill: '#2761D8' },
    { browser: 'show', visitors: show, fill: '#2DB78A' },
    { browser: 'noShow', visitors: noShow, fill: '#E78D2F' },
    { browser: 'notQualified', visitors: notQualified, fill: '#E2366F' },
  ]

  const chartConfig = {
    visitors: {
      label: 'Visitors',
    },
    booked: {
      label: 'Booked',
      color: 'hsl(var(--chart-1))',
    },
    show: {
      label: 'Show',
      color: 'hsl(var(--chart-2))',
    },
    noShow: {
      label: 'No Show',
      color: 'hsl(var(--chart-3))',
    },
    notQualified: {
      label: 'Not Qualified',
      color: 'hsl(var(--chart-4))',
    },
    other: {
      label: 'Other',
      color: 'hsl(var(--chart-5))',
    },
  } satisfies ChartConfig
  return (
    <div>
      <h1 className="font-semibold">Leads by booking</h1>
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square max-h-[250px]"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            data={chartData}
            dataKey="visitors"
            nameKey="browser"
            innerRadius={60}
            strokeWidth={5}
          >
            <Label
              content={({ viewBox }) => {
                if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="fill-foreground text-3xl font-bold"
                      ></tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                        className="fill-muted-foreground"
                      ></tspan>
                    </text>
                  )
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
      <div className="flex flex-wrap gap-2 justify-center">
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#2761D8]" />
          <span className="text-xs text-gray-500">Booked</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#2DB78A]" />
          <span className="text-xs text-gray-500">Show</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#E78D2F]" />
          <span className="text-xs text-gray-500">No Show</span>
        </div>
        <div className="flex gap-1 items-center">
          <div className="w-2 h-2 rounded-sm bg-[#E2366F]" />
          <span className="text-xs text-gray-500">Not Qualified</span>
        </div>
      </div>
    </div>
  )
}
