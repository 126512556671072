import { useEffect } from 'react'
import { store } from '../store'
import { Outlet } from 'react-router-dom'
import { branchApi } from './branch'
import { departmentApi } from './department'
import { specialistApi } from './specialist'
import { groupApi } from './group'
import { sourceApi } from './source'
import { settingsApi } from './settings'
import { leadApi } from './lead'

const Prefetch = () => {
  useEffect(() => {
    const branches = store.dispatch(
      branchApi.endpoints.getBranches.initiate(undefined)
    )
    const departments = store.dispatch(
      departmentApi.endpoints.getDepartments.initiate(undefined)
    )
    const specialists = store.dispatch(
      specialistApi.endpoints.getSpecialists.initiate(undefined)
    )
    const groups = store.dispatch(
      groupApi.endpoints.getGroups.initiate(undefined)
    )
    const sources = store.dispatch(
      sourceApi.endpoints.getSources.initiate(undefined)
    )
    const settings = store.dispatch(
      settingsApi.endpoints.getSettings.initiate(undefined)
    )
    const accessPeriod = store.dispatch(
      settingsApi.endpoints.getAccessPeriod.initiate(undefined)
    )
    const leadsCount = store.dispatch(
      leadApi.endpoints.getLeadsCount.initiate(null)
    )
    const branchesCount = store.dispatch(
      branchApi.endpoints.getBranchesCount.initiate(null)
    )
    const departmentsCount = store.dispatch(
      departmentApi.endpoints.getDepartmentsCount.initiate(null)
    )
    const specialistsCount = store.dispatch(
      specialistApi.endpoints.getSpecialistsCount.initiate(null)
    )

    return () => {
      branches.unsubscribe()
      departments.unsubscribe()
      specialists.unsubscribe()
      groups.unsubscribe()
      sources.unsubscribe()
      settings.unsubscribe()
      leadsCount.unsubscribe()
      branchesCount.unsubscribe()
      departmentsCount.unsubscribe()
      specialistsCount.unsubscribe()
      accessPeriod.unsubscribe()
    }
  }, [])

  return <Outlet />
}

export default Prefetch
