import { cn } from 'lib/utils'
import React, { useRef } from 'react'
import ChatBottomBar from './chat-bottombar'
import { AnimatePresence, motion } from 'framer-motion'
import { Avatar, AvatarImage } from 'components/ui/avatar'
import { ChatMessage } from './chat-message'

interface ChatListProps {
  messages?: any[]
  selectedUser: any
  sendMessage: (newMessage: any) => void
  sendMedia: (newMessage: any) => void
  isMobile: boolean
}

export function ChatList({
  messages,
  selectedUser,
  sendMessage,
  sendMedia,
  isMobile,
}: ChatListProps) {
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col">
      <div
        ref={messagesContainerRef}
        className="w-full overflow-y-auto overflow-x-hidden h-full flex flex-col"
      >
        <AnimatePresence>
          {messages?.map((message, index) => (
            <motion.div
              key={index}
              layout
              initial={{ opacity: 0, scale: 1, y: 50, x: 0 }}
              animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
              exit={{ opacity: 0, scale: 1, y: 1, x: 0 }}
              transition={{
                opacity: { duration: 0.1 },
                layout: {
                  type: 'spring',
                  bounce: 0.3,
                  duration: messages.indexOf(message) * 0.05 + 0.2,
                },
              }}
              style={{
                originX: 0.5,
                originY: 0.5,
              }}
              className={cn(
                'flex flex-col gap-2 p-4 whitespace-pre-wrap',
                message.senderId !== selectedUser ? 'items-end' : 'items-start'
              )}
            >
              <div className="flex gap-3 items-center">
                {message.senderId === selectedUser && (
                  <Avatar className="flex justify-center items-center">
                    <AvatarImage
                      src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                      alt="@shadcn"
                      width={6}
                      height={6}
                    />
                  </Avatar>
                )}
                <ChatMessage
                  content={message.content}
                  mediaId={message.mediaId}
                  type={message.type}
                  filename={message.filename}
                  senderName={message.sender?.firstName}
                />
                {message.senderId !== selectedUser && (
                  <Avatar className="flex justify-center items-center">
                    <AvatarImage
                      src="https://i.pinimg.com/564x/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg"
                      alt="@shadcn"
                      width={6}
                      height={6}
                    />
                  </Avatar>
                )}
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      {selectedUser && (
        <ChatBottomBar sendMessage={sendMessage} sendMedia={sendMedia} />
      )}
    </div>
  )
}
