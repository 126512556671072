import { Button } from '../ui/button'
import { Calendar } from '../ui/calendar'
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { cn } from 'lib/utils'
import dayjs from 'dayjs'
import { TbCalendar } from 'react-icons/tb'

const hours = Array.from({ length: 12 }, (_, i) => i + 1)
const minutes = Array.from({ length: 60 }, (_, i) => i)
const ampms = ['AM', 'PM']

export default function DateAndTime({
  date,
  setDate,
  hour,
  setHour,
  minute,
  setMinute,
  ampm,
  setAmpm,
  disablePast,
  disabled,
  beforeSelectedDate,
}: any) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal gap-3 items-center',
            !date && 'text-muted-foreground'
          )}
        >
          <TbCalendar size={18} />
          {date ? (
            `${dayjs(date).format('DD/MM/YYYY')} ${hour}:${
              parseInt(minute) <= 9 ? `0${minute}` : minute
            } ${ampm}`
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex gap-3 w-auto p-0" align="start">
        {/* date */}
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          disabled={
            beforeSelectedDate
              ? { before: beforeSelectedDate }
              : disablePast
                ? { before: new Date() }
                : undefined
          }
        />

        {/* time */}
        <div className="flex flex-col gap-3 p-3">
          {/* hour */}
          <Select value={hour} onValueChange={(value) => setHour(value)}>
            <SelectTrigger className="w-24">
              <SelectValue placeholder="Hours" />
            </SelectTrigger>
            <SelectContent className="h-60" position="popper">
              {hours.map((hour) => (
                <SelectItem key={hour} value={hour.toString()}>
                  {hour <= 9 ? `0${hour}` : hour}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {/* minutes */}
          <Select value={minute} onValueChange={(value) => setMinute(value)}>
            <SelectTrigger className="w-24">
              <SelectValue placeholder="Minutes" />
            </SelectTrigger>
            <SelectContent className="h-60" position="popper">
              {minutes.map((minute) => (
                <SelectItem key={minute} value={minute.toString()}>
                  {minute <= 9 ? `0${minute}` : minute}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          {/* am pm */}
          <Select value={ampm} onValueChange={(value) => setAmpm(value)}>
            <SelectTrigger className="w-24">
              <SelectValue placeholder="AM/PM" />
            </SelectTrigger>
            <SelectContent position="popper">
              {ampms.map((ampm) => (
                <SelectItem key={ampm} value={ampm}>
                  {ampm}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </PopoverContent>
    </Popover>
  )
}
