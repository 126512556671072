import { useState } from 'react'
import { Label } from '../ui/label'
import { Input } from '../ui/input'
import { TbEye, TbEyeOff } from 'react-icons/tb'

export default function PasswordInput({
  password,
  label,
  setPassword,
  disabled,
}: any) {
  const [show, setShow] = useState(false)
  return (
    <div className="space-y-2 relative">
      <Label>{label}</Label>
      <Input
        type={show ? 'text' : 'password'}
        value={password}
        onChange={(e: any) => setPassword(e.target.value)}
        required
        disabled={disabled}
      />
      <div
        className="absolute right-3 top-8 cursor-pointer"
        onClick={() => setShow(!show)}
      >
        {show ? <TbEyeOff size={18} /> : <TbEye size={18} />}
      </div>
    </div>
  )
}
