import { useGetBranchesQuery } from 'app/features/branch'
import {
  useAddDepartmentMutation,
  useGetDepartmentsQuery,
  useUpdateDepartmentMutation,
} from 'app/features/department'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import { Button } from 'components/ui/button'
import { Input } from 'components/ui/input'
import { useEffect, useState } from 'react'
import { TbEdit, TbCheck } from 'react-icons/tb'

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { cn } from 'lib/utils'
import { TbChevronDown } from 'react-icons/tb'
import { ScrollArea } from 'components/ui/scroll-area'

export default function Departments() {
  const [open, setOpen] = useState(false)
  const [error, setError] = useState('')
  const [name, setName] = useState('')
  const [selectedDepartment, setSelectedDepartment] = useState<any>(null)
  const [selectedBranch, setSelectedBranch] = useState<any>(null)
  const [mode, setMode] = useState('add')

  const {
    data: departments,
    isError: departmentIsError,
    isLoading: dataLoading,
  } = useGetDepartmentsQuery()
  const [addDepartment, { isLoading: createLoading, isError: createError }] =
    useAddDepartmentMutation()
  const [updateDepartment, { isLoading: updateLoading, isError: updateError }] =
    useUpdateDepartmentMutation()

  const {
    data: branches,
    isError: branchesIsError,
    isLoading: branchesIsLoading,
  } = useGetBranchesQuery()

  const isLoading =
    dataLoading || createLoading || updateLoading || branchesIsLoading
  const isError = departmentIsError || branchesIsError

  useEffect(() => {
    if (createError || updateError) setError('Failed to add department')
  }, [createError, updateError])

  useEffect(() => {
    setMode('add')
  }, [isLoading])

  useEffect(() => {
    if (mode === 'add') {
      setName('')
      setSelectedBranch(null)
      setSelectedDepartment(null)
      setError('')
      setMode('add')
    }
  }, [mode])

  const handleDepartment = async (e: any) => {
    e.preventDefault()
    setError('')

    if (name === '' || selectedBranch === null)
      return setError('Name and branch are required')
    if (mode === 'add')
      await addDepartment({ name, branchId: selectedBranch.id })
    else
      await updateDepartment({
        id: selectedDepartment.id,
        data: { name, branchId: selectedBranch.id },
      })
    setName('')
    setSelectedBranch(null)
    setSelectedDepartment(null)
    setMode('add')
  }

  if (isLoading)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center pt-20">
        <Error message="Server error, contact administrator please" />
      </div>
    )

  return (
    <div className="flex flex-col p-6">
      <h1 className="text-lg font-medium">Department</h1>
      <p className="text-xs text-gray-500">
        Create departments that will be used to categorize the leads
      </p>
      {/* Form */}
      {branches && branches.length < 1 && (
        <p className="my-3 text-xs font-medium text-rose-600">
          Please add a branch first
        </p>
      )}
      {branches && branches.length > 0 && (
        <form
          onSubmit={handleDepartment}
          className="mt-5 flex items-center gap-3 max-md:flex-col"
        >
          <Input
            value={name}
            onChange={(e: any) => setName(e.target.value)}
            type="text"
            placeholder="Department Name"
            disabled={isLoading || createLoading}
          />
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full justify-between"
              >
                {selectedBranch
                  ? selectedBranch.name + ' ' + selectedBranch.location
                  : 'Select Branch'}
                <TbChevronDown />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[400px] p-0">
              <Command className="overflow-y-auto ">
                <CommandInput
                  placeholder="Search branches..."
                  className="h-9"
                />
                <CommandEmpty>No data.</CommandEmpty>
                <CommandGroup>
                  <ScrollArea className="h-52 w-full">
                    {branches.map((branch) => (
                      <CommandItem
                        key={branch.id}
                        value={branch.id}
                        onSelect={(currentValue) => {
                          setSelectedBranch(branch)
                          setOpen(false)
                        }}
                        className="cursor-pointer"
                      >
                        {branch.name} - {branch.location}
                        <TbCheck
                          className={cn(
                            'ml-auto h-4 w-4',
                            selectedBranch?.id === branch.id
                              ? 'opacity-100'
                              : 'opacity-0'
                          )}
                        />
                      </CommandItem>
                    ))}
                  </ScrollArea>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <Button disabled={isLoading || createLoading} size="sm" type="submit">
            {mode === 'add' ? 'Add Department' : 'Update Department'}
          </Button>
          {mode === 'update' && (
            <Button
              size="sm"
              variant="secondary"
              onClick={() => setMode('add')}
            >
              Cancel update
            </Button>
          )}
        </form>
      )}
      {error !== '' && (
        <p className="my-3 text-xs font-medium text-rose-600">{error}</p>
      )}
      {/* List */}
      <div className="mt-5 flex flex-col gap-3">
        {departments?.map((department: any) => (
          <div
            key={department.id}
            className="flex items-center gap-2 py-2 px-3 rounded-md bg-gray-50 dark:bg-gray-800"
          >
            <div className="flex items-center gap-3">
              <div>{department.name}</div>
              {'-'}
              <div>{department.branch.name}</div>
              {'-'}
              <div>{department.branch.location}</div>
            </div>
            <div className="flex-1" />
            <div className="flex items-center gap-3">
              <Button
                size="sm"
                variant="ghost"
                onClick={() => {
                  setMode('update')
                  setName(department.name)
                  setSelectedBranch(department.branch)
                  setSelectedDepartment(department)
                }}
              >
                <TbEdit size={18} />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
