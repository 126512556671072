import { ChatMedia } from './chat-media'

interface ChatListProps {
  content: any
  type: string
  senderName?: string
  mediaId?: string
  filename?: string
}

export function ChatMessage({
  content,
  type,
  senderName,
  mediaId,
  filename,
}: ChatListProps) {
  return (
    <>
      {type === 'TEXT' && (
        <span className="bg-gray-100 text-white py-2 px-4 rounded-md max-w-xs">
          <span className="font-bold text-sm text-black">{senderName}</span>
          <span className="block mt-1 w-full text-black">{content}</span>
        </span>
      )}
      {mediaId && (
        <ChatMedia
          type={type}
          mediaId={mediaId}
          content={content}
          filename={filename}
          senderName={senderName}
        />
      )}
    </>
  )
}
