import { io, Socket } from 'socket.io-client'

export class SocketClient {
  private static instance: SocketClient
  private socket: Socket

  private constructor() {
    this.socket = io(process.env.REACT_APP_SOCKET_SERVER_URL || '')
  }

  public static getInstance(): SocketClient {
    if (!SocketClient.instance) {
      SocketClient.instance = new SocketClient()
    }
    return SocketClient.instance
  }

  public emitMessage(message: string): void {
    this.socket.emit('message', message)
  }

  public onMessage(message: string, callback: () => void): void {
    if (this.socket) {
      this.socket.on(message, callback)
    }
  }
}
