import { useGetMyCurrentSubscriptionQuery } from 'app/features/payment'
import {
  useDisableUserMutation,
  useGetPaymentActiveAgentsCountQuery,
  useActivateDowngradedUserMutation,
} from 'app/features/users'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'

export default function ActivateDowngradedUser({ user, open, setOpen }: any) {
  const [activateDowngradedUser, { isLoading }] =
    useActivateDowngradedUserMutation()

  const {
    data: myCurrentSubscription,
    isError: isMyCurrentSubscriptionError,
    error: myCurrentSubscriptionError,

    isLoading: isMyCurrentSubscriptionLoading,
  } = useGetMyCurrentSubscriptionQuery(null)

  const {
    data: paymentAgentsActiveCount,
    isError: isPaymentAgentsActiveCountError,
    error: paymentAgentsActiveCountError,

    isLoading: isPaymentAgentsActiveCountLoading,
  } = useGetPaymentActiveAgentsCountQuery(null)

  async function handleActivateDowngradedUser() {
    const paylaod = await activateDowngradedUser(user.id)
    setOpen(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change user status</AlertDialogTitle>
          <AlertDialogDescription>
            {user.isAgentDisableDueToSubscriptionPlan
              ? 'By enabling the user will be able to login to their account. you must be subscriped to a suffecient plan'
              : 'By disabling the user will not be able to login to their account.'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button variant="outline" size="sm" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant={
              user.isAgentDisableDueToSubscriptionPlan
                ? 'default'
                : 'destructive'
            }
            disabled={
              isLoading ||
              isMyCurrentSubscriptionLoading ||
              isPaymentAgentsActiveCountLoading ||
              (myCurrentSubscription?.stripeSubscriptionNumOfUsers <=
                paymentAgentsActiveCount &&
                user.isAgentDisableDueToSubscriptionPlan)
            }
            size="sm"
            onClick={handleActivateDowngradedUser}
          >
            Activate
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
