export const shouldClaimInsurance = (
  lead: any,
  numberOfInsuranceClaims: any
) => {
  const lastInsuranceClaim =
    lead?.insuranceClaims?.length > 0
      ? lead?.insuranceClaims[lead.insuranceClaims.length - 1]
      : null

  if (
    !lead.isInsuranceClaimChecked ||
    (lastInsuranceClaim && lastInsuranceClaim.status === 'Approved') ||
    lead?.insuranceClaims?.length >= numberOfInsuranceClaims!
  )
    return false
  return true
}
