import { buttonVariants } from 'components/ui/button'
import { Textarea } from 'components/ui/textarea'
import { AnimatePresence, motion } from 'framer-motion'
import { cn } from 'lib/utils'
import React, { useEffect, useRef, useState } from 'react'
import {
  TbMicrophone,
  TbPaperclip,
  TbSend,
  TbPlayerPause,
  TbVideo,
  TbPhoto,
  TbFile,
} from 'react-icons/tb'
import { Link } from 'react-router-dom'
import RecordMessage from './record-message'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'

interface ChatBottomBarProps {
  sendMessage: (newMessage: any) => void
  sendMedia: (newMessage: any) => void
}

type Record = {
  id: number
  name: string
  file: any
}

export default function ChatBottomBar({
  sendMessage,
  sendMedia,
}: ChatBottomBarProps) {
  const [message, setMessage] = useState('')
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const [isRecording, setIsRecording] = useState(false)
  const [isRecordingPaused, setIsRecordingPaused] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const canvasRef = useRef<HTMLCanvasElement>(null)
  const animationRef = useRef<any>(null)
  let recorder: MediaRecorder
  let recordingChunks: BlobPart[] = []

  const mediaRecorderRef = useRef<{
    stream: MediaStream | null
    analyser: AnalyserNode | null
    mediaRecorder: MediaRecorder | null
    audioContext: AudioContext | null
  }>({
    stream: null,
    analyser: null,
    mediaRecorder: null,
    audioContext: null,
  })

  useEffect(() => {
    if (!canvasRef.current) return

    const canvas = canvasRef.current
    const canvasCtx = canvas.getContext('2d')
    const WIDTH = canvas.width
    const HEIGHT = canvas.height

    const drawWaveform = (dataArray: Uint8Array) => {
      if (!canvasCtx) return
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
      canvasCtx.fillStyle = '#939393'

      const barWidth = 1
      const spacing = 1
      const maxBarHeight = HEIGHT / 2.5
      const numBars = Math.floor(WIDTH / (barWidth + spacing))

      for (let i = 0; i < numBars; i++) {
        const barHeight = Math.pow(dataArray[i] / 128.0, 8) * maxBarHeight
        const x = (barWidth + spacing) * i
        const y = HEIGHT / 2 - barHeight / 2
        canvasCtx.fillRect(x, y, barWidth, barHeight)
      }
    }
    const visualizeVolume = () => {
      if (
        !mediaRecorderRef.current?.stream?.getAudioTracks()[0]?.getSettings()
          .sampleRate
      )
        return
      const bufferLength =
        (mediaRecorderRef.current?.stream?.getAudioTracks()[0]?.getSettings()
          .sampleRate as number) / 100
      const dataArray = new Uint8Array(bufferLength)

      const draw = () => {
        if (!isRecording) {
          cancelAnimationFrame(animationRef.current || 0)
          return
        }
        if (isRecordingPaused) {
          return
        }
        animationRef.current = requestAnimationFrame(draw)
        mediaRecorderRef.current?.analyser?.getByteTimeDomainData(dataArray)
        drawWaveform(dataArray)
      }

      draw()
    }

    if (isRecording) {
      visualizeVolume()
    } else {
      if (canvasCtx) {
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
      }
      cancelAnimationFrame(animationRef.current || 0)
    }

    return () => {
      cancelAnimationFrame(animationRef.current || 0)
    }
  }, [isRecording, isRecordingPaused])

  const [currentRecord, setCurrentRecord] = useState<Record>({
    id: -1,
    name: '',
    file: null,
  })

  const handleFileUpload = (event: any) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    sendMedia({ file, type: 'DOCUMENT' })
  }

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    sendMedia({ file, type: 'IMAGE' })
  }

  const handleVideoUpload = (event: any) => {
    const file = event.target.files[0]
    setSelectedFile(file)
    sendMedia({ file, type: 'VIDEO' })
  }

  function startRecording() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          setIsRecording(true)
          setIsRecordingPaused(false)
          // ============ Analyzing ============
          const AudioContext = window.AudioContext
          const audioCtx = new AudioContext()
          const analyser = audioCtx.createAnalyser()
          const source = audioCtx.createMediaStreamSource(stream)
          source.connect(analyser)
          mediaRecorderRef.current = {
            stream,
            analyser,
            mediaRecorder: null,
            audioContext: audioCtx,
          }

          const mimeType = MediaRecorder.isTypeSupported('audio/mpeg')
            ? 'audio/mpeg'
            : MediaRecorder.isTypeSupported('audio/webm')
              ? 'audio/webm'
              : 'audio/wav'

          const options = { mimeType }
          mediaRecorderRef.current.mediaRecorder = new MediaRecorder(
            stream,
            options
          )
          mediaRecorderRef.current.mediaRecorder.start()
          recordingChunks = []
          // ============ Recording ============
          recorder = new MediaRecorder(stream)
          recorder.start()
          recorder.ondataavailable = (e) => {
            recordingChunks.push(e.data)
            console.log('recordingChunks ', recordingChunks)
            const recordBlob = new Blob(recordingChunks, {
              type: 'audio/ogg',
            })
            console.log('recordBlob 1', recordBlob)
            const file = new File([recordBlob], 'recorded_audio.ogg', {
              type: 'audio/ogg',
            })
            console.log('my_file 1', file)
            sendMedia({ file, type: 'AUDIO' })
          }
        })
        .catch((error) => {
          alert(error)
          console.log(error)
        })
    }
  }

  function pauseRecording() {
    const { mediaRecorder } = mediaRecorderRef.current

    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.pause()
      setIsRecordingPaused(true)
    }
  }

  function resumeRecording() {
    const { mediaRecorder } = mediaRecorderRef.current

    if (mediaRecorder && mediaRecorder.state === 'paused') {
      mediaRecorder.resume()
      setIsRecordingPaused(false)
    }
  }

  // function stopRecording() {
  //   console.log('here!!!')
  //   // try {
  //   const { mediaRecorder, stream, analyser, audioContext } =
  //     mediaRecorderRef.current

  //   if (mediaRecorder) {
  //     mediaRecorder.onstop = () => {
  //       const recordBlob = new Blob(recordingChunks, {
  //         type: 'audio/mpeg',
  //       })
  //       setCurrentRecord({
  //         ...currentRecord,
  //         file: window.URL.createObjectURL(recordBlob),
  //       })
  //       recordingChunks = []
  //       console.log('recordBlob ', recordingChunks)

  //       // sendMedia({ recordBlob, type: 'AUDIO' })
  //     }
  //     mediaRecorder.stop()
  //   }

  //   setIsRecording(false)
  //   setIsRecordingPaused(false)
  // }

  function stopRecording() {
    const { mediaRecorder, stream, analyser, audioContext } =
      mediaRecorderRef.current
    if (mediaRecorder && stream) {
      mediaRecorder.stop()

      const audioTracks = mediaRecorder.stream.getAudioTracks()
      audioTracks.forEach((track) => {
        track.stop()
      })

      const blob = new Blob(recordingChunks, { type: 'audio/webm' })
      const url = URL.createObjectURL(blob)

      // Send the recorded audio data (blob or url) to the desired destination
      console.log('recordBlob ', recordingChunks)
      setIsRecording(false)
      setIsRecordingPaused(false)
    }
  }

  function resetRecording() {
    const { mediaRecorder, stream, analyser, audioContext } =
      mediaRecorderRef.current

    if (mediaRecorder) {
      mediaRecorder.onstop = () => {
        recordingChunks = []
      }
      mediaRecorder.stop()
    } else {
      alert('recorder instance is null!')
    }

    // Stop the web audio context and the analyser node
    if (analyser) {
      analyser.disconnect()
    }
    if (stream) {
      stream.getTracks().forEach((track) => track.stop())
    }
    if (audioContext) {
      audioContext.close()
    }
    setIsRecording(false)
    setIsRecordingPaused(false)

    // Clear the animation frame and canvas
    cancelAnimationFrame(animationRef.current || 0)
    const canvas = canvasRef.current
    if (canvas) {
      const canvasCtx = canvas.getContext('2d')
      if (canvasCtx) {
        const WIDTH = canvas.width
        const HEIGHT = canvas.height
        canvasCtx.clearRect(0, 0, WIDTH, HEIGHT)
      }
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value)
  }

  const handleSend = () => {
    if (message.trim()) {
      const newMessage = message.trim()
      sendMessage(newMessage)
      setMessage('')

      if (inputRef.current) {
        inputRef.current.focus()
      }
    } else if (isRecording) {
      stopRecording()
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSend()
    }

    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault()
      setMessage((prev) => prev + '\n')
    }
  }

  return (
    <div className="p-2 flex justify-between w-full items-center gap-2">
      <div className="flex">
        {/* {isRecording && (
          <Link
            to="#"
            onClick={() => {}}
            className={cn(
              buttonVariants({
                variant: 'ghost',
                size: 'icon',
              }),
              'h-9 w-9',
              'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
            )}
          >
            {!isRecordingPaused ? (
              <TbPlayerPause
                size={20}
                className="text-muted-foreground"
                onClick={pauseRecording}
              />
            ) : (
              <TbMicrophone
                size={20}
                className="text-muted-foreground"
                onClick={resumeRecording}
              />
            )}
          </Link>
        )} */}
        <Popover>
          <PopoverTrigger asChild>
            <Link
              to="#"
              className={cn(
                buttonVariants({ variant: 'ghost', size: 'icon' }),
                'h-9 w-9',
                'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
              )}
            >
              <TbPaperclip size={20} className="text-muted-foreground" />
            </Link>
          </PopoverTrigger>
          <PopoverContent side="top" className="w-full p-4">
            <div className="flex gap-5">
              <label
                htmlFor="imageUpload"
                className={cn(
                  buttonVariants({ variant: 'ghost', size: 'icon' }),
                  'h-9 w-9',
                  'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
                )}
              >
                <TbPhoto size={20} className="text-muted-foreground" />
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleImageUpload}
                />
              </label>
              <label
                htmlFor="videoUpload"
                className={cn(
                  buttonVariants({ variant: 'ghost', size: 'icon' }),
                  'h-9 w-9',
                  'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
                )}
              >
                <TbVideo size={20} className="text-muted-foreground" />
                <input
                  type="file"
                  accept="video/*"
                  id="videoUpload"
                  style={{ display: 'none' }}
                  onChange={handleVideoUpload}
                />
              </label>
              <label
                htmlFor="fileUpload"
                className={cn(
                  buttonVariants({ variant: 'ghost', size: 'icon' }),
                  'h-9 w-9',
                  'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
                )}
              >
                <TbFile size={20} className="text-muted-foreground" />
                <input
                  type="file"
                  id="fileUpload"
                  style={{ display: 'none' }}
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          </PopoverContent>
        </Popover>
      </div>
      <AnimatePresence initial={false}>
        <motion.div
          key="input"
          className="w-full relative"
          layout
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1 }}
          transition={{
            opacity: { duration: 0.05 },
            layout: {
              type: 'spring',
              bounce: 0.15,
            },
          }}
        >
          {isRecording ? (
            <RecordMessage
              isRecording={isRecording}
              isRecordingPaused={isRecordingPaused}
              resetRecording={resetRecording}
              canvasRef={canvasRef}
            />
          ) : (
            <Textarea
              autoComplete="off"
              value={message}
              ref={inputRef}
              onKeyDown={handleKeyPress}
              onChange={handleInputChange}
              name="message"
              placeholder="Aa"
              className="w-full border-0 rounded-lg flex items-center h-10 resize-none overflow-hidden bg-gray-200 px-3 py-2 text-gray-700"
            ></Textarea>
          )}
        </motion.div>
        {message.trim() || isRecording ? (
          <Link
            to="#"
            className={cn(
              buttonVariants({ variant: 'ghost', size: 'icon' }),
              'h-9 w-9',
              'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white shrink-0'
            )}
            onClick={handleSend}
          >
            <TbSend size={20} className="text-muted-foreground" />
          </Link>
        ) : (
          <TbMicrophone
            size={20}
            className="text-muted-foreground"
            onClick={startRecording}
          />
        )}
      </AnimatePresence>
    </div>
  )
}
