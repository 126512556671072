import { useState, useEffect } from 'react'
import { Button } from 'components/ui/button'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useVerifyMutation } from 'app/features/auth'
import { setCredentials } from 'app/slices/user'
import PasswordInput from 'components/auth/password-input'
const regex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,}$/

export default function Verify({ byToken }: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [error, setError] = useState<string | undefined>('')
  const { verifyUser } = useSelector((state: any) => state.user)
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const [verify, { isLoading }] = useVerifyMutation()

  useEffect(() => {
    if (verifyUser && verifyUser.verified && verifyUser.role === 'ADMIN') {
      navigate('/dashboard')
    } else if (
      verifyUser &&
      verifyUser.verified &&
      verifyUser.role === 'AGENT'
    ) {
      navigate('/leads')
    }
  }, [verifyUser])

  const handleVerify = async (e: any) => {
    e.preventDefault()
    setError('')

    if (regex.test(newPassword) === false)
      return setError(
        'Password must be at least 8 characters long, contain at least one number and have a mixture of uppercase and lowercase letters'
      )

    if (newPassword !== confirmNewPassword)
      return setError("Confirm password doesn't match New password ")

    try {
      const { user: returnedUser, token: accessToken } = await verify({
        ...(!byToken && { email: verifyUser?.email?.trim()?.toLowerCase() }),
        ...(byToken && { token: params.token }),
        password: newPassword,
      }).unwrap()
      dispatch(setCredentials({ user: returnedUser, token: accessToken }))
      navigate('/login')
    } catch (error) {
      return setError('Error verifying account in, try again later')
    }
  }

  return (
    <div className="grid h-screen w-screen place-content-center">
      <div className="w-96">
        <h1 className="text-lg font-semibold">Confirm password</h1>
        <p className="my-3 text-foreground text-xs">
          Please enter a new password to continue, your password must be at
          least 8 characters long, contain at least one number and have a
          mixture of uppercase and lowercase letters and special characters.
        </p>
      </div>
      <div className="rounded-md border p-3 bg-white dark:bg-gray-900 shadow-sm mt-3">
        <form className="w-96 space-y-3" onSubmit={handleVerify}>
          {error !== '' && (
            <div className="my-1 text-center text-sm font-medium text-rose-600">
              {error}
            </div>
          )}
          <PasswordInput
            label="Password"
            password={newPassword}
            setPassword={setNewPassword}
            disabled={isLoading}
          />
          <PasswordInput
            label="Confirm Password"
            password={confirmNewPassword}
            setPassword={setConfirmNewPassword}
            disabled={isLoading}
          />
          <div className="pt-3">
            <Button disabled={isLoading} className="w-full text-white">
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
