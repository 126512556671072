import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'

const hours = Array.from({ length: 12 }, (_, i) => i + 1)
const minutes = Array.from({ length: 60 }, (_, i) => i)
const ampms = ['AM', 'PM']

export default function TimeSelect({
  hour,
  setHour,
  minute,
  setMinute,
  ampm,
  setAmpm,
}: any) {
  return (
    <div className="flex items-center gap-3 py-3">
      {/* hour */}
      <Select value={hour} onValueChange={(value) => setHour(value)}>
        <SelectTrigger className="w-24">
          <SelectValue placeholder="Hours" />
        </SelectTrigger>
        <SelectContent className="h-60" position="popper">
          {hours.map((hour) => (
            <SelectItem key={hour} value={hour.toString()}>
              {hour <= 9 ? `0${hour}` : hour}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {/* minutes */}
      <Select value={minute} onValueChange={(value) => setMinute(value)}>
        <SelectTrigger className="w-24">
          <SelectValue placeholder="Minutes" />
        </SelectTrigger>
        <SelectContent className="h-60" position="popper">
          {minutes.map((minute) => (
            <SelectItem key={minute} value={minute.toString()}>
              {minute <= 9 ? `0${minute}` : minute}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {/* am pm */}
      <Select value={ampm} onValueChange={(value) => setAmpm(value)}>
        <SelectTrigger className="w-24">
          <SelectValue placeholder="AM/PM" />
        </SelectTrigger>
        <SelectContent position="popper">
          {ampms.map((ampm) => (
            <SelectItem key={ampm} value={ampm}>
              {ampm}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  )
}
