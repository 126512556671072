import { useEffect, useState } from 'react'
import LeadInterest from './lead-interest'
import LeadInfo from './lead-info'
import { Button } from 'components/ui/button'
import {
  useUpdateLeadMutation,
  useGetLeadQuery,
  useAddLeadMutation,
} from 'app/features/lead'
import { VscFilePdf } from 'react-icons/vsc'
import { isEmail, isPhone } from 'lib/validation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Loader from 'components/shared/loader'
import Error from 'components/shared/error'
import useApp from 'hooks/useApp'
import FollowUp from 'pages/leads/components/follow-up'
import Book from 'pages/leads/components/book'
import { shouldFollowUp } from 'lib/follow-up'
import NotQualified from 'pages/leads/components/not-qualified'
import Status from 'pages/leads/components/status'
import UpdateBook from 'pages/leads/components/update-book'
import ClaimInsurance from 'pages/leads/components/claim-insurance'
import { shouldClaimInsurance } from 'lib/claim-insurance'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { HiOutlineDuplicate } from 'react-icons/hi'
import { LeadInfoReport } from './lead-info-report'
import { useSelector } from 'react-redux'
import { useToast } from 'components/ui/use-toast'
import { shouldBook } from 'lib/book'

const initialLead = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  alternativePhone: '',
  userId: '',
  sourceId: '',
  branchIDs: [],
  departmentIDs: [],
  specialistIDs: [],
  interest: '',
  nationality: '',
  gender: null,
  status: '',
  notQualifiedReason: '',
}

export default function Lead() {
  const { user } = useSelector((state: any) => state.user)
  const navigate = useNavigate()
  const { toast } = useToast()
  const location = useLocation()
  const isDetailsPage = location.pathname.includes('details')
  const { settings, branches, departments, specialists } = useApp()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>('')
  const [openFollowUp, setOpenFollowUp] = useState(false)
  const [openBook, setOpenBook] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [openUpdateBook, setOpenUpdateBook] = useState(false)
  const [openNotQualified, setOpenNotQualified] = useState(false)
  const [openClaimInsurance, setOpenClaimInsurance] = useState(false)
  const numberOfFollowUps = settings[0].numberOfFollowUps
  const numberOfInsuranceClaims = settings[0].numberOfInsuranceClaims
  const timeGap = settings[0].followUpTimeGap

  const [
    updateLead,
    { isLoading, isError: isUpdateError, error: updateError },
  ] = useUpdateLeadMutation()
  const {
    data: lead,
    isLoading: loadingLead,
    isError: isErrorLead,
    error: errorLead,
  } = useGetLeadQuery(id)
  const [data, setData] = useState<any>(initialLead)

  useEffect(() => {
    if (lead) setData(lead)
  }, [lead])

  const handleChange = (e: { target: { id: any; value: any } }) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  function addBranch(id: any) {
    if (data.branchIDs.includes(id)) {
      const newBranches = data.branchIDs.filter((branch: any) => branch !== id)
      setData({ ...data, branchIDs: newBranches })
    } else {
      setData({ ...data, branchIDs: [...data.branchIDs, id] })
    }
  }

  function addDepartment(id: any) {
    if (data.departmentIDs.includes(id)) {
      const newDepartments = data.departmentIDs.filter(
        (department: any) => department !== id
      )
      setData({ ...data, departmentIDs: newDepartments })
    } else {
      setData({ ...data, departmentIDs: [...data.departmentIDs, id] })
    }
  }

  function addSpecialist(id: any) {
    if (data.specialistIDs.includes(id)) {
      const newSpecialists = data.specialistIDs.filter(
        (specialist: any) => specialist !== id
      )
      setData({ ...data, specialistIDs: newSpecialists })
    } else {
      setData({ ...data, specialistIDs: [...data.specialistIDs, id] })
    }
  }

  async function editLead() {
    setError('')
    if (data.firstName.length < 1) {
      return setError('First Name is required')
    }

    if (data.email && !isEmail(data.email)) {
      return setError('Valid email is required')
    }

    if (!isPhone(data.phone)) {
      return setError('Valid phone is required')
    }
    if (data.alternativePhone && !isPhone(data.alternativePhone)) {
      return setError('Valid Alternative phone is required')
    }
    if (!isPhone(data.whatsappNumber)) {
      return setError('Valid whatsapp number is required')
    }
    if (data.sourceId === '') {
      return setError('Source is required')
    }

    setLoading(true)
    delete data.id
    const result: any = await updateLead({
      id: lead.id,
      data,
    })
    if (result?.error?.status === 409) {
      setLoading(false)
      return setError('Lead already exists')
    }
    if (result?.error) {
      setLoading(false)
      return setError('Server Error, Lead Not Updated')
    }

    setLoading(false)
    if (result?.data) navigate(`/lead-details/${lead.id}`)
  }

  const [
    addLead,
    { isLoading: isDuplicateLoading, isError: isDuplicateError },
  ] = useAddLeadMutation()

  async function duplicateLead() {
    const {
      firstName,
      lastName,
      email,
      phone,
      whatsappNumber,
      alternativePhone,
      nationality,
      gender,
      sourceId,
      branchIDs,
      departmentIDs,
      specialistIDs,
    } = lead
    const result: any = await addLead({
      firstName,
      lastName,
      email,
      phone,
      whatsappNumber,
      alternativePhone,
      nationality,
      gender,
      sourceId,
      branchIDs,
      departmentIDs,
      specialistIDs,
      status: 'NEW',
      userId: user.id,
    })
    if (result?.error) {
      toast({
        title: 'Error',
        description: 'Failed to duplicate lead',
      })
      return
    }

    const newLead = result?.data

    navigate(`/lead-details/${newLead.id}`)
  }

  return (
    <div className="flex-1 h-full bg-white border rounded-md overflow-hidden overflow-y-scroll max-md:pb-10">
      <div className="flex flex-col p-6 gap-3">
        <div className="py-3 items-center gap-3">
          <div className="flex flex-col gap-1">
            {(isLoading || loadingLead || loading) && (
              <div className="h-screen grid place-content-center">
                <Loader />
              </div>
            )}
            {(isErrorLead || isUpdateError) && (
              <div className="h-screen grid place-content-center">
                <Error
                  message={
                    errorLead?.status == 404
                      ? 'Lead Not Found'
                      : 'Server error, contact administrator please'
                  }
                />
              </div>
            )}
            {lead && !isLoading && !loadingLead && !loading && (
              <>
                {isDetailsPage ? (
                  <div className="pt-5 flex items-center justify-between">
                    <h1 className="text-lg font-medium flex items-center gap-1">
                      Lead Info
                      <RadixTooltip.Provider>
                        <RadixTooltip.Root delayDuration={500}>
                          <RadixTooltip.Trigger asChild>
                            <Button variant={'ghost'} className="w-10">
                              <PDFDownloadLink
                                document={
                                  <LeadInfoReport
                                    lead={lead}
                                    clinicDescription={
                                      settings ? settings[0]?.description : ''
                                    }
                                  />
                                }
                                fileName="report.pdf"
                              >
                                {({ loading }) =>
                                  true && <VscFilePdf size={22} className="" />
                                }
                              </PDFDownloadLink>
                            </Button>
                          </RadixTooltip.Trigger>
                          <RadixTooltip.Portal>
                            <RadixTooltip.Content
                              className="  px-4 py-2  text-indigo-700 rounded-lg border bg-white shadow-sm shadow-gray-300 text-sm mr-10 z-[100]"
                              sideOffset={5}
                            >
                              Share {lead.firstName} {lead.lastName} Info as a
                              pdf
                              <RadixTooltip.Arrow className="TooltipArrow" />
                            </RadixTooltip.Content>
                          </RadixTooltip.Portal>
                        </RadixTooltip.Root>
                      </RadixTooltip.Provider>
                      <RadixTooltip.Provider>
                        <RadixTooltip.Root delayDuration={500}>
                          <RadixTooltip.Trigger asChild>
                            <Button
                              variant={'ghost'}
                              className="w-10"
                              onClick={duplicateLead}
                              disabled={isDuplicateLoading}
                            >
                              <span>
                                <HiOutlineDuplicate size={22} />
                              </span>
                            </Button>
                          </RadixTooltip.Trigger>

                          <RadixTooltip.Portal>
                            <RadixTooltip.Content
                              className="  px-4 py-2  text-indigo-700 rounded-lg border bg-white shadow-sm shadow-gray-300 text-sm mr-10 z-[100]"
                              sideOffset={5}
                            >
                              duplicate
                              <RadixTooltip.Arrow className="TooltipArrow" />
                            </RadixTooltip.Content>
                          </RadixTooltip.Portal>
                        </RadixTooltip.Root>
                      </RadixTooltip.Provider>
                    </h1>
                    <Button
                      variant="secondary"
                      onClick={() => navigate(`/update-lead/${id}`)}
                    >
                      Edit
                    </Button>
                  </div>
                ) : (
                  <div className="pt-5 flex items-center justify-between">
                    <h1 className="text-lg font-medium">Update Lead</h1>
                  </div>
                )}

                <div className="flex justify-end">
                  <LeadInfo
                    data={data}
                    setData={setData}
                    handleChange={handleChange}
                    isDetailsPage={isDetailsPage}
                  />
                </div>

                <div className="flex-1 mt-10 border-t border-dashed" />
                {(!isDetailsPage ||
                  (isDetailsPage && lead?.branches?.length > 0)) && (
                  <div className="flex justify-end">
                    <LeadInterest
                      data={data}
                      setData={setData}
                      addBranch={addBranch}
                      addDepartment={addDepartment}
                      addSpecialist={addSpecialist}
                      isDetailsPage={isDetailsPage}
                      branches={isDetailsPage ? lead?.branches : branches}
                      departments={
                        isDetailsPage ? lead?.departments : departments
                      }
                      specialists={
                        isDetailsPage ? lead?.specialists : specialists
                      }
                    />
                  </div>
                )}
                <div className="flex-1" />
                {lead && isDetailsPage ? (
                  <div className="flex justify-center space-x-6">
                    {shouldFollowUp(
                      lead,
                      lead?.followUps[lead.followUps.length - 1],
                      numberOfFollowUps,
                      timeGap,
                      numberOfInsuranceClaims
                    ) && (
                      <Button
                        className="bg-teal-600"
                        onClick={() => setOpenFollowUp(true)}
                      >
                        Follow Up
                      </Button>
                    )}

                    {lead?.status != 'BOOKED' ? (
                      <>
                        {shouldBook(lead, numberOfInsuranceClaims) ? (
                          <Button onClick={() => setOpenBook(true)}>
                            Book
                          </Button>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      <>
                        <Button onClick={() => setOpenStatus(true)}>
                          Update Status
                        </Button>
                        <Button onClick={() => setOpenUpdateBook(true)}>
                          Update Book
                        </Button>
                      </>
                    )}

                    {lead?.status != 'NOT_QUALIFIED' && (
                      <Button
                        variant="destructive"
                        onClick={() => setOpenNotQualified(true)}
                      >
                        Not qualified
                      </Button>
                    )}
                    {shouldClaimInsurance(lead, numberOfInsuranceClaims) && (
                      <Button
                        className="bg-teal-600"
                        onClick={() => setOpenClaimInsurance(true)}
                      >
                        Insurance Claim
                      </Button>
                    )}
                  </div>
                ) : (
                  <div className="pr-5 text-right">
                    <Button onClick={editLead}>Update</Button>
                    {error && (
                      <p className="text-red-500 text-xs mt-2">{error}</p>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {lead && openFollowUp && (
        <FollowUp
          status={shouldFollowUp(
            lead,
            lead?.followUps[lead.followUps.length - 1],
            numberOfFollowUps,
            timeGap,
            numberOfInsuranceClaims
          )}
          leadId={lead.id}
          open={openFollowUp}
          setOpen={setOpenFollowUp}
          openBook={setOpenBook}
        />
      )}
      {lead && openBook && (
        <Book leadId={lead?.id} open={openBook} setOpen={setOpenBook} />
      )}
      {lead && openStatus && (
        <Status lead={lead} open={openStatus} setOpen={setOpenStatus} />
      )}
      {lead && openUpdateBook && (
        <UpdateBook
          leadId={lead?.id}
          open={openUpdateBook}
          setOpen={setOpenUpdateBook}
        />
      )}
      {lead && openNotQualified && (
        <NotQualified
          lead={lead}
          open={openNotQualified}
          setOpen={setOpenNotQualified}
        />
      )}
      {lead && openClaimInsurance && (
        <ClaimInsurance
          leadId={lead.id}
          open={openClaimInsurance}
          setOpen={setOpenClaimInsurance}
        />
      )}
    </div>
  )
}
