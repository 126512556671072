import React from 'react'

export const Timer = React.memo(
  ({
    hourLeft,
    hourRight,
    minuteLeft,
    minuteRight,
    secondLeft,
    secondRight,
  }: {
    hourLeft: string
    hourRight: string
    minuteLeft: string
    minuteRight: string
    secondLeft: string
    secondRight: string
  }) => {
    return (
      <div className="items-center -top-12 left-0 justify-center gap-0.5 border p-1.5 rounded-md font-mono font-medium text-foreground flex">
        <span className="rounded-md bg-background p-0.5 text-foreground">
          {hourLeft}
        </span>
        <span className="rounded-md bg-background p-0.5 text-foreground">
          {hourRight}
        </span>
        <span>:</span>
        <span className="rounded-md bg-background p-0.5 text-foreground">
          {minuteLeft}
        </span>
        <span className="rounded-md bg-background p-0.5 text-foreground">
          {minuteRight}
        </span>
        <span>:</span>
        <span className="rounded-md bg-background p-0.5 text-foreground">
          {secondLeft}
        </span>
        <span className="rounded-md bg-background p-0.5 text-foreground ">
          {secondRight}
        </span>
      </div>
    )
  }
)
Timer.displayName = 'Timer'
