import { Toaster } from '../ui/toaster'
import { Outlet } from 'react-router-dom'
import Sidebar from './sidebar'
import Header from './header'
import useApp from 'hooks/useApp'
import Loader from './loader'
import { ScrollArea } from '../ui/scroll-area'

export default function Layout() {
  const { isAppLoading } = useApp()
  if (isAppLoading)
    return (
      <div className="w-screen h-screen grid place-content-center">
        <Loader />
      </div>
    )
  return (
    <div className="grid h-screen w-full md:grid-cols-[200px_1fr] lg:grid-cols-[230px_1fr] ">
      <Sidebar />
      <div className="min-h-screen overflow-scroll w-full">
        <Header />
        <Outlet />
        <Toaster />
      </div>
    </div>
  )
}
