import { api } from '../api'

type CreateSubscription = {
  priceId: string
  hasFreeTrial?: boolean
  endTrial?: boolean
}
type ConfirmPaymentIntent = {
  stripePaymentMethodId: string
  stripePaymentIntentId: string
}

type addPaymentMethod = {
  cardCountry: string
  cardBrand: string
  cardDisplayBrand: string
  cardLast4: string
  cardExpMonth: number
  cardExpYear: number
  stripePaymentMethodId: string
  mode: 'add' | 'replace' | 'add-default'
  defaultPaymentMethodId?: string | null
}
type addPaymentMethodByConfirmSetupIntent = {
  stripePaymentMethodId: string
}

export const paymentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    reset: builder.mutation({
      query: (args: void) => ({
        url: '/payments/all',
        method: 'DELETE',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    /* this function related to super admin dashboard*/
    createCustomSubscription: builder.mutation({
      query: (data: any) => ({
        url: '/payments/custom-subscription',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    /* this function related to super admin dashboard*/
    updateCustomSubscription: builder.mutation({
      query: (data: any) => ({
        url: '/payments/custom-subscription',
        method: 'PUT',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    /* this function related to super admin dashboard*/
    getCustomSubscription: builder.query<any, any>({
      query: (clinicId: string) => `payments/custom-subscription/${clinicId}`,
      providesTags: () => ['Subscriptions'],
    }),

    /* this function related to super admin dashboard*/
    toggleSubscriptionCancellationStatusBySuperAdmin: builder.mutation({
      query: (data: any) => ({
        url: '/payments/toggle-subscription-cancellation-status-by-super-admin',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    /* this function related to super admin dashboard*/
    deleteSubscriptionBySuperAdmin: builder.mutation({
      query: (data: any) => ({
        url: '/payments/delete-subscription-by-super-admin',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    createStripeSubscription: builder.mutation({
      query: (data: CreateSubscription) => ({
        url: '/payments/create-subscription',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    endFreeTrial: builder.mutation({
      query: (args: void) => ({
        url: '/payments/end-free-trial',
        method: 'POST',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    confirmPaymentIntent: builder.mutation({
      query: (data: ConfirmPaymentIntent) => ({
        url: '/payments/confirm-payment-intent',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    upgradeStripeSubscription: builder.mutation({
      query: (data: CreateSubscription) => ({
        url: '/payments/upgrade-subscription',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    downgradeStripeSubscription: builder.mutation({
      query: (data: CreateSubscription) => ({
        url: '/payments/downgrade-subscription',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    cancelStripeDowngrade: builder.mutation({
      query: () => ({
        url: '/payments/cancel-downgrade',
        method: 'POST',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    toggleSubscriptionCancellationStatus: builder.mutation({
      query: (args: void) => ({
        url: '/payments/toggle-subscription-cancellation-status',
        method: 'POST',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    deleteSubscription: builder.mutation({
      query: (args: void) => ({
        url: '/payments/delete-subscription',
        method: 'POST',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    refreshMyCurrentSubscriptionStatus: builder.mutation({
      query: (args: void) => ({
        url: '/payments/refresh-my-current-subscription-status',
        method: 'POST',
      }),
    }),
    refreshMyCurrentSubscription: builder.mutation({
      query: (args: void) => ({
        url: '/payments/refresh-my-current-subscription',
        method: 'POST',
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    getPrices: builder.query<any, any>({
      query: (recurring: string) =>
        recurring
          ? `payments/prices?recurring=${recurring}`
          : `payments/prices`,
      providesTags: () => ['Subscriptions'],
    }),
    getMyCurrentSubscription: builder.query<any, any>({
      query: () => 'payments/my-current-subscription',
      providesTags: () => ['Subscriptions'],
    }),
    getPreviewUpgradedSubscription: builder.query<any, any>({
      query: (priceId: string) => ({
        url: `payments/preview-upgraded-subscription/${priceId}`,
      }),
      providesTags: () => ['Subscriptions'],
    }),

    getMyCurrentInvoice: builder.query({
      query: () => 'payments/my-current-invoice',
      providesTags: ['Subscriptions'],
    }),
    getMyCurrentPaymentIntent: builder.query({
      query: () => 'payments/my-current-payment-intent',
      providesTags: ['Subscriptions'],
    }),
    reviewUpcomingInvoice: builder.query({
      query: () => 'payments/review-upcoming-invoice',
      providesTags: ['Subscriptions'],
    }),
    getInvoices: builder.query<any, any>({
      query: ({ skip, take }) => ({
        url: `payments/invoices?skip=${skip}&take=${take}`,
      }),
      providesTags: ['Subscriptions'],
    }),
    addPaymentMethod: builder.mutation({
      query: (data: addPaymentMethod) => ({
        url: '/payments/payment-methods',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    addPaymentMethodByConfirmSetupIntent: builder.mutation({
      query: (data: addPaymentMethodByConfirmSetupIntent) => ({
        url: '/payments/payment-methods/by-confirm-setup-intent',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    // TODO:
    // CHANGE TO PUT
    setDefaultPaymentMethod: builder.mutation({
      query: (data: { paymentMethodId: string; handlePending: boolean }) => ({
        url: '/payments/set-default-payment-method',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    togglePaymentMethodActivationStatus: builder.mutation({
      query: (data: { paymentMethodId: string }) => ({
        url: '/payments/toggle-payment-method-activation-status',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),

    // TODO:
    // CHANGE TO DELETE

    removePaymentMethod: builder.mutation({
      query: (data: { paymentMethodId: string }) => ({
        url: '/payments/remove-payment-method',
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: ['Subscriptions'],
    }),
    getPaymentMethods: builder.query<any, any>({
      query: () => ({
        url: `payments/payment-methods`,
      }),
      providesTags: ['Subscriptions'],
    }),

    getMySubscriptions: builder.query({
      query: () => 'payments/my-subscriptions',
      providesTags: ['Subscriptions'],
    }),
  }),
})

export const {
  useCreateStripeSubscriptionMutation,
  useGetMyCurrentInvoiceQuery,
  useGetMyCurrentPaymentIntentQuery,
  useGetMyCurrentSubscriptionQuery,
  useGetMySubscriptionsQuery,
  useReviewUpcomingInvoiceQuery,
  useToggleSubscriptionCancellationStatusMutation,
  useRefreshMyCurrentSubscriptionStatusMutation,
  useGetPricesQuery,
  useGetPreviewUpgradedSubscriptionQuery,
  useUpgradeStripeSubscriptionMutation,
  useRefreshMyCurrentSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useGetInvoicesQuery,
  useGetPaymentMethodsQuery,
  useAddPaymentMethodMutation,
  useRemovePaymentMethodMutation,
  useSetDefaultPaymentMethodMutation,
  useTogglePaymentMethodActivationStatusMutation,
  useDowngradeStripeSubscriptionMutation,
  useCancelStripeDowngradeMutation,
  useAddPaymentMethodByConfirmSetupIntentMutation,
  useConfirmPaymentIntentMutation,
  useEndFreeTrialMutation,
  useResetMutation,
  useCreateCustomSubscriptionMutation,
  useUpdateCustomSubscriptionMutation,
  useGetCustomSubscriptionQuery,
  useToggleSubscriptionCancellationStatusBySuperAdminMutation,
  useDeleteSubscriptionBySuperAdminMutation,
} = paymentApi
