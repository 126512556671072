import { useGetAllLeadsQuery } from 'app/features/lead'
import Error from 'components/shared/error'
import Loader from 'components/shared/loader'
import useApp from 'hooks/useApp'
import LeadsByInsuranceClaim from './leads-by-insurance-claim'

export default function InsurancePortal() {
  const { filters } = useApp()
  const { data: leads, isError, isLoading } = useGetAllLeadsQuery(filters)

  if (isLoading)
    return (
      <div className="w-full h-screen grid place-content-center">
        <Loader />
      </div>
    )
  if (isError)
    return (
      <div className="w-full h-full grid place-content-center">
        <Error message="Server error, contact administrator please" />
      </div>
    )
  return (
    <div className="flex flex-col p-3 h-screen overflow-hidden overflow-y-scroll">
      <div className="flex flex-col gap-3">
        {leads && leads.length < 1 && (
          <div className="w-full grid place-content-center text-center h-80">
            <h1 className="font-medium">No leads</h1>
            <p>Start by adding leads</p>
          </div>
        )}
        {leads && leads.length > 0 && (
          <LeadsByInsuranceClaim
            leads={leads?.filter(
              (lead: any) =>
                lead.isInsuranceClaimChecked &&
                (!lead.insuranceClaims.length ||
                  lead.insuranceClaims[lead.insuranceClaims.length - 1]
                    .status != 'Approved')
            )}
          />
        )}
      </div>
    </div>
  )
}
