import { useState } from 'react'
import { Button } from 'components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from 'components/ui/dropdown-menu'
import { TbDotsVertical } from 'react-icons/tb'
import Book from './book'
import FollowUp from './follow-up'
import EditLead from '../edit-lead'
import Status from './status'
import useApp from 'hooks/useApp'
import { TbEdit, TbTextPlus, TbClipboardList } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { shouldFollowUp } from 'lib/follow-up'
import UpdateBook from './update-book'
import { shouldClaimInsurance } from 'lib/claim-insurance'
import ClaimInsurance from './claim-insurance'
import { IoShieldCheckmarkSharp } from 'react-icons/io5'
import { shouldBook } from 'lib/book'

export function RowAction({ lead, status }: any) {
  const { settings } = useApp()
  const numberOfFollowUps = settings[0].numberOfFollowUps
  const numberOfInsuranceClaims = settings[0].numberOfInsuranceClaims
  const timeGap = settings[0].followUpTimeGap
  const navigate = useNavigate()
  const [selectedLead, setSelectedLead] = useState<any>(null)
  const [openFollowUp, setOpenFollowUp] = useState(false)
  const [openBook, setOpenBook] = useState(false)
  const [openStatus, setOpenStatus] = useState(false)
  const [openUpdateBook, setOpenUpdateBook] = useState(false)
  const [openClaimInsurance, setOpenClaimInsurance] = useState(false)
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted relative"
          >
            <TbDotsVertical size={18} />
            {status && (
              <span className="absolute top-1/2 -right-2.5 -translate-y-1/2 flex h-1 w-1">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-rose-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-1 w-1 bg-rose-500"></span>
              </span>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[160px]">
          <DropdownMenuItem
            onClick={() => {
              setSelectedLead(lead)
              setOpenFollowUp(true)
            }}
            disabled={
              lead?.followUps?.length >= numberOfFollowUps ||
              !status ||
              !shouldFollowUp(
                lead,
                lead?.followUps[lead.followUps.length - 1],
                numberOfFollowUps,
                timeGap,
                numberOfInsuranceClaims
              )
            }
          >
            <div className="flex gap-3 items-center">
              <TbClipboardList className="text-gray-500" size={15} />
              <span>Follow Up</span>
            </div>
          </DropdownMenuItem>

          {lead.status === 'BOOKED' ? (
            <>
              <DropdownMenuItem
                onClick={() => {
                  setSelectedLead(lead)
                  setOpenStatus(true)
                }}
              >
                <div className="flex gap-3 items-center">
                  <TbTextPlus className="text-gray-500" size={15} />
                  <span>Update Status</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => {
                  setSelectedLead(lead)
                  setOpenUpdateBook(true)
                }}
              >
                <div className="flex gap-3 items-center">
                  <TbTextPlus className="text-gray-500" size={15} />
                  <span>Update Book</span>
                </div>
              </DropdownMenuItem>
            </>
          ) : (
            <DropdownMenuItem
              onClick={() => {
                setSelectedLead(lead)
                setOpenBook(true)
              }}
              disabled={!shouldBook(lead, numberOfInsuranceClaims)}
            >
              <div className="flex gap-3 items-center">
                <TbTextPlus className="text-gray-500" size={15} />
                <span>Book</span>
              </div>
            </DropdownMenuItem>
          )}
          {shouldClaimInsurance(lead, numberOfInsuranceClaims) && (
            <DropdownMenuItem
              onClick={() => {
                setSelectedLead(lead)
                setOpenClaimInsurance(true)
              }}
            >
              <div className="flex gap-3 items-center">
                <IoShieldCheckmarkSharp className="text-gray-500" size={15} />
                <span>Insurance Claim</span>
              </div>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem
            onClick={() => {
              setSelectedLead(lead)
              navigate(`/update-lead/${lead.id}`)
            }}
          >
            <div className="flex gap-3 items-center">
              <TbEdit className="text-gray-500" size={15} />
              <span>Edit</span>
            </div>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {selectedLead && openFollowUp && (
        <FollowUp
          status={status}
          leadId={lead.id}
          open={openFollowUp}
          setOpen={setOpenFollowUp}
          openBook={() => {
            setOpenFollowUp(false)
            setOpenBook(true)
          }}
        />
      )}
      {selectedLead && openBook && (
        <Book leadId={lead?.id} open={openBook} setOpen={setOpenBook} />
      )}
      {selectedLead && openClaimInsurance && (
        <ClaimInsurance
          leadId={lead?.id}
          open={openClaimInsurance}
          setOpen={setOpenClaimInsurance}
        />
      )}
      {selectedLead && openStatus && (
        <Status lead={selectedLead} open={openStatus} setOpen={setOpenStatus} />
      )}
      {selectedLead && openUpdateBook && (
        <UpdateBook
          leadId={lead?.id}
          open={openUpdateBook}
          setOpen={setOpenUpdateBook}
        />
      )}
    </>
  )
}
