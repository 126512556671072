import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'components/ui/alert-dialog'
import { Button } from 'components/ui/button'
export const RejectLoginReuquest = ({
  open,
  setOpen,
  handleApprove,
  isLoading,
  rejectMode,
}: any) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent className="sm:max-w-[425px] md:max-w-[745px] bg-white dark:bg-gray-900 pr-3">
        <AlertDialogHeader>
          <AlertDialogTitle>
            {' '}
            {rejectMode == 'reject' ? 'Reject' : 'Delete'} Auth Device
          </AlertDialogTitle>
          <AlertDialogDescription>
            {rejectMode == 'reject'
              ? 'By Confirming, the agent will not be able to login from the new device'
              : 'By confirming, the agent will be logged out from his device '}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button variant="outline" size="sm" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant={'destructive'}
            disabled={isLoading}
            size="sm"
            onClick={handleApprove}
          >
            Confirm
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
