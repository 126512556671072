export default function Kpi({ title, icon, value }: any) {
  return (
    <div className="w-full rounded-lg border p-6 shadow-sm bg-white dark:bg-gray-900">
      <div className="w-full h-full space-y-2">
        <div className="flex w-full justify-between items-center">
          <h1 className="text-base font-medium">{title}</h1>
          {icon}
        </div>
        <div className="text-2xl font-bold">{value}</div>
      </div>
    </div>
  )
}
