import { Routes, Route, Navigate } from 'react-router-dom'
import './App.css'

import Layout from 'components/shared/layout'
import Login from 'pages/auth/login'
import Verify from 'pages/auth/verify'
import ResetPassword from 'pages/auth/reset-password'
import Dashboard from 'pages/dashboard'
import Leads from 'pages/leads'
import Users from 'pages/users'
import Settings from 'pages/settings'
import AgentDashboard from 'pages/dashboard/components/agent-dashboard'
import RequireAuth from 'lib/auth-guard'
import Prefetch from 'app/features/prefetch'
import { PaymentSuccess } from 'pages/settings/payment-success'
import { SubscriptionWrapper } from 'components/shared/wrapperSubscription'
import TemporarilyFailure from 'pages/auth/temporarilyFailure'
import AuthDevicesRequests from 'pages/auth/auth-devices-requests.'
import LeadForm from 'pages/lead/lead-form'
import Lead from 'pages/lead'
import Form from 'pages/form'
import Whatsapp from 'pages/whatsapp'
import { TabsWrapper } from 'components/shared/wrapperTabs'
import InsurancePortal from 'pages/dashboard/components/insurance-portal'

export default function App() {
  return (
    <Routes>
      <Route path="/form" element={<Form />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/verify/:token" element={<Verify byToken={true} />} />

      <Route path="/temporarily-failure" element={<TemporarilyFailure />} />
      <Route path="/forgot-password" element={<ResetPassword />} />
      <Route element={<RequireAuth />}>
        <Route element={<Prefetch />}>
          <Route element={<Layout />}>
            <Route
              path="/"
              element={<Navigate to="/dashboard" replace={true} />}
            />
            <Route
              path="/dashboard"
              element={
                <SubscriptionWrapper>
                  <Dashboard />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/agents-dashboard"
              element={
                <TabsWrapper tab={'Dashboard'}>
                  <AgentDashboard />
                </TabsWrapper>
              }
            />

            <Route
              path="/create-lead"
              element={
                <SubscriptionWrapper>
                  <LeadForm />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/lead-details/:id"
              element={
                <SubscriptionWrapper>
                  <Lead />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/update-lead/:id"
              element={
                <SubscriptionWrapper>
                  <Lead />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/leads"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Leads'}>
                    <Leads statuses={['NEW', 'FOLLOW_UP']} />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/booked"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Booked">
                    <Leads status="BOOKED" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/not-qualified"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Not_Qualified">
                    <Leads status="NOT_QUALIFIED" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/insurance"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Insurance">
                    <Leads status="Insurance" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/insurance-portal"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab="Insurance_Portal">
                    <InsurancePortal />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />

            <Route
              path="/show"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Show'}>
                    <Leads status="SHOW" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/not-show"
              element={
                <SubscriptionWrapper>
                  <TabsWrapper tab={'Show'}>
                    <Leads status="NO_SHOW" />
                  </TabsWrapper>
                </SubscriptionWrapper>
              }
            />

            <Route
              path="/users"
              element={
                <SubscriptionWrapper>
                  <Users />
                </SubscriptionWrapper>
              }
            />
            <Route
              path="/agents-login-requests"
              element={
                <SubscriptionWrapper>
                  <AuthDevicesRequests />
                </SubscriptionWrapper>
              }
            />
            <Route path="/whatsapp" element={<Whatsapp />} />
            <Route path="/settings" element={<Settings />} />
          </Route>
        </Route>
        <Route path="*" element={<div>Not found</div>} />
      </Route>
    </Routes>
  )
}
